import { storage } from "./firebase";

//params: ref like '/documents/file_name/ and file
export const uploadFileToStorage = async (ref, file) => {
  const uploadTask = storage.ref(ref).put(file);
  return uploadTask;
};

//params : uploadTask needed
export const getDownloadURL = async (uploadTask) => {
  const url = await (await uploadTask).ref.getDownloadURL();
  return url;
};
