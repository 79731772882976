import { makeStyles } from "@material-ui/core/styles";
import { Button, Card, CardMedia, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 329,
    minHeight: 150,
    borderRadius: 10,
    boxShadow:
      "3.103773593902588px 3.103773593902588px 31.037734985351562px 0px #0000000F",
  },
  media: {
    height: 154,
  },
  content: {
    padding: "13px 13px 0px 16px",
    marginBottom: 6,
    overflow: "hidden",
  },
  batchName: { fontWeight: "600" },
  desc: {
    lineHeight: "21px",
    fontWeight: "300",
  },
}));

export const BatchCard = ({ handleEditBatch, batch }) => {
  const classes = useStyles();

  return (
    <div>
      <Link to={`/batch/${batch?.docId}`} state={{ data: batch }}>
        <Card className={classes.root}>
          <div className={classes.content}>
            <Typography variant="h6" className={classes.batchName}>
              {batch?.batchName}
            </Typography>
            <Typography className={classes.desc}>
              {batch?.description}
            </Typography>
          </div>
        </Card>
      </Link>
      <div>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={() => handleEditBatch(batch)}
        >
          Edit
        </Button>
      </div>
    </div>
  );
};
