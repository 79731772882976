import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { AddCertificateDetails } from "./AddCertificateDetails";

const useStyles = makeStyles({
  fullList: {
    minWidth: "729px",
    height: "1024px",
    padding: "32px 38px 50px",
  },
});

export function AddCertificateModal({ state, handleState, studentData }) {
  const classes = useStyles();

  const classe = `container mt-5 ${classes.fullList}`;

  return (
    <div>
      <React.Fragment key={"right"}>
        <SwipeableDrawer anchor={"right"} open={state} onClose={handleState}>
          <div className={classe}>
            <AddCertificateDetails
              handleDrawerState={handleState}
              studentData={studentData}
            />
          </div>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
