import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import CreateIcon from "@material-ui/icons/Create";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import LinkIcon from "@material-ui/icons/Link";
import { formatDate } from "../../../common/const";
import { useParams } from "react-router-dom";
import { useState } from "react";
import ModalVideo from "./ModalVideo";
import { WarningModal } from "../../../common/WarningModal";
import { deleteToast, errorToast } from "../../../common/Toastify";
import { deleteCourseVideo } from "../../../backend/EditCourseDetails";

const useStyles = makeStyles((theme) => ({
  cards: {
    width: 356,
    height: 330,
    marginTop: 30,
    overflow: "hidden",
    borderRadius: 10,
  },
  root: {
    width: "100%",
    height: "100%",
    boxShadow: "0px 3.47px 6.94px rgba(0, 0, 0, 0.2)",
    borderRadius: 10,
    position: "relative",
    overflow: "hidden",
  },
  desc: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#41423F",
    padding: "5px 10px",
  },
  timeSection: {
    padding: "5px 13px",
    color: "#4B4C47",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 10,
    fontWeight: 300,
  },
  bgImg: {
    width: 356,
    height: 259,
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  },
  icons: {
    color: "#000",
    transform: "rotate(-45deg)",
  },
}));

const VideoCard = ({ video }) => {
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const { id } = useParams();

  async function handleConfirmDelete() {
    const res = await deleteCourseVideo(video.id, id);
    if (res.successfull) {
      setOpenWarningModal(false);
      deleteToast("Video");
    } else {
      errorToast();
    }
  }

  return (
    <>
      <WarningModal
        open={openWarningModal}
        setOpen={setOpenWarningModal}
        message={"Video"}
        subMessage={"All Data will be deleted & wont be accessible anymore"}
        deleteItem={handleConfirmDelete}
      />
      <ModalVideo modal={modal} setModal={setModal} editVideoDetails={video} />
      <div className={classes.cards}>
        <Card className={classes.root}>
          <div className={classes.bgImg}>
            <img src={video?.link} alt="img" />
          </div>

          <div className={classes.desc}>
            <div>
              <Typography variant="h6" gutterBottom>
                {video.title}
              </Typography>
              <Typography
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#4B4C47",
                  fontSize: 12,
                  fontWeight: 300,
                }}
              >
                <AccessTimeIcon style={{ fontSize: 14, fontWeight: 400 }} />
                {formatDate(video?.updatedDate)}
              </Typography>
            </div>
            <div>
              <LinkIcon className={classes.icons} />

              <div style={{ fontSize: 10 }}>
                <CreateIcon
                  style={{ color: "#909090", marginRight: 5, fontSize: 15 }}
                  onClick={() => setModal(true)}
                />
                <DeleteOutlineOutlinedIcon
                  style={{ color: "#F44336", fontSize: 15 }}
                  onClick={() => setOpenWarningModal(true)}
                />
              </div>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default VideoCard;
