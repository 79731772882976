import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import VisibilityIcon from '@material-ui/icons/Visibility';
import { getOrders } from "../../../backend/shop/getOrders";
import OrderDetails from "./OrderDetails";
import { formatRawDate } from "../../../common/const";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  status: {
    color: theme.palette.info.main,
  },
  invoice: {
    color: theme.palette.error.main,
  },
  container: {
    maxHeight: 600,
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: "#ffff",
    },
  },

  arrowIcon: {
    width: "30px",
    height: "30px",
  },
}));

function Row({ order, setShowDetails }) {
  const classes = useRowStyles();

  const handleShowDetails = () => {
    setShowDetails(order);
  };

  const invoicePrice = order?.products?.reduce((total, item) => {
    return total + item.discountPrice * item.quantity;
  }, 0);

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell />
        <TableCell>{order?.name}</TableCell>
        <TableCell>{formatRawDate(order?.orderDate)}</TableCell>
        <TableCell>
          <div className={classes.status}>{order?.status}</div>
        </TableCell>
        <TableCell>
          <div className={classes.invoice}>{invoicePrice}</div>
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="delete"
            id={order?.docId}
            style={{ color: "#00000052", padding: 0 }}
            onClick={handleShowDetails}
          >
            <VisibilityIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export function ViewOrdersTable() {
  const classes = useRowStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [orders, setOrders] = React.useState([]);

  const [showDetails, setShowDetails] = React.useState(null);

  useEffect(() => {
    (async () => {
      const { success, data } = await getOrders();
      if (success) setOrders(data);
    })();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, orders.length - page * rowsPerPage);

  return (
    <>
      {showDetails && (
        <OrderDetails order={showDetails} setOrder={setShowDetails} />
      )}
      <TableContainer className={classes.container} component={Paper}>
        <Table stickyHeader>
          <TableHead style={{ position: "relative", zIndex: 0 }}>
            <TableRow>
              <TableCell />
              <TableCell>Customer</TableCell>
              <TableCell>Date of order</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Invoice</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          {orders.length ? (
            <TableBody>
              {orders
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((order, index) => {
                  return (
                    <Row
                      key={index}
                      order={order}
                      setShowDetails={setShowDetails}
                    />
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          ) : (
            <TableBody>
              <TableCell>
                <p>Please Add a Order</p>
              </TableCell>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={orders.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
