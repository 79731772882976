import { IconButton } from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import CreateIcon from "@material-ui/icons/Create";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: "16px",
  },
}));

export function Actions({ data, onView, onEdit, onDelete }) {
  const classes = useStyles();

  return (
    <>
      <IconButton
        aria-label="edit"
        id={data?.docId}
        onClick={() => onEdit(data)}
      >
        <CreateIcon className={classes.root} />
      </IconButton>

      <IconButton
        aria-label="delete"
        id={data?.docId}
        style={{ color: "#F44336" }}
        onClick={(e) => onDelete(data)}
      >
        <DeleteOutlineOutlinedIcon className={classes.root} />
      </IconButton>
    </>
  );
}
