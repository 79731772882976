import { DEVICE } from "../../common/const";
import AddStudent from "../AddStudents/AddStudent";
import styled from "styled-components";

export const AddSingleStudent = ({ isRedirect = false }) => {
  const handleDrawerClose = () => {};

  return (
    <Container>
      <AddStudent
        handleDrawerClose={handleDrawerClose}
        isRedirect={isRedirect}
      />
    </Container>
  );
};

const Container = styled.div`
  padding-right: 40px;

  @media ${DEVICE.laptop} {
    padding: 40px;
  }
`;
