import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import { BatchCard } from "./BatchCard";
import { BatchModal } from "../AddBatch/AddNewBatch";

export const BatchList = ({ batches }) => {
  const [openEditBatch, setOpenEditBatch] = useState(false);
  const [editBatchDetails, setEditBatchDeatils] = useState({});

  const handleEditBatchModal = (data) => {
    if (data) {
      setEditBatchDeatils(data);
      setOpenEditBatch(true);
    }
  };

  const handleCloseModal = () => {
    setEditBatchDeatils({});
    setOpenEditBatch(false);
  };

  return (
    <React.Fragment>
      <BatchModal
        open={openEditBatch}
        handleModal={handleCloseModal}
        editBatchData={editBatchDetails}
      />
      <Grid container spacing={5}>
        {batches?.length ? (
          batches?.map((batch, index) => {
            return (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <BatchCard
                  handleEditBatch={handleEditBatchModal}
                  batch={batch}
                />
              </Grid>
            );
          })
        ) : (
          <Typography variant="h4">Please create a new Batch</Typography>
        )}
      </Grid>
    </React.Fragment>
  );
};
