import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { INPUT_IMAGE_PATH } from "../../../common/config/configImage";
import prianka_sign from "../../../globals/assets/signature/Priyanka_Sign.png";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 900,
    height: 508,
    boxShadow: "0px 3px 20px 0px #00000024",
  },
  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 50px",
    backgroundColor: theme.palette.primary.main,
    height: 130,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  subContainer: {
    paddingTop: 15,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  certificate: {
    fontSize: 19,
    paddingBottom: 4,
    fontWeight: "600",
  },
  name: {
    fontSize: 42,
    fontWeight: "700",
    letterSpacing: "1px",
    marginBottom: 6,
  },
  desc: {
    width: "70%",
    fontSize: 20,
    fontWeight: "300",
    textAlign: "center",
  },
  desc1: { paddingTop: 16, fontWeight: "300", fontSize: 20 },
  footer: {
    padding: "20px 0px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  signatureContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  founderName: {
    fontWeight: "300",
    fontSize: 20,
  },
  coFounder: {
    fontWeight: "300",
    fontSize: 16,
  },
}));

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const CertificatePreview = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { data, handleClose } = props;

  console.log(data?.issueDate?.getMonth(), data?.issueDate?.getFullYear());

  const issuedDate = `${
    monthNames[data?.issueDate?.getMonth()]
  } ${data?.issueDate?.getFullYear()}`;

  return (
    <React.Fragment>
      <div ref={ref} className={classes.root}>
        <div className={classes.headerContainer}>
          <div style={{ fontSize: 24, color: "#ffff", fontWeight: "700" }}>
            ROGUE CODE ACADEMY
          </div>
          <div className={classes.container}>
            <div
              style={{ fontSize: 36, fontWeight: "700", lineHeight: "26px" }}
            >
              CERTIFICATE
            </div>
            <div style={{ fontSize: 25 }}>OF COMPLETION</div>
          </div>
        </div>
        <div className={classes.subContainer}>
          <Typography className={classes.certificate}>
            This Certificate is proudly presented to
          </Typography>
          <Typography className={classes.name}>
            {`${data?.studentName}`}
          </Typography>
          <Typography className={classes.desc}>
            for having successfully completed{" "}
            <span style={{ fontWeight: "700" }}>{data?.course}</span> course
            conducted by Rogue Code Academy in {issuedDate}
          </Typography>
          <Typography className={classes.desc1}>
            Your hard work, dedication and achievement will be cherished
          </Typography>
        </div>
        <div className={classes.footer}>
          <img height={130} alt="Logo" src={INPUT_IMAGE_PATH.ROGUE_CODE_LOGO} />
          <div className={classes.signatureContainer}>
            <img
              src={prianka_sign}
              alt="signature"
              height={50}
              width={200}
              style={{ objectFit: "cover" }}
            />
            <Typography className={classes.founderName}>
              PRIYANKA PACHERIA
            </Typography>
            <Typography className={classes.coFounder}>CO-FOUNDER</Typography>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
});
