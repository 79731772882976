import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { TABS_MARKET } from "../../common/config/configURL";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  container: {
    background: "#17191A",
    paddingTop: 10,
  },
  root: {
    flexGrow: 1,
  },
  tabs: {
    root: {
      "& .MuiTabs-root": {
        minHeight: "50px",
      },
    },
    "& .MuiTabs-flexContainer": {
      background: "#17191A",
      marginLeft: 50,
    },
    "& .MuiTabs-scrollable": {
      height: 50,
    },
    "& .MuiTab-wrapper": {
      display: "-webkit-box",
    },
    "& .MuiTab-labelIcon": {
      minHeight: "0px",
    },
    "& .MuiTab-root": {
      minWidth: 0,
      marginRight: "11px",
    },
    "& .MuiTypography-body1": {
      fontWeight: 500,
      textTransform: "capitalize",
    },

    "& .MuiTabs-root": {
      minHeight: "50px",
    },
    "& .MuiTab-labelIcon .MuiTab-wrapper > *:first-child": {
      marginRight: 10,
    },
  },
  pageHeader: {
    fontWeight: 600,
    color: "#909090",
    marginBottom: "30px",
  },
}));

export const MarketHeader = () => {
  const classes = useStyles();

  const [value, setValue] = React.useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setValue(location.pathname);
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
    navigate(newValue);
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.root}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="nav tabs example"
            textColor="primary"
            indicatorColor="primary"
            className={classes.tabs}
            variant="scrollable"
          >
            {TABS_MARKET.map((tab, index) => (
              <Tab
                component="a"
                onClick={(event) => {
                  event.preventDefault();
                }}
                textColor="primary"
                label={
                  <Typography
                    style={{
                      color: value === tab?.url ? "#F6B501" : "#fff",
                    }}
                  >
                    {tab.title}
                  </Typography>
                }
                href={tab.url}
                icon={value === tab?.url ? tab.active : tab.icon}
                value={tab.url}
                {...a11yProps(index)}
              />
            ))}
          </Tabs>
        </div>
      </div>
    </>
  );
};
