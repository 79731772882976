import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const UploadTable = ({ tableData, tabName }) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader aria-label="sticky table" className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell style={{ minWidth: "9em" }}>
              {TAB_NAME_MAPPING[tabName]["label"]}
            </TableCell>
            <TableCell style={{ minWidth: "9em" }}>Vendor Name</TableCell>
            <TableCell style={{ minWidth: "9em" }}>Client Name</TableCell>
            <TableCell style={{ minWidth: "9em" }}>Client Contact</TableCell>
            <TableCell style={{ minWidth: "9em" }}>Date of Purchase</TableCell>
            <TableCell style={{ minWidth: "9em" }}>Date of Expire</TableCell>
            <TableCell style={{ minWidth: "9em" }}>Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData?.length
            ? tableData?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    {row[TAB_NAME_MAPPING[tabName]["key"]]}
                  </TableCell>
                  <TableCell>{row["vendorName"]}</TableCell>
                  <TableCell>{row["clientName"]}</TableCell>
                  <TableCell>{row["clientContact"]}</TableCell>
                  <TableCell>
                    {row["purchaseDate"].toLocaleDateString("en-US")}
                  </TableCell>
                  <TableCell>
                    {row["expireDate"].toLocaleDateString("en-US")}
                  </TableCell>
                  <TableCell>{row["price"]}</TableCell>
                </TableRow>
              ))
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UploadTable;

const TAB_NAME_MAPPING = {
  Domain: { label: "Domain Name", key: "domainName" },
  SSL: { label: "SSL Name", key: "sslName" },
  Host: { label: "Host Name", key: "hostName" },
};
