import { IconButton } from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import CreateIcon from "@material-ui/icons/Create";

export function Actions({ data, onEdit, onDelete }) {
  return (
    <>
      <IconButton
        aria-label="delete"
        id={data?.docId}
        onClick={() => onDelete(data)}
      >
        <DeleteOutlineOutlinedIcon />
      </IconButton>
      <IconButton
        aria-label="edit"
        id={data?.docId}
        onClick={() => onEdit(data)}
      >
        <CreateIcon />
      </IconButton>
    </>
  );
}
