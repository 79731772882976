import "./Sidebar.css";
import { ListItem, Typography } from "@material-ui/core";
import { SIDEBAR_TABS } from "../../common/const";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: { margin: "15px 0px", display: "flex", flexDirection: "column" },
}));

export const SidebarTab = () => {
  const classes = useStyles();
  const location = useLocation();

  return SIDEBAR_TABS.map((tab) => {
    return (
      <ListItem
        className={classes.root}
        button
        {...{
          component: RouterLink,
          to: tab?.path,
          color: "inherit",
          style: {
            textDecoration: "none",
            color: "black",
            borderLeft:
              window.location.href.indexOf(tab?.includeParams) > -1
                ? "3px solid #F6B501"
                : "3px solid transparent",
            backgroundColor:
              window.location.href.indexOf(tab?.includeParams) > -1
                ? "#1E1F20"
                : null,
            transition: "all 0.3s ease-in-out",
          },
          key: tab.title,
        }}
      >
        <img src={tab?.icon} alt={tab?.title} />
        <Typography
          variant="body2"
          style={{
            color: "#fff",
            margin: "5px 0px",
            lineHeight: "24px",
            fontSize: 12,
            fontWeight: "500",
          }}
        >
          {tab?.title}
        </Typography>
      </ListItem>
    );
  });
};
