import React from "react";
import Analytics from "../Analytics";
import styled from "styled-components";
import RevenueChart from "./RevenueChart";

const ViewRevenue = () => {
  return (
    <div>
      <AnalyticsContainer>
        <Analytics />
      </AnalyticsContainer>
      <RevenueChartContainer>
        <RevenueChart />
      </RevenueChartContainer>
    </div>
  );
};

const AnalyticsContainer = styled.div`
  padding: 32px 38px;
  background-color: #17191a;
`;

const RevenueChartContainer = styled.div`
  padding: 32px 38px;
`;

export default ViewRevenue;
