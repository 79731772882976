import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  User: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  UserName: {
    fontSize: "16px",
    fontWeight: 500,
    margin: 0,
    padding: 0,
  },
  UserBday: {
    fontSize: "14px",
    fontWeight: 400,
  },
  UserDetails: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
});

export const BirthdayNotifications = ({ students }) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.UserDetails}>
        {students.map((item, index) => {
          const calculateAge = () => {
            const dobDate = new Date(item?.dob.seconds * 1000);
            const now = new Date();
            let age = now.getFullYear() - dobDate.getFullYear();
            const monthDiff = now.getMonth() - dobDate.getMonth();
            if (
              monthDiff < 0 ||
              (monthDiff === 0 && now.getDate() < dobDate.getDate())
            ) {
              age--;
            }
            return age + 1;
          };
          return (
            <div className={classes.User} key={index}>
              <Avatar
                alt={item?.studentName}
                src="/static/images/avatar/1.jpg"
              />
              <div>
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.UserName}
                >
                  {item?.studentName}
                </Typography>
                <p className={classes.UserBday}>
                  <span style={{ color: "red" }}>
                    {new Date(item?.dob.seconds * 1000).toLocaleDateString()}
                  </span>{" "}
                  Turning {calculateAge()} years{" "}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
