import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import { AddBatch } from "./AddBatch";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "100%",
      borderRadius: 15,
      boxShadow: "0px 4px 12px 0px #00000040",
    },
  },

  container: {
    width: "100%",
    height: "100%",
  },
}));

export const BatchModal = ({ open = false, handleModal, editBatchData }) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        open={open}
        onClose={handleModal}
        className={classes.dialog}
        style={{ zIndex: 1000 }}
      >
        <div className={classes.container}>
          <AddBatch handleClose={handleModal} editBatchData={editBatchData} />
        </div>
      </Dialog>
    </>
  );
};
