import { firestore } from "./firebase";

export const createCourse = async (data) => {
  const collectionRef = firestore.collection("Course").doc();
  await collectionRef
    .set({
      ...data,
      courseId: collectionRef.id,
    })
    .catch((e) => {
      console.log(e);
      return { successful: false, message: "There is some Problem" };
    });
  return {
    successful: true,
    message: "Data has been successfully created.",
  };
};

export const updateCourse = async (data) => {
  const collectionRef = firestore.collection("Course").doc(data?.courseId);
  await collectionRef.update(data).catch((e) => {
    console.log(e);
    return { successful: false, message: "There is some Problem" };
  });
  return {
    successful: true,
    message: "Data has been successfully Updated.",
  };
};

export const createQuiz = async (data, id) => {
  const collectionRef = firestore
    .collection("Course")
    .doc(id)
    .collection("Quiz")
    .doc();
  await collectionRef
    .set({
      ...data,
    })
    .catch((e) => {
      console.log(e);
      return { successful: false, message: "There is some Problem" };
    });
  return {
    successful: true,
    message: "Data has been successfully created.",
  };
};

export const getAllQuizDetails = async (date) => {
  const data = [];
  const collectionRef = await firestore.collection("Quiz").get();
  if (collectionRef.docs.length > 0) {
    for (let doc of collectionRef.docs) {
      data.push({ ...doc.data(), id: doc.id });
    }
    return data;
  } else {
    return data;
  }
};

export const createAssignment = async (data, id) => {
  const collectionRef = firestore
    .collection("Course")
    .doc(id)
    .collection("Assignment")
    .doc();
  await collectionRef
    .set({
      ...data,
    })
    .catch((e) => {
      console.log(e);
      return { successfull: false, message: "There is some Problem" };
    });
  return {
    successfull: true,
    message: "Data has been successfully created.",
  };
};

export const createPpt = async (data, id) => {
  const collectionRef = firestore
    .collection("Course")
    .doc(id)
    .collection("PPT")
    .doc();
  await collectionRef
    .set({
      ...data,
      id: collectionRef.id,
    })
    .catch((e) => {
      console.log(e);
      return { successful: false, message: "There is some Problem" };
    });
  return {
    successful: true,
    message: "Data has been successfully created.",
  };
};

export const createCourseVideo = async (data, id) => {
  const collectionRef = firestore
    .collection("Course")
    .doc(id)
    .collection("Video")
    .doc();
  await collectionRef
    .set({
      ...data,
      id: collectionRef.id,
    })
    .catch((e) => {
      console.log(e);
      return { successful: false, message: "There is some Problem" };
    });
  return {
    successful: true,
    message: "Data has been successfully created.",
  };
};

export const createCourseSyllabus = async (id, data, isUpdate) => {
  if (isUpdate) {
    const collectionRef = firestore
      .collection("Course")
      .doc(id)
      .collection("Syllabus")
      .doc(isUpdate);
    await collectionRef.update({ ...data, id: isUpdate }).catch((e) => {
      console.log(e);
      return { successful: false, message: "There is some Problem" };
    });
    return {
      successful: true,
      message: "Data has been successfully created.",
    };
  } else {
    const collectionRef = firestore
      .collection("Course")
      .doc(id)
      .collection("Syllabus")
      .doc();
    await collectionRef.set({ ...data, id: collectionRef.id }).catch((e) => {
      console.log(e);
      return { successful: false, message: "There is some Problem" };
    });
    return {
      successful: true,
      message: "Data has been successfully created.",
    };
  }
};
