import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { COURSE_TAB } from "../../../common/config/configURL";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import QuizPreview from "../QuizSection/QuizPreview";
import VideoPreview from "../VideoSection/VideoPreview";
import PptPreview from "../PptSection/PptPreview";
import AssignmentPreview from "../AssignmentSection/AssignmentPreview";
import { deleteCourse } from "../../../backend/EditCourseDetails";
import { deleteToast, errorToast } from "../../../common/Toastify";
import { WarningModal } from "../../../common/WarningModal";
import { Syllabus } from "../SyllabusSection/Syllabus";

const RenderComponent = ({ renderComponent, course }) => {
  switch (renderComponent) {
    case 0:
      return <Syllabus course={course} />;
    case 1:
      return <QuizPreview course={course} />;
    case 2:
      return <AssignmentPreview course={course} />;
    case 3:
      return <PptPreview course={course} />;
    case 4:
      return <VideoPreview course={course} />;
    default:
      return <QuizPreview course={course} />;
  }
};

const useStyles = makeStyles((theme) => ({
  header: {
    background: "#17191A",
    color: "#F6F8FA",
  },
  button: {
    height: 42,
    width: 229,
    borderRadius: 0,
    textTransform: "capitalize",
    fontWeight: 500,
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
    padding: "30px 90px",
  },
  Tabs: {
    color: "#fff",
    margin: 0,
    padding: 0,
    minHeight: 48,

    "& .MuiTab-wrapper": {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "flex-start",
    },
  },

  tabHeader: {
    background: "#17191A",
    color: "#fff",
  },
  tabBody: {
    width: 800,
    margin: "0px 50px",
  },
  subHeading: {
    color: "#FFF4D5",
    fontWeight: 300,
    fontSize: 20,
  },
}));
const PreviewCourse = () => {
  const location = useLocation();
  const [course, setCourse] = useState(null);

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [renderComponent, setRenderComponent] = React.useState(value);
  const [openWarningModal, setOpenWarningModal] = useState(false);

  const { id } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setRenderComponent(newValue);
  };

  useEffect(() => {
    if (location.state) {
      setCourse(location.state.data);
    }
  }, [location]);

  async function handleConfirmDelete() {
    const res = await deleteCourse(id);
    if (res.successfull) {
      setOpenWarningModal(false);
      deleteToast("Course");
      window.history.back();
    } else {
      errorToast();
    }
  }

  return (
    <>
      <WarningModal
        open={openWarningModal}
        setOpen={setOpenWarningModal}
        message={"Course"}
        subMessage={"All Data will be deleted & wont be accessible anymore"}
        deleteItem={handleConfirmDelete}
      />
      <div className={classes.header}>
        <div className={classes.heading}>
          <div>
            <Typography variant="h5">{course?.courseTitle}</Typography>
            <Typography className={classes.subHeading}>
              {course?.courseDescription}
            </Typography>
          </div>
          <div>
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => setOpenWarningModal(true)}
            >
              Delete Subject
            </Button>
          </div>
        </div>

        <div className={classes.tabBody}>
          <Paper square>
            <Tabs
              value={value}
              textColor="primary"
              onChange={handleChange}
              indicatorColor="primary"
              className={classes.tabHeader}
            >
              {COURSE_TAB.map((item, index) => {
                return (
                  <Tab
                    component="a"
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                    textColor="primary"
                    label={
                      <Typography variant="body2">{item.title}</Typography>
                    }
                    icon={value === index ? item.active : item.icon}
                    className={classes.Tabs}
                  />
                );
              })}
            </Tabs>
          </Paper>
        </div>
      </div>

      <div style={{ backgroundColor: "#FFFF" }}>
        <RenderComponent renderComponent={renderComponent} course={course} />
      </div>
    </>
  );
};

export default PreviewCourse;
