import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ToolBar from "../ToolBar";

import ModalPpt from "./ModalPpt";
import PptTable from "./PptTable";
import { useParams } from "react-router-dom";
import { getPptData } from "../../../backend/getCourseData";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "40px 90px",
  },
  table: {
    marginTop: 30,
  },
}));

const PptPreview = ({ course }) => {
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [pptData, setPptData] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    handlerGetAssignment(id);
  }, [id]);

  const handlerGetAssignment = async (id) => {
    await getPptData(setPptData, id);
  };

  const handleModal = () => {
    setModal(true);
  };

  return (
    <>
      <div className={classes.container}>
        <ToolBar btnText="Add PPT" handleModal={handleModal} />
        <div className={classes.table}>
          <PptTable pptData={pptData} />
        </div>
      </div>
      <ModalPpt setModal={setModal} modal={modal} />
    </>
  );
};

export default PptPreview;
