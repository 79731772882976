import { firestore } from "../firebase";

export const getOrders = async () => {
  try {
    const data = [];
    const collectionRef = await firestore.collection("Orders").get();
    if (collectionRef.docs.length > 0) {
      for (let doc of collectionRef.docs) {
        data.push({ ...doc.data(), id: doc.id });
      }
      return { success: true, data };
    } else {
      return { success: false, data: [] };
    }
  } catch (error) {
    return { success: false, error };
  }
};
