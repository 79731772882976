import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Select, TextField, Typography } from "@material-ui/core";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    gap: 15,
    margin: "24px 0px",
  },
  required: {
    color: theme.palette.error.main,
  },
  label: { paddingBottom: 8 },
  textfield: { width: 201 },
}));

export const CertificateForm = ({ studentData, formData, handleChange }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.container}>
        <div>
          <Typography className={classes.label}>
            Student Name <span className={classes.required}>*</span>
          </Typography>
          <TextField
            className={classes.textfield}
            placeholder="Student’s First Name"
            variant="outlined"
            name="studentName"
            value={formData.studentName}
            onChange={(e) => handleChange("studentName", e.target.value)}
          />
        </div>
        <div>
          <Typography className={classes.label}>
            Course<span className={classes.required}>*</span>
          </Typography>
          <Select
            native
            className={classes.textfield}
            variant="outlined"
            name="course"
            value={formData.course}
            onChange={(e) => {
              handleChange("course", e.target.value);
            }}
          >
            <option aria-label="None" value="" />
            {studentData?.courseDetails?.map((course) => {
              return (
                <option value={course?.courseName}>{course?.courseName}</option>
              );
            })}
          </Select>
        </div>
        <div>
          <Typography className={classes.label}>
            Date of Issue<span className={classes.required}>*</span>
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              className={classes.textfield}
              format="dd-MM-yyyy"
              inputVariant="outlined"
              name="issueDate"
              value={formData.issueDate}
              onChange={(date) => {
                handleChange("issueDate", date);
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
    </React.Fragment>
  );
};
