import { Divider, FormControl, Select, Typography } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { addStudentFees } from "../../backend/EditDataToFirebase";
import StudentData from "../../components/StudentData";
import { CourseCard } from "./Course";
import MenuItem from "@material-ui/core/MenuItem";

export const AddStudentFee = ({ handleDrawerClose, student }) => {
  const [selectCourseIndex, setSelectCourseIndex] = React.useState(null);
  const [selectCourse, setSelectCourse] = React.useState({});

  const isPendingTotalFees =
    parseFloat(selectCourse?.courseFee) -
    parseFloat(selectCourse?.totalPaidFee);

  const isNegativeTotalFees =
    Math.sign(
      isPendingTotalFees -
      (selectCourse?.paidFee ? parseFloat(selectCourse?.paidFee) : 0)
    ) === -1;

  const handleChange = (e) => {
    setSelectCourse({ ...selectCourse, [`${e.target.name}`]: e.target.value });
  };

  const handleCourseDetails = () => {
    const { courseName, courseFee, totalPaidFee, courseDuration, paidFee } =
      selectCourse;

    const allCourse = [...student.courseDetails];
    allCourse[selectCourseIndex] = {
      courseName,
      courseFee,
      courseDuration,
      totalPaidFee: parseFloat(paidFee) + parseFloat(totalPaidFee),
    };
    return allCourse;
  };

  const validateFields = () => {
    const { paidFee, paymentMode } = selectCourse;
    if (!paidFee || !paymentMode) {
      return false;
    }
    return true;
  };

  const handleAddFee = async () => {
    if (!validateFields()) {
      alert("Please fill all fields");
      return;
    }
    const updatedCourseDetails = handleCourseDetails();
    const { totalPaidFee, paidFee, accountNumber = "", ...data } = selectCourse;
    const receipt = {
      ...data,
      accountNumber,
      createdDate: new Date(),
      paidFee: parseFloat(paidFee) + parseFloat(totalPaidFee),
    };
    const res = await addStudentFees(
      student?.id,
      receipt,
      updatedCourseDetails
    );
    if (res.successful) {
      handleDrawerClose();
    }
  };

  return (
    <React.Fragment>
      <Typography variant="h6" style={{ paddingBottom: 20 }}>
        ADD FEES
      </Typography>
      <Divider />
      <StudentData
        formData={student}
        studentDOB={new Date(student?.dob.seconds * 1000)}
      />
      <Typography>Edit Course Fees</Typography>
      <FormControl variant="outlined">
        <Select
          variant="outlined"
          native
          name="courseName"
          value={selectCourseIndex}
          onChange={(event) => {
            setSelectCourseIndex(event.target.value);
            setSelectCourse({
              ...selectCourse,
              ...student?.courseDetails[event.target.value],
            });
          }}
        >
          <option value="" disabled selected>
            Select Course
          </option>
          {student?.courseDetails.map((course, index) => (
            <option value={index}>{course?.courseName}</option>
          ))}
        </Select>
      </FormControl>
      <CourseCard course={selectCourse} handleChange={handleChange} />
      <Btns>
        <Btn onClick={handleDrawerClose}>Back</Btn>
        {Object.keys(selectCourse).length ? (
          <Btn
            disabled={!isPendingTotalFees || isNegativeTotalFees}
            onClick={handleAddFee}
          >
            Save
          </Btn>
        ) : null}
      </Btns>
    </React.Fragment>
  );
};

const Btns = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Btn = styled.button`
  color: #000;
  background: #e6e6e6;
  border: none;
  outline: none;
  padding: 10px 30px;

  &:last-child {
    background: #f6b501;
    color: #000;
    margin-left: 10px;
  }

  &:disabled {
    background: gray !important;;
    cursor: not-allowed !important;;
  }
`;
