import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AddSyllabus from "./AddSyllabus";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import SyllabusCard from "./SyllabusCard";
import { useParams } from "react-router-dom";
import { getSyllabusData } from "../../../backend/getCourseData";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "40px 90px",
  },
  cards: {
    display: "flex",
    flexDirection: "column",
    gap: 30,
    marginTop: 20,
  },
}));

export const Syllabus = () => {
  const classes = useStyles();
  const [modal, setModal] = useState(false);

  const [syllabusData, setSyllabusData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    handlerGetSyllabus(id);
  }, [id]);

  const handlerGetSyllabus = async (id) => {
    await getSyllabusData(setSyllabusData, id);
  };

  const handleModal = () => {
    setModal(true);
  };

  return (
    <>
      <div className={classes.container}>
        <div>
          <Button
            variant="outlined"
            className={classes.btn}
            onClick={handleModal}
          >
            <AddIcon />
            Add Syllabus
          </Button>
        </div>
        <div className={classes.cards}>
          {syllabusData?.map((data, index) => {
            return <SyllabusCard data={data} key={index} />;
          })}
        </div>
      </div>
      {modal ? <AddSyllabus setModal={setModal} modal={modal} /> : null}
    </>
  );
};
