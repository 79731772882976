import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 24,
    display: "flex",
    justifyContent: "space-between",
  },
  textField: {
    height: 42,
    width: 810,
  },
  inputTextField: {
    background: theme.palette.common.white,
  },
  dateField: {
    width: 169,
    height: 42,
  },
  selectEmpty: {
    width: 200,
    height: 42,
    background: "#fff",
    border: 0,

    "& .MuiSelect-outlined": {
      background: "transparent",
    },
  },

  sortLabel: {
    transform: "translate(14px, 12px) scale(1)",
    color: "#0A2340",
    fontWeight: 600,
  },
}));

export const SearchOrder = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TextField
        className={classes.textField}
        size="small"
        placeholder="Search..."
        variant="outlined"
        InputProps={{
          startAdornment: <SearchIcon />,
          className: classes.inputTextField,
        }}
      />

      <TextField
        className={classes.dateField}
        type="date"
        size="small"
        variant="outlined"
        InputProps={{
          startAdornment: <CalendarTodayOutlinedIcon />,
          className: classes.inputTextField,
        }}
      />

      <div>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel
            htmlFor="outlined-age-native-simple"
            className={classes.sortLabel}
          >
            Sort By Status
          </InputLabel>
          <Select
            className={classes.selectEmpty}
            native
            label="Sort By Status"
            inputProps={{
              name: "age",
              id: "outlined-age-native-simple",
            }}
          >
            <option aria-label="None" value="" />
            <option value="Canceled">Canceled</option>
            <option value="Delivered">Delivered</option>
            <option value="Pending">Pending</option>
          </Select>
        </FormControl>
      </div>
    </div>
  );
};
