import React from "react";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles({
  headerTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "30px",
  },
  button: {
    border: "1px solid #ccc",
    borderRadius: "5px",
    width: "30px",
    height: "30px",
  },
});

export const AddCertificateHeader = ({ handleClose }) => {
  const classes = useStyles();

  return (
    <div className={classes.headerTitle}>
      <Typography style={{ fontWeight: "500" }} variant="h5">
        Certificate
      </Typography>
      <IconButton
        aria-label="delete"
        className={classes.button}
        onClick={handleClose}
      >
        <ClearIcon fontSize="small" />
      </IconButton>
    </div>
  );
};
