import React from "react";
import styled from "styled-components";

const convertDate = (d) => {
  var date = new Date(d),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [day, mnth, date.getFullYear()].join("-");
};

const StudentData = ({ formData, studentDOB }) => {
  return (
    <>
      <Container>
        <UserImg>
          {formData.profilePhoto && formData?.id ? (
            <img src={formData.profilePhoto} alt="user" />
          ) : formData.profilePhoto && !formData?.id ? (
            <img src={URL.createObjectURL(formData.profilePhoto)} alt="user" />
          ) : (
            <img src="/assets/profile-icon.png" alt="user" />
          )}
        </UserImg>

        <Details>
          <div>
            <p>Student Information</p>
          </div>
          <h4>Name:- {formData?.studentName}</h4>
          {<p>D.O.B.:- {convertDate(studentDOB)}</p>}

          <p>
            contact No:- <span>{formData?.contactNumber}</span>
          </p>
        </Details>
      </Container>
    </>
  );
};

const Container = styled.div`
  padding: 15px;
  margin: 15px 0;
  border: 1px solid #b3b3b3;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 15px;
`;

const UserImg = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 5px;
  border: 1px solid #b3b3b3;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
`;

const Details = styled.div`
  font-size: 14px;
  > div {
    border: 1px solid #9c27b0;
    padding: 3px 5px;
    border-radius: 16px;
    text-align: center;
    color: #9c27b0;
    p {
      font-size: 14px;
      font-weight: 500;
    }
  }

  p {
    font-size: 14px;
    font-weight: 500;
    margin: 5px 0;
  }
  h3,
  h4 {
    font-size: 18px;
    font-weight: 600;
  }
  span {
    color: rgba(0, 0, 0, 0.6);
  }
`;
export default StudentData;
