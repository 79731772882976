import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

export const AddFees = withStyles((theme) => ({
  root: {
    minWidth: 110,
    border: "1px solid #0A2340",
    fontWeight: "bold",
    margin: "2px 4px",
    padding: "5px 8px",
  },
}))(Button);

export const AddCourse = withStyles((theme) => ({
  root: {
    border: "1px solid #0A2340",
    fontWeight: "bold",
    color: "#0A2340",
  },
}))(Button);

export const CreateBlog = withStyles((theme) => ({
  root: {
    height: 42,
    width: 229,
    borderRadius: 0,
    textTransform: "capitalize",
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[10],
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
  },
}))(Button);

export const PublishChanges = withStyles((theme) => ({
  root: {
    height: 51,
    width: 239,
    borderRadius: 32,
    textTransform: "capitalize",
    backgroundColor: "#3B41C9",
    color: theme.palette.common.white,
    fontSize: 20,
    "&:hover": {
      backgroundColor: "#3B41C9",
    },
  },
}))(Button);

export const PrimaryButton = withStyles((theme) => ({
  root: {
    boxShadow: "none",
    width: 196,
    height: 42,
    fontSize: 15,
    borderRadius: 0,
    textTransform: "capitalize",
  },
}))(Button);

export const AddProductButton = withStyles((theme) => ({
  root: {
    boxShadow: "none",
    width: 188,
    height: 42,
    textTransform: "capitalize",
    fontSize: 16,
    color: "#002E63",
    border: "1px solid #002E63",
  },
}))(Button);

export const DeleteProduct = withStyles((theme) => ({
  root: {
    width: 147,
    height: 42,
    textTransform: "capitalize",
    fontSize: 16,
    color: "#002E63",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    background: "#fff",
  },
}))(Button);

export const ModalBtn = withStyles((theme) => ({
  root: {
    width: 239,
    height: 50,
    textTransform: "capitalize",
    fontSize: 16,
    color: "#fff",
    boxShadow: "none",
    background: "#3B41C9",
    textAlign: "center",
    borderRadius: 999,

    "&:hover": {
      backgroundColor: "#3B41C9",
      boxShadow: "none",
    },
  },
}))(Button);
