import React, { useEffect, useState } from "react";
import { Divider, IconButton, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { PublishChanges } from "../../../../common/Button";
import { AddStudentList } from "./AddStudentList";
import { editBatchStudents } from "../../../../backend/EditDataToFirebase";
import { useParams } from "react-router-dom";
import { successToastMessage } from "../../../../common/Toastify";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "6px 5px 0px 32px",
  },
  header: {
    fontSize: 27,
    fontWeight: "600",
    color: "#5F5F5F",
  },
  closeIcon: { fontSize: "30px" },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minWidth: 450,
    padding: "30px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
  },
  dataContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 30,
  },
}));

export const AddStudents = ({ handleClose, students }) => {
  const classes = useStyles();
  const { id } = useParams();

  useEffect(() => {
    setFilteredStudents(students);
  }, [students]);

  const [searchValue, setSearchValue] = useState("");
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [toAddStudents, setToAddStudents] = useState([]);

  const searchLeadResult = (name) => {
    if (!students?.length) {
      return;
    }

    return students?.filter((pros) => {
      if (name) {
        return (
          pros?.studentName &&
          pros?.studentName.toLowerCase().includes(name.toLowerCase())
        );
      }
      return true;
    });
  };

  const onSearch = (e) => {
    setSearchValue(e.target.value);
    if (students?.length) {
      setFilteredStudents(searchLeadResult(e.target.value));
    }
  };

  const handlerSubmit = async () => {
    const res = await editBatchStudents(id, toAddStudents);
    if (res.successful) {
      handleClose();
      successToastMessage(res.message);
    }
  };

  return (
    <React.Fragment>
      <div className={classes.headerContainer}>
        <Typography className={classes.header}>Add Students</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </div>
      <Divider />

      <div className={classes.content}>
        <div className={classes.dataContainer}>
          <TextField
            fullWidth
            size="small"
            style={{ height: 38 }}
            label="Search"
            placeholder="Search by student name..."
            variant="outlined"
            value={searchValue}
            onChange={onSearch}
          />
          <AddStudentList
            student={filteredStudents}
            setToAddStudents={setToAddStudents}
            toAddStudents={toAddStudents}
          />
        </div>
        <div className={classes.buttonContainer}>
          <PublishChanges
            endIcon={<AddIcon />}
            onClick={handlerSubmit}
            disabled={!students.length || !toAddStudents.length}
          >
            Add
          </PublishChanges>
        </div>
      </div>
    </React.Fragment>
  );
};
