import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, IconButton } from "@material-ui/core";
import { AddCertificateModal } from "./CerificateModal";
import { STUDENTS_TABLE_IMAGE_PATH } from "../../../common/config/configImage";

const useStyles = makeStyles((theme) => ({
  button: {
    padding: "13px 0px",
    justifyContent: "start",
    color: "#2F80ED",
    fontSize: 16,
  },
}));

export const AddCertificate = ({ studentData, showIcon = true }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleState = () => {
    setOpen((currentState) => !currentState);
  };

  return (
    <React.Fragment>
      <AddCertificateModal
        state={open}
        handleState={handleState}
        studentData={studentData}
      />
      {showIcon ? (
        <IconButton onClick={handleState}>
          <img
            src={STUDENTS_TABLE_IMAGE_PATH.COURSE_CERTIFICATE}
            alt={"certificate"}
          />
        </IconButton>
      ) : (
        <Button onClick={handleState} className={classes.button} variant="text">
          Show Certificate
        </Button>
      )}
    </React.Fragment>
  );
};
