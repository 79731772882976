import React from "react";
import { Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import StudentData from "../../../components/StudentData";
import { CertificateForm } from "./AddCertificateForm";
import { AddCertificateHeader } from "./AddCertificateHeader";
import { PrimaryButton } from "../../../common/Button";
import { OpenCertificateModal } from "./DownloadCertificate";
import { STUDENTS_TABLE_IMAGE_PATH } from "../../../common/config/configImage";

const useStyles = makeStyles({
  studentCard: { margin: "30px 0px" },
  buttonContainer: { display: "flex", justifyContent: "flex-end" },
  generateButton: { position: "fixed", marginBottom: 50, bottom: 0 },
});

export const AddCertificateDetails = ({ handleDrawerState, studentData }) => {
  const classes = useStyles();
  const initialFormData = {
    studentName: "",
    course: "",
    issueDate: new Date(),
  };
  const [formData, setFormData] = React.useState(initialFormData);
  const [showPreview, setShowPreview] = React.useState(false);

  React.useEffect(() => {
    const { studentName } = studentData;
    setFormData((data) => ({ ...data, studentName }));
  }, [studentData]);

  const handleChange = (name, value) => {
    setFormData({ ...formData, [`${name}`]: value });
  };

  const handleGenerateCertificate = () => {
    setShowPreview((preview) => !preview);
  };

  return (
    <React.Fragment>
      <OpenCertificateModal
        open={showPreview}
        certificateDetails={formData}
        handleClose={handleGenerateCertificate}
      />
      <AddCertificateHeader handleClose={handleDrawerState} />
      <Divider />
      <div className={classes.studentCard}>
        <StudentData
          formData={studentData}
          studentDOB={new Date(studentData?.dob.seconds * 1000)}
        />
      </div>
      <Typography
        style={{ fontWeight: "500", marginBottom: 15 }}
        variant="subtitle1"
      >
        Enter Details to generate Certificate
      </Typography>
      <Divider />
      <CertificateForm
        studentData={studentData}
        formData={formData}
        handleChange={handleChange}
      />
      <div className={classes.buttonContainer}>
        <PrimaryButton
          startIcon={
            <img
              alt="generate"
              src={STUDENTS_TABLE_IMAGE_PATH.GENERATE_CERTIFICATE}
            />
          }
          className={classes.generateButton}
          variant="contained"
          color="primary"
          disabled={!formData.course}
          onClick={handleGenerateCertificate}
        >
          Generate
        </PrimaryButton>
      </div>
    </React.Fragment>
  );
};
