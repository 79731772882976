import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { ScheduleEvent } from "./AddEventDetails";

const useStyles = makeStyles({
  fullList: {
    minWidth: "729px",
    height: "1024px",
    padding: "32px 38px 50px",
  },
});

export const ScheduleEventModal = ({ open, handleState, eventDetails }) => {
  const classes = useStyles();

  const classe = `container mt-5 ${classes.fullList}`;

  return (
    <React.Fragment>
      <React.Fragment key={"right"}>
        <SwipeableDrawer anchor={"right"} open={open} onClose={handleState}>
          <div className={classe}>
            <ScheduleEvent
              handleDrawerState={handleState}
              eventDetails={eventDetails}
            />
          </div>
        </SwipeableDrawer>
      </React.Fragment>
    </React.Fragment>
  );
};
