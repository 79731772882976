import React from "react";
import XLSX from "xlsx";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import { StudentsTable } from "./StudentsTable";
import { UploadStudentsData } from "./UploadSheet";
import { Btn } from "../AddStudents/AddStudent";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 900,
    },
  },
  container: {
    margin: "25px 40px 47px",
    width: 722,
  },
}));
export const AddMultipleStudents = () => {
  const classes = useStyles();
  let file = [];
  const [open, setOpen] = React.useState(false);
  const [studentData, setStudentData] = React.useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpload = async (event) => {
    file = event.target.files;
    await handleData();
  };

  const handlerDropOver = (e) => {
    e.preventDefault();
  };

  const handlerDrop = async (e) => {
    e.preventDefault();
    file = e.dataTransfer.files;
    await handleData();
  };

  const handleData = async (e) => {
    if (file.length < 1) {
      alert("Choose Correct File...");
      return;
    }
    if (typeof FileReader !== "undefined") {
      const reader = new FileReader();
      if (reader.readAsBinaryString) {
        reader.onload = (e) => {
          const workbook = XLSX.read(reader.result, { type: "binary" });
          const firstSheet = workbook.SheetNames[0];
          let excelRows = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[firstSheet]
          );
          for (let row in excelRows) {
            excelRows[row]["Course Name"] =
              excelRows[row]["Course Name"].split(",");
            excelRows[row]["Course Duration"] =
              excelRows[row]["Course Duration"].split(",");
            excelRows[row]["Course Fee"] =
              typeof excelRows[row]["Course Fee"] === "string"
                ? excelRows[row]["Course Fee"].split(",")
                : JSON.stringify(excelRows[row]["Course Fee"]).split(",");
          }
          setStudentData(excelRows);
        };
        reader.readAsBinaryString(file[0]);
      }
    } else {
      console.log("This browser does not support HTML5.");
    }
  };

  return (
    <div>
      <Btn onClick={handleClickOpen}>
        <img src="/assets/vector.svg" alt="Upload File" />
        Upload Excel or CSV File
      </Btn>
      <Dialog open={open} onClose={handleClose} className={classes.dialog}>
        <div className={classes.container}>
          <UploadStudentsData
            handleClose={handleClose}
            handleUpload={handleUpload}
            handlerDrop={handlerDrop}
            handlerDropOver={handlerDropOver}
          />
          <StudentsTable
            studentsData={studentData}
            setStudentData={setStudentData}
            handleClose={handleClose}
          />
        </div>
      </Dialog>
    </div>
  );
};
