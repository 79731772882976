import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { Avatar, List } from "@material-ui/core";
import { SidebarTab } from "./SidebarTab";
import { Link } from "react-router-dom";
import { INPUT_IMAGE_PATH } from "../../common/config/configImage";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 248,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 90,
    backgroundColor: "#161819",
    borderRight: "1px solid #333333",
  },
  drawerContainer: {
    overflow: "scroll",
  },
  container: {
    padding: "24px 0px",
  },
  Logout: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20vh",
    cursor: "pointer",
  },
  LogoutButton: {
    color: "#fff",
    margin: "10px 0px",
  },
}));

export const SidebarDrawer = () => {
  const classes = useStyles();
  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerContainer}>
        <List>
          <Link to="/">
            <div className={classes.container}>
              <Avatar
                alt="Profile"
                src={INPUT_IMAGE_PATH.ROGUE_CODE_LOGO}
                style={{ width: 50, height: 50, margin: "auto" }}
              />
            </div>
          </Link>
          <SidebarTab />
        </List>
      </div>
    </Drawer>
  );
};
