import { ModalHeader } from "../../../common/ModalHeader/ModalHeader";
import { makeStyles } from "@material-ui/core/styles";
import ProductInfo1 from "../../../globals/assets/add-products/product_info_1.svg";
import Description1 from "../../../globals/assets/add-products/description_1.svg";
import ProductInfo2 from "../../../globals/assets/add-products/product_info_2.svg";
import Description2 from "../../../globals/assets/add-products/description_2.svg";
import { AddProductInfo } from "./AddProductInfo";
import { AddProductDescription } from "./AddProductDescription";
import { useState } from "react";
import { FormFooter } from "./Footer";
import { useEffect } from "react";

const useStyles = makeStyles({
  root: {
    borderBottom: "1px solid #808080",
  },
  stepper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 104,
  },
});

const initialData = {
  productImage: [],
  productName: "",
  productCode: "",
  productCategory: "",
  productMRP: "",
  discountPrice: "",
  stock: "",
  description: "",
};

export const AddProductForm = ({ handleClose, editProductData, isEditProduct }) => {
  const classes = useStyles();
  const [active, setActive] = useState(1);
  const [formData, setForm] = useState(initialData);

  useEffect(() => {
    if (editProductData) setForm({ ...editProductData });
  }, [editProductData]);

  const handleChangeForm = (field, value) => {
    setForm({ ...formData, [field]: value });
  };

  const handleResetForm = () => {
    setForm({ ...initialData, productImage: [] });
  };

  return (
    <div>
      <div className={classes.root}>
        <ModalHeader
          headerTitle={editProductData?.docId ? "Edit Product" : "Add Product"}
          handleClose={handleClose}
        />
      </div>
      <div className={classes.stepper}>
        {active === 1 ? (
          <>
            <img alt={ProductInfo1} src={ProductInfo1} />
            <img alt={Description1} src={Description1} />
          </>
        ) : (
          <>
            <img alt={ProductInfo2} src={ProductInfo2} />
            <img alt={Description2} src={Description2} />
          </>
        )}
      </div>
      {active === 1 ? (
        <AddProductInfo
          formData={formData}
          handleChangeForm={handleChangeForm}
          isEditProduct={isEditProduct}
        />
      ) : null}
      {active === 2 ? (
        <AddProductDescription
          formData={formData}
          handleChangeForm={handleChangeForm}
        />
      ) : null}
      <FormFooter
        active={active}
        setActive={setActive}
        formData={formData}
        handleClose={handleClose}
        handleResetForm={handleResetForm}
        editProductData={editProductData}
      />
    </div>
  );
};
