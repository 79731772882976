import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CourseHeader from "./CourseHeader";
import CourseInfo from "./CourseInfo";
import CourseModal from "./CourseModal";
import { course } from "../../data/course";

const useStyles = makeStyles((theme) => ({
  container: { background: "#eee", minHeight: "100vh" },
}));

const ViewCourses = () => {
  const classes = useStyles();
  const [modal, setModal] = useState(false);

  const handleModal = () => {
    setModal(true);
  };
  return (
    <>
      <div className={classes.container}>
        <CourseHeader handleModal={handleModal} />
        <div>
          <CourseInfo course={course} />
        </div>
      </div>
      <CourseModal
        closeModal={() => setModal((modal) => !modal)}
        modal={modal}
      />
    </>
  );
};

export default ViewCourses;
