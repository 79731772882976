import { firestore } from "./firebase";

export async function getBlogs(setBlogs, setFilteredResult) {
  firestore.collection("Blogs").onSnapshot((querySnapshot) => {
    const data = [];
    querySnapshot?.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    setBlogs(data);
    setFilteredResult(data);
  });
}

export const getStudentsItems = async (date) => {
  const data = [];
  const collectionRef = await firestore.collection("Students").get();
  if (collectionRef.docs.length > 0) {
    for (let doc of collectionRef.docs) {
      data.push({ ...doc.data(), id: doc.id });
    }
    return data;
  } else {
    return data;
  }
};

export function getEvents(startDate, endDate, setEvents, setFilteredResult) {
  firestore
    .collection("Events")
    .where("eventDate", ">=", startDate)
    .where("eventDate", "<=", endDate)
    .onSnapshot((querySnapshot) => {
      const data = [];
      querySnapshot?.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      console.log(data);
      setEvents(data);
      setFilteredResult(data);
    });
}

export function getProducts(setProducts) {
  firestore.collection("Products").onSnapshot((querySnapshot) => {
    const data = [];
    querySnapshot?.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    setProducts(data);
  });
}

export function getReminder(
  collectionName,
  setReminderData,
  setFilteredResult
) {
  firestore.collection(collectionName).onSnapshot((querySnapshot) => {
    const data = [];
    querySnapshot?.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    setFilteredResult(data);
    setReminderData(data);
  });
}

export function getBatch(setEvents, setFilteredResult) {
  firestore.collection("Batch").onSnapshot((querySnapshot) => {
    const data = [];
    querySnapshot?.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    setEvents(data);
    setFilteredResult(data);
  });
}

export async function getBatchDetails(setBatch, id) {
  firestore
    .collection("Batch")
    .doc(id)
    .onSnapshot((querySnapshot) => {
      setBatch({ ...querySnapshot.data(), id: querySnapshot.id });
    });

  const collectionRef = await firestore.collection("Batch").doc(id).get();
  if (!collectionRef.exists) {
    return null;
  }
  return collectionRef.data();
}

export async function getBatchCourseDetails(courseIds) {
  if (!courseIds?.length) {
    courseIds = [""];
  }
  const data = [];
  const collectionRef = await firestore
    .collection("Course")
    .where("courseId", "in", courseIds)
    .get();
  if (collectionRef.docs.length > 0) {
    for (let doc of collectionRef.docs) {
      data.push({ ...doc.data(), id: doc.id });
    }
    return data;
  } else {
    return data;
  }
}

export async function getBatchStudentsDetails(studentIds) {
  if (!studentIds?.length) {
    studentIds = [""];
  }
  const data = [];
  const collectionRef = await firestore
    .collection("Students")
    .where("docId", "in", studentIds)
    .get();
  if (collectionRef.docs.length > 0) {
    for (let doc of collectionRef.docs) {
      data.push({ ...doc.data(), id: doc.id });
    }
    return data;
  } else {
    return data;
  }
}

export async function getToAddBatchCourseDetails(courseIds) {
  if (!courseIds?.length) {
    courseIds = [""];
  }
  const data = [];
  const collectionRef = await firestore
    .collection("Course")
    .where("courseId", "not-in", courseIds)
    .get();
  if (collectionRef.docs.length > 0) {
    for (let doc of collectionRef.docs) {
      data.push({ ...doc.data(), id: doc.id });
    }
    return data;
  } else {
    return data;
  }
}

export async function getToAddBatchStudentDetails(studentIds) {
  if (!studentIds?.length) {
    studentIds = [""];
  }

  const data = [];
  const collectionRef = await firestore
    .collection("Students")
    .where("docId", "not-in", studentIds)
    .get();
  if (collectionRef.docs.length > 0) {
    for (let doc of collectionRef.docs) {
      data.push({ ...doc.data(), id: doc.id });
    }
    return data;
  } else {
    return data;
  }
}
