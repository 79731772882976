import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { OVERVIEW_CARDS } from "../../../common/config/configURL";
import { Typography } from "@material-ui/core";
// import basket from "../../../globals/assets/overview/growth.svg";
// console.log(basket);

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    gap: "50px",
  },
  ordersBody: {
    width: 925,
    height: 395,
    background: "#ccc",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 9,
  },
  cards: {
    padding: "15px 25px",
    background: "#fff",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 7,
    width: 267,
    height: 67,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 15,
  },
  title: {
    color: "#565656",
    fontWeight: 600,
    fontSize: 16,
  },
  cardImg: {
    display: "flex",
    gap: 10,
    alignItems: "center",
  },
}));

const OverViewOrders = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.container}>
        <div className={classes.ordersBody}></div>
        <div>
          {OVERVIEW_CARDS.map((item, index) => {
            return (
              <div className={classes.cards}>
                <div className={classes.cardImg}>
                  <img src={item.logo} alt="" />
                  <Typography>{item.totalCount}</Typography>
                </div>
                <Typography className={classes.title}>{item.title}</Typography>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default OverViewOrders;
