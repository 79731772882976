import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { AddDomainForm } from "./AddReminder/DomainReminderForm";
import { AddSSLForm } from "./AddReminder/SSLReminderForm";
import { AddHostForm } from "./AddReminder/HostReminderForm";

const useStyles = makeStyles({
  fullList: {
    minWidth: "729px",
    height: "1024px",
    padding: "32px 38px 50px",
  },
});

export const ReminderFormModal = ({
  isOpen,
  openTab,
  handleState,
  selectedReminder,
}) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <React.Fragment key={"right"}>
        <SwipeableDrawer
          anchor={"right"}
          open={isOpen}
          onClose={() => handleState(openTab)}
        >
          <div className={classes.fullList}>
            {reminderForm(openTab, handleState, selectedReminder)}
          </div>
        </SwipeableDrawer>
      </React.Fragment>
    </React.Fragment>
  );
};

const reminderForm = (openTab, handleState, selectedReminder) => {
  switch (openTab) {
    case "Domain":
      return (
        <AddDomainForm
          openTab={openTab}
          handleState={handleState}
          editDomain={selectedReminder}
        />
      );
    case "SSL":
      return (
        <AddSSLForm
          openTab={openTab}
          handleState={handleState}
          editSSL={selectedReminder}
        />
      );
    case "Host":
      return (
        <AddHostForm
          openTab={openTab}
          handleState={handleState}
          editHost={selectedReminder}
        />
      );
    default:
      break;
  }
};
