import React from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "../components/Navbar";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
// import { ViewBlogsComponent } from "../pages/Blogs/ViewBlog/ViewBlogs";
import { ViewEventsComponent } from "../pages/Events/ViewEvents/ViewEvents";
import { ToastContainer } from "react-toastify";
import BlogDetails from "../pages/Blogs/ViewBlog/BlogPreview/BlogDetails";
import {
  CORE_BLOGS,
  CORE_BLOGS_DETAILS,
  CORE_DASHBOARD,
  CORE_EVENTS,
  CORE_NOT_FOUND,
  CORE_SHOP_ORDERS,
  CORE_SHOP_OVERVIEW,
  CORE_SHOP_PRODUCT,
  CORE_PRODUCT_VIEW,
  CORE_COURSE,
  CORE_COURSE_VIEW,
  CORE_REMINDER_DOMAIN,
  CORE_REMINDER_SSL,
  CORE_REMINDER_HOST,
  CORE_BATCH,
  CORE_BATCH_DETAILS,
  CORE_ADD_STUDENT,
  CORE_LOGIN,
} from "../common/config/configURL";
import { ShopOverviewTab } from "../pages/CoreShopTab/CoreShopOverview/ShopOverview";
import { ShopOrdersTab } from "../pages/CoreShopTab/CoreShopOrders/ShopOrders";
import { ShopProductTab } from "../pages/CoreShopTab/CoreShopProduct/ShopProduct";
import ProductCard from "../pages/CoreShopTab/ProductView/ProductCard";
import ViewCourses from "../pages/Courses/ViewCourses";
import PreviewCourse from "../pages/Courses/PreviewCourse/PreviewCourse";
import { ViewSSL } from "../pages/CoreReminderTab/ViewSSL/ViewSSL";
import { ViewHost } from "../pages/CoreReminderTab/ViewHost/ViewHost";
import { ViewDomain } from "../pages/CoreReminderTab/ViewDomain/ViewDomain";
import { Batch } from "../pages/CoreBatch/ViewBatch/ViewBatches";
import { ViewBatchDetails } from "../pages/CoreBatch/ViewBatchDetails/ViewBatchDetails";
import { MarketingBlogs } from "../pages/Marketing/MarketingBlogs";
import { MarketingEvets } from "../pages/Marketing/MarketingEvets";
import { AddSingleStudent } from "../pages/Student/AddStudent";
import LoginPage from "../pages/Login/LoginPage";
import { DEVICE } from "../common/const";

export const AppRouter = () => {
  return (
    <>
      <Router>
        <Navbar />
        <RoutesContainer>
          <Routes>
            <Route path={"/"} element={<Home />} />
            <Route path={CORE_LOGIN} element={<LoginPage />} />
            <Route path={CORE_DASHBOARD} element={<Home />} />
            <Route path={CORE_BLOGS_DETAILS} element={<BlogDetails />} />
            <Route path={CORE_SHOP_OVERVIEW} element={<ShopOverviewTab />} />
            <Route path={CORE_SHOP_ORDERS} element={<ShopOrdersTab />} />
            <Route path={CORE_SHOP_PRODUCT} element={<ShopProductTab />} />
            <Route path={CORE_REMINDER_DOMAIN} element={<ViewDomain />} />
            <Route path={CORE_REMINDER_SSL} element={<ViewSSL />} />
            <Route path={CORE_REMINDER_HOST} element={<ViewHost />} />
            <Route path={CORE_NOT_FOUND} element={<NotFound />} />
            <Route path={CORE_PRODUCT_VIEW} element={<ProductCard />} />
            <Route path={CORE_COURSE} element={<ViewCourses />} />
            <Route path={CORE_BATCH} element={<Batch />} />
            <Route path={CORE_BATCH_DETAILS} element={<ViewBatchDetails />} />
            <Route path={CORE_COURSE_VIEW} element={<PreviewCourse />} />
            <Route path={CORE_BLOGS} element={<MarketingBlogs />} />
            <Route path={CORE_EVENTS} element={<MarketingEvets />} />
            {/* add student */}
            <Route
              path={CORE_ADD_STUDENT}
              element={<AddSingleStudent isRedirect={true} />}
            />
          </Routes>
        </RoutesContainer>
      </Router>
      <ToastContainer />
    </>
  );
};

const RoutesContainer = styled.div`
  margin-left: 90px;
  margin-top: 56px;

  @media ${DEVICE.laptop} {
    margin: 0px;
  }
`;
