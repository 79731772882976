import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { PrimaryButton } from "../../common/Button";
import { INPUT_IMAGE_PATH } from "../../common/config/configImage";
import { SAMPLE_UPLOAD_STUDENTS_URL } from "../../common/const";

const useStyles = makeStyles({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "18px",
  },
  sampleButton: {
    height: "100%",
    width: 170,
    margin: "0px 15px",
  },
});

export const UploadStudentsData = ({
  handleClose,
  handleUpload,
  handlerDrop,
  handlerDropOver,
}) => {
  const classes = useStyles();

  const handleDownloadSample = () => {
    const link = document.createElement("a");
    link.download = `sample.xlsx`;
    link.href = SAMPLE_UPLOAD_STUDENTS_URL;
    link.click();
  };

  return (
    <>
      <div onDrop={handlerDrop} onDragOver={handlerDropOver}>
        <div className={classes.header}>
          <div>
            <Typography variant="h7">Upload Excel or CSV File</Typography>
            <PrimaryButton
              className={classes.sampleButton}
              color="primary"
              variant="contained"
              onClick={handleDownloadSample}
            >
              Download Sample
            </PrimaryButton>
          </div>
          <Button onClick={handleClose}>
            <CloseIcon />
          </Button>
        </div>
        <div>
          <label>
            <img alt="upload Excel" src={INPUT_IMAGE_PATH.UPLOAD_EXCEL} />
            <input
              type="file"
              accept=".xlsx"
              name="uploadExcel"
              style={{ display: "none" }}
              onChange={handleUpload}
            />
          </label>
        </div>
      </div>
    </>
  );
};
