import { firebase, firestore } from "./firebase";

export async function deleteCourse(courseId) {
  const collectionRef = firestore.collection("Course").doc(courseId);

  await collectionRef.delete().catch((error) => {
    console.error("Error removing document: ", error);
    return { successfull: false, message: "There is some Problem" };
  });
  return {
    successfull: true,
    message: "Course has been successfully deleted.",
  };
}

export async function deleteAssignment(itemId, id) {
  const collectionRef = firestore
    .collection("Course")
    .doc(id)
    .collection("Assignment")
    .doc(itemId);

  await collectionRef.delete().catch((error) => {
    console.error("Error removing document: ", error);
    return { successfull: false, message: "There is some Problem" };
  });
  return {
    successfull: true,
    message: "Assignment has been successfully deleted.",
  };
}

export const editAssignment = async (itemId, id, data) => {
  const collectionRef = firestore
    .collection("Course")
    .doc(id)
    .collection("Assignment")
    .doc(itemId);
  await collectionRef
    .update({
      ...data,
    })
    .catch((e) => {
      return { successfull: false, message: "There is some Problem" };
    });
  return {
    successfull: true,
    message: "Data has been successfully updated.",
  };
};

export async function deleteQuiz(itemId, id) {
  const collectionRef = firestore
    .collection("Course")
    .doc(id)
    .collection("Quiz")
    .doc(itemId);

  await collectionRef.delete().catch((error) => {
    console.error("Error removing document: ", error);
    return { successfull: false, message: "There is some Problem" };
  });
  return {
    successfull: true,
    message: "Quiz has been successfully deleted.",
  };
}

export const editQuizz = async (itemId, id, data) => {
  const collectionRef = firestore
    .collection("Course")
    .doc(id)
    .collection("Quiz")
    .doc(itemId);
  await collectionRef
    .update({
      ...data,
    })
    .catch((e) => {
      return { successfull: false, message: "There is some Problem" };
    });
  return {
    successfull: true,
    message: "Data has been successfully updated.",
  };
};

export async function deletePpt(itemId, id) {
  const collectionRef = firestore
    .collection("Course")
    .doc(id)
    .collection("PPT")
    .doc(itemId);

  await collectionRef.delete().catch((error) => {
    console.error("Error removing document: ", error);
    return { successfull: false, message: "There is some Problem" };
  });
  return {
    successfull: true,
    message: "PPT has been successfully deleted.",
  };
}

export const editPpt = async (itemId, id, data) => {
  const collectionRef = firestore
    .collection("Course")
    .doc(id)
    .collection("PPT")
    .doc(itemId);
  await collectionRef
    .update({
      ...data,
    })
    .catch((e) => {
      return { successfull: false, message: "There is some Problem" };
    });
  return {
    successfull: true,
    message: "Data has been successfully updated.",
  };
};

export async function deleteCourseVideo(itemId, id) {
  const collectionRef = firestore
    .collection("Course")
    .doc(id)
    .collection("Video")
    .doc(itemId);

  await collectionRef.delete().catch((error) => {
    console.error("Error removing document: ", error);
    return { successfull: false, message: "There is some Problem" };
  });
  return {
    successfull: true,
    message: "PPT has been successfully deleted.",
  };
}

export const editCourseVideo = async (itemId, id, data) => {
  const collectionRef = firestore
    .collection("Course")
    .doc(id)
    .collection("Video")
    .doc(itemId);
  await collectionRef
    .update({
      ...data,
    })
    .catch((e) => {
      return { successfull: false, message: "There is some Problem" };
    });
  return {
    successfull: true,
    message: "Data has been successfully updated.",
  };
};
