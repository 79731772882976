import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { StudentDetails } from "./StudentDetails";
import Dialog from "@material-ui/core/Dialog";

const useStyles = makeStyles({
  container: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 1228,
    },
  },
  fullList: {
    minWidth: "1228px",
    height: "1016px",
    padding: "32px 38px 50px",
  },
});

export function OpenViewStudentDetails({ state, setState, student }) {
  const classes = useStyles();

  const handleDrawerClose = () => {
    setState(false);
  };
  const classe = `container mt-5 ${classes.fullList}`;

  return (
    <div>
      <Dialog
        className={classes.container}
        open={state}
        onClose={handleDrawerClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classe}>
          <StudentDetails
            handleDrawerClose={handleDrawerClose}
            student={student}
          />
        </div>
      </Dialog>
    </div>
  );
}
