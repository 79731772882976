import React from "react";
import { Divider, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { BlogDescription } from "./AddBlogDescription";
import { PublishChanges } from "../../../common/Button";
import PublishIcon from "@material-ui/icons/Publish";
import { AddBlogDetails } from "./AddBlogDetails";
import {
  getDownloadURL,
  uploadFileToStorage,
} from "../../../backend/UploadMedia";
import { addBlog as createBlog } from "../../../backend/sendDataToFirestore";
import { editBlog } from "../../../backend/EditDataToFirebase";
import { errorToast, successToast } from "../../../common/Toastify";
import Button from "@material-ui/core/Button";
import { INPUT_IMAGE_PATH } from "../../../common/config/configImage";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "6px 5px 0px 32px",
  },
  header: {
    fontSize: 27,
    fontWeight: "600",
    color: "#5F5F5F",
  },
  closeIcon: { fontSize: "30px" },
  content: {
    display: "flex",
    height: 577,
  },
  leftContainer: { minWidth: 367, padding: "32px 37px 22px 32px" },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: 28,
  },
  editorContainer: {
    width: "100%",
  },
}));

export const AddBlog = ({
  handleClose,
  blogDetails,
  setBlogModal,
  blogmodal,
}) => {
  const classes = useStyles();
  const initialData = {
    blogTitle: "",
    blogKeywords: [],
    blogDescription: "",
    blogImage: "",
    blogAuthor: "",
  };
  const [isExpand, setExpand] = React.useState(false);
  const [formData, setFormData] = React.useState(initialData);

  React.useEffect(() => {
    if (blogDetails) setFormData({ ...blogDetails });
  }, [blogDetails]);

  const handleChange = (name, value) => {
    setFormData({ ...formData, [`${name}`]: value });
  };

  const handleUpload = async (file) => {
    console.log(typeof file);
    if (typeof file === "string") return { url: file };
    const id =
      Date.now().toString(36) + Math.random().toString(36).substring(2);
    console.log(id);
    const ref = `/blog-images/${id}`;
    const uploadTask = uploadFileToStorage(ref, file);
    const url = await getDownloadURL(uploadTask);
    return { url, ref };
  };

  const handleUploadForm = async () => {
    let res;
    if (!formData.id) {
      const imageData = await handleUpload(formData.blogImage);
      res = await createBlog({
        ...formData,
        updatedDate: new Date(),
        blogImage: imageData.url,
      });
    } else {
      res = await editBlog({ ...formData });
    }

    if (res.successfull) {
      successToast("Blog");
      handleClose();
      setExpand(false);
    } else {
      errorToast();
    }
  };

  const handlerExpand = () => {
    setExpand(!isExpand);
    setBlogModal(!blogmodal);
  };

  return (
    <React.Fragment>
      <div
        className={classes.headerContainer}
        style={{ width: isExpand ? "100vw" : "" }}
      >
        <Typography className={classes.header}>
          {!formData.id ? "New Blog" : "Edit Blog"}
        </Typography>

        <div>
          <IconButton onClick={handlerExpand}>
            <img
              src={INPUT_IMAGE_PATH.BLOG_EXPAND}
              alt="expand"
              style={{ width: 18, height: 18, color: "rgba(0, 0, 0, 0.54)" }}
            />
          </IconButton>
          <IconButton onClick={handleClose}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
      </div>
      <Divider />

      <div className={classes.content}>
        <div
          className={classes.leftContainer}
          style={{ display: isExpand ? "none" : "" }}
        >
          <AddBlogDetails formData={formData} handleChange={handleChange} />
        </div>
        <Divider
          orientation="vertical"
          flexItem
          style={{ display: isExpand ? "none" : "" }}
        />

        <div className={classes.editorContainer}>
          <BlogDescription
            formData={formData}
            handleChange={handleChange}
            isExpand={isExpand}
          />
          <div className={classes.footer}>
            <PublishChanges
              onClick={handleUploadForm}
              endIcon={<PublishIcon />}
            >
              Publish Changes
            </PublishChanges>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
