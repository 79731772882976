import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ToolBar from "../ToolBar";
import TableAssignment from "./TableAssignment";
import ModalAssignment from "./ModalAssignment";
import { useParams } from "react-router-dom";
import { getAssignmentData } from "../../../backend/getCourseData";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "40px 90px",
  },
  table: {
    marginTop: 30,
  },
}));

const AssignmentPreview = () => {
  const classes = useStyles();
  const [modalAssignment, setModalAssignment] = useState(false);
  const [assignmentData, setAssignmentData] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    handlerGetAssignment(id);
  }, [id]);

  const handlerGetAssignment = async (id) => {
    await getAssignmentData(setAssignmentData, id);
  };

  const handleModal = () => {
    setModalAssignment(true);
  };

  return (
    <>
      <div className={classes.container}>
        <ToolBar btnText="Add Assignment" handleModal={handleModal} />
        <div className={classes.table}>
          <TableAssignment assignmentData={assignmentData} />
        </div>
      </div>
      <ModalAssignment
        setModalAssignment={setModalAssignment}
        modalAssignment={modalAssignment}
      />
    </>
  );
};

export default AssignmentPreview;
