import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import parse from "html-react-parser";

const useStyles = makeStyles((theme) => ({
  description: {
    padding: "0 30px",
    marginTop: 20,
  },
  list: {
    "& li": {
      padding: "0 0 8px 20px",
    },
  },
}));

const ProductDescription = ({ product }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.description}>
        <Typography variant="h6" gutterBottom>
          Description
        </Typography>
        <div>{product?.description ? parse(product?.description) : null}</div>
      </div>
    </>
  );
};

export default ProductDescription;
