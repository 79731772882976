import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import QuizTable from "./QuizTable";
import ModalQuiz from "./ModalQuiz";
import ToolBar from "../ToolBar";
import { getQuizData } from "../../../backend/getCourseData";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  quizHeader: {
    display: "flex",
    gap: 20,
  },
  search: {
    width: 300,
    height: 35,
  },
  select: {
    width: 180,
  },
  cards: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  quizBody: {
    padding: "40px 90px",
  },
  table: {
    marginTop: 30,
  },
}));

const QuizPreview = () => {
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [quizData, setQuizData] = useState([]);
  const [searchVal, setSearch] = useState("");
  const { id } = useParams();

  useEffect(() => {
    handlerGetQuiz(id);
  }, [id]);

  const handlerGetQuiz = async (id) => {
    await getQuizData(setQuizData, id);
  };

  console.log(quizData)

  const handleModal = () => {
    setModal(true);
  };

  
  return (
    <>
      <div className={classes.quizBody}>
        <ToolBar
          btnText="Add Quiz"
          handleModal={handleModal}
          setSearch={setSearch}
          searchVal={searchVal}
        />
        <div className={classes.table}>
          <QuizTable quizData={quizData} searchVal={searchVal} />
        </div>
      </div>
      <ModalQuiz setModal={setModal} modal={modal} />
    </>
  );
};

export default QuizPreview;
