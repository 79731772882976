import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import Typography from "@material-ui/core/Typography";
import { ReminderNotifications } from "./ReminderNotifications";
import { BirthdayNotifications } from "./BirthdayNotifications";
import { getStudentsItems } from "../../backend/getDataFromFirestore";
import { getReminderNotification } from "../../backend/notifications/getNotifications";
import Badge from "@material-ui/core/Badge";
import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    maxWidth: "350px",
    zIndex: 999,
    position: "absolute",
    top: "90%",
    right: "5%",
    padding: "15px",
    borderRadius: "5px",
    color: "#000",
    backgroundColor: "#fff",
    backdropFilter: "blur(5px)",
  },
  box: {
    padding: "0px",
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "15px",
  },
  button: {
    border: "1px solid #ccc",
    borderRadius: "5px",
    width: "30px",
    height: "30px",
  },
  User: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  UserName: {
    fontSize: "16px",
    fontWeight: 500,
    margin: 0,
    padding: 0,
  },
  UserBday: {
    fontSize: "14px",
    fontWeight: 400,
  },
  UserDetails: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
  bellIcon: {
    color: "#B3B3B3",
  },
  sectionDesktop: {
    display: "none",

    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  tabContainer: {
    marginBottom: 20,
  },
  tabWrapper: { fontWeight: "800" },
}));

export const Notifications = ({ open, setOpen }) => {
  const classes = useStyles();
  const [students, setStudents] = useState([]);
  const [reminderNotification, setReminderNotification] = useState({
    domain: [],
    ssl: [],
    host: [],
  });
  const [value, setValue] = React.useState(0);

  let totalNotification = students.length || 0;
  Object.keys(reminderNotification).forEach((type) => {
    totalNotification += reminderNotification[type].length;
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getStudents();
    getReminders();
  }, []);

  const getStudents = async () => {
    const data = await getStudentsItems(new Date());

    if (data) {
      const currentDate = new Date();
      const futureDate = new Date();
      futureDate.setDate(currentDate.getDate() + 15);

      const filteredArr = data.filter((item) => {
        const dobDate = new Date(item.dob.seconds * 1000);

        dobDate.setFullYear(currentDate.getFullYear());

        const isUpcoming =
          dobDate.getMonth() === currentDate.getMonth() &&
          dobDate.getDate() >= currentDate.getDate() &&
          dobDate.getDate() <= futureDate.getDate();
        return isUpcoming;
      });
      setStudents(filteredArr);
    }
  };

  const getReminders = async () => {
    await getReminderNotification(setReminderNotification);
  };

  return (
    <div>
      <div className={classes.sectionDesktop}>
        <IconButton color="">
          <Badge
            badgeContent={totalNotification}
            color="primary"
            className={classes.bellIcon}
            onClick={(e) => setOpen(!open)}
          >
            <NotificationsOutlinedIcon />
          </Badge>
        </IconButton>
      </div>
      {open && (
        <Card className={classes.card}>
          <CardContent className={classes.box}>
            <div className={classes.container}>
              <Typography variant="h6" gutterBottom>
                Notifications
              </Typography>
              <IconButton
                aria-label="delete"
                className={classes.button}
                onClick={() => setOpen(false)}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            </div>
            <div className={classes.tabContainer}>
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label="Reminder" className={classes.tabWrapper} />
                <Tab label="Birthday" className={classes.tabWrapper} />
              </Tabs>
            </div>
            {value === 0 ? (
              <ReminderNotifications
                reminderNotification={reminderNotification}
              />
            ) : value === 1 ? (
              <BirthdayNotifications students={students} />
            ) : null}
          </CardContent>
        </Card>
      )}
    </div>
  );
};
