import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CourseCards from "./CourseCards";
import { getAllCourseDetails } from "../../backend/getCourseData";
import EditCourseModal from "../Courses/EditCourse/EditCourseModal.jsx";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "40px 90px",
  },
  searchInputs: {
    display: "flex",
    gap: 20,
  },
  search: {
    width: 300,
    height: 35,
  },
  select: {
    width: 180,
  },
  cards: {
    display: "flex",
    flexWrap: "wrap",
    gap: 45,
  },
}));

const CourseInfo = ({ course }) => {
  const classes = useStyles();
  const [courseData, setCourseData] = useState([]);
  const [searchVal, setSearch] = useState("");
  const [sortVal, setSort] = useState("ASC");

  const [openEditCourse, setOpenEditCourse] = useState(false);
  const [editCourseDetails, setEditCourseDetails] = useState({});

  useEffect(() => {
    getCourses();
  }, []);

  const getCourses = async () => {
    await getAllCourseDetails(setCourseData);
  };

  const handleEditCourseModal = (data) => {
    if (data) {
      setEditCourseDetails(data);
      setOpenEditCourse(true);
    }
  };

  const handleCloseModal = () => {
    setEditCourseDetails({});
    setOpenEditCourse(false);
  };

  return (
    <>
      <EditCourseModal
        closeModal={handleCloseModal}
        modal={openEditCourse}
        editCourseData={editCourseDetails}
      />
      <div className={classes.container}>
        <div className={classes.searchInputs}>
          <div>
            <TextField
              id="filled-required"
              label="Search"
              variant="outlined"
              placeholder="Name, subject, etc..."
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.search}
              name="search"
              onChange={(e) => setSearch(e.target.value)}
              value={searchVal}
            />
          </div>

          <div>
            <FormControl variant="outlined">
              <InputLabel>Sort By</InputLabel>
              <Select
                label="Sort By"
                className={classes.select}
                InputLabelProps={{
                  shrink: true,
                }}
                value={sortVal}
                onChange={(e) => setSort(e.target.value)}
              >
                <MenuItem value="ASC">Ascending</MenuItem>
                <MenuItem value="DESC">Descending</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        <div className={classes.cards}>
          {courseData
            ?.filter((item) =>
              searchVal === null || searchVal === ""
                ? item
                : item?.courseTitle
                    ?.toLowerCase()
                    .includes(searchVal.toLowerCase())
            )
            .map((item, index) => {
              return (
                <CourseCards
                  handleEditCourse={handleEditCourseModal}
                  key={index}
                  course={item}
                />
              );
            })}
        </div>
      </div>
    </>
  );
};

export default CourseInfo;
