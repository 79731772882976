import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    width: 550,
    padding: "20px 55px",
  },
  message: { textAlign: "center", fontSize: 30, fontWeight: "600" },
  subMessage: {
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: "12px 0px",
  },
  cancelButton: {
    width: 200,
    height: 35,
    textTransform: "capitalize",
    border: "1px solid #0A2340",
    fontWeight: "500",
  },
  successButton: {
    width: 200,
    height: 35,
    textTransform: "capitalize",
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
    fontWeight: "500",
    "&:hover": {
      backgroundColor: theme.palette.error.light,
    },
  },
}));

export const WarningModal = ({
  open,
  setOpen,
  message,
  subMessage,
  deleteItem,
}) => {
  const classes = useStyles();
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <div className={classes.container}>
          <Typography variant="h6" className={classes.message}>
            {`Are you sure you want to `}
            {<span style={{ color: "#F44336" }}>delete</span>}
            {` this ${message} data?`}
          </Typography>
          <Typography className={classes.subMessage}>{subMessage}</Typography>
          <div className={classes.buttonContainer}>
            <Button
              onClick={deleteItem}
              className={classes.successButton}
              autoFocus
            >
              Yes! Delete it
            </Button>
            <Button
              onClick={handleClose}
              variant="outlined"
              className={classes.cancelButton}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
