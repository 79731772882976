import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AddMultipleReminder from "./AddMultipleReminder/AddMultipleReminder";
import { Dialog } from "@material-ui/core";

const useStyles = makeStyles({
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 900,
    },
  },
  fullList: {
    minWidth: "729px",
    height: "1024px",
    padding: "32px 38px 50px",
  },
});

export const AddMultipleReminderModal = ({ isOpen, openTab, toggleView }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <React.Fragment key={"right"}>
        <Dialog
          open={isOpen}
          onClose={() => toggleView(openTab)}
          className={classes.dialog}
        >
          <div className={classes.fullList}>
            <AddMultipleReminder tabName={openTab} handleState={toggleView} />
          </div>
        </Dialog>
      </React.Fragment>
    </React.Fragment>
  );
};
