import { useEffect, useState } from "react";
import { getToAddBatchStudentDetails } from "../../../../backend/getDataFromFirestore";
import { AddStudents } from "./AddStudents";

export const AddStudentPage = ({ handleClose, studentIds }) => {
  const [students, setStudents] = useState([]);

  useEffect(() => {
    handleToSelectStudents(studentIds);
  }, [studentIds]);

  const handleToSelectStudents = async (studentIds) => {
    const res = await getToAddBatchStudentDetails(studentIds);
    setStudents(res);
  };

  return <AddStudents handleClose={handleClose} students={students} />;
};
