import React from "react";
import { TabMenu } from "../../../common/TabMenu/TabMenu";
import { TABS_CONFIG } from "../../../common/config/configURL";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import OverViewOrders from "./OverViewOrders";
import OverviewProducts from "./OverviewProducts";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "40px",
  },
}));

export const ShopOverviewTab = () => {
  const classes = useStyles();
  return (
    <div>
      <TabMenu tabs={TABS_CONFIG} />
      <div className={classes.container}>
        <div>
          <OverViewOrders />
          <OverviewProducts />
        </div>
      </div>
    </div>
  );
};
