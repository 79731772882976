import { firestore } from "../firebase";

export async function getReminderNotification(setReminderNotification) {
  const currentDate = new Date();
  const twoMonthsFromNow = new Date();
  twoMonthsFromNow.setMonth(currentDate.getMonth() + 2);

  firestore
    .collection("DomainReminder")
    .where("expireDate", "<", twoMonthsFromNow)
    .orderBy("expireDate", "asc")
    .onSnapshot((docs) => {
      const data = [];
      docs?.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      setReminderNotification((prev) => ({ ...prev, domain: data }));
    });
  firestore
    .collection("SslReminder")
    .where("expireDate", "<", twoMonthsFromNow)
    .orderBy("expireDate", "asc")
    .onSnapshot((docs) => {
      const data = [];
      docs?.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      setReminderNotification((prev) => ({ ...prev, ssl: data }));
    });
  firestore
    .collection("HostReminder")
    .where("expireDate", "<", twoMonthsFromNow)
    .orderBy("expireDate", "asc")
    .onSnapshot((docs) => {
      const data = [];
      docs?.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      setReminderNotification((prev) => ({ ...prev, host: data }));
    });
}
