import React, { useState, useEffect } from "react";
import { EnrollStudentTable } from "./EnrollStudentTable";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, InputLabel, Select, TextField } from "@material-ui/core";
import { AddBatchStudent } from "../AddBatchStudent/AddBatchStudent";

const useRowStyles = makeStyles((theme) => ({
  container: {
    padding: "31px 90px",
    background: theme.palette.grey[100],
  },
  searchContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: 500,
  },
  studentTable: {
    marginTop: 35,
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export const BatchStudentsInfo = ({ studentsData, studentsIds }) => {
  const classes = useRowStyles();
  const [enrolledStudents, setEnrolledStudents] = useState();
  const [filteredResult, setFilteredResult] = useState(); //initialBatch

  useEffect(() => {
    setEnrolledStudents(studentsData);
    setFilteredResult(studentsData);
  }, [studentsData]);

  const [searchValue, setSearchValue] = useState("");
  const [sort, setSort] = useState("asc");

  function compareAB(a, b) {
    var nameA = a ? a.toString().toUpperCase() : ""; // ignore upper and lowercase
    var nameB = b ? b.toString().toUpperCase() : ""; // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  const searchLeadResult = (name, status) => {
    if (!enrolledStudents?.length) {
      return;
    }

    if (status === "asc" && !name) {
      return enrolledStudents?.sort((a, b) =>
        compareAB(a["studentName"], b["studentName"])
      );
    } else if (status === "desc" && !name) {
      return enrolledStudents?.sort(
        (a, b) => -1 * compareAB(a["studentName"], b["studentName"])
      );
    }

    return enrolledStudents?.filter((pros) => {
      if (name) {
        return (
          pros?.studentName &&
          pros?.studentName.toLowerCase().includes(name.toLowerCase())
        );
      }
      return true;
    });
  };

  const onSearch = (e) => {
    setSearchValue(e.target.value);
    setFilteredResult(searchLeadResult(e.target.value, sort));
  };

  const onSort = (e) => {
    setSort(e.target.value);
    setFilteredResult(searchLeadResult(searchValue, e.target.value));
  };

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <div className={classes.searchContainer}>
            <TextField
              size="small"
              style={{ width: 300, height: 38 }}
              label="Search"
              placeholder="Search by Batch name..."
              variant="outlined"
              value={searchValue}
              onChange={onSearch}
            />
            <FormControl
              size="small"
              variant="outlined"
              style={{ width: 180, height: 38 }}
            >
              <InputLabel>Sort By</InputLabel>
              <Select native label="Sort By" value={sort} onChange={onSort}>
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
              </Select>
            </FormControl>
          </div>
          <AddBatchStudent studentIds={studentsIds} />
        </div>

        <div className={classes.studentTable}>
          <EnrollStudentTable filteredResult={filteredResult} />
        </div>
      </div>
    </>
  );
};
