import React from "react";
import { Divider, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { ModalHeader } from "../../../common/ModalHeader/ModalHeader";
import { PrimaryButton } from "../../../common/Button";
import { addNewReminder } from "../../../backend/sendDataToFirestore";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../common/Toastify";
import { REMINDER_COLLECTION_NAME } from "../../../common/const";
import { editReminder } from "../../../backend/EditDataToFirebase";

const useStyles = makeStyles((theme) => ({
  containerWrapper: {
    paddingTop: 30,
  },
  container: {
    paddingBottom: 28,
  },
  dateContainer: {
    paddingBottom: 28,
    display: "flex",
    gap: 34,
  },
  required: {
    color: theme.palette.error.main,
  },
  label: { paddingBottom: 8 },
  textfield: { width: 310 },
  buttonContainer: { right: 60, position: "fixed", bottom: 50 },
}));

export const AddDomainForm = ({ openTab, handleState, editDomain }) => {
  const classes = useStyles();

  const initialForm = {
    domainName: "",
    vendorName: "",
    clientName: "",
    clientContact: "",
    purchaseDate: new Date(),
    expireDate: new Date(),
    price: "",
  };

  const [formData, setFormData] = useState(initialForm);

  React.useEffect(() => {
    if (editDomain)
      setFormData({
        ...editDomain,
        purchaseDate: new Date(editDomain?.purchaseDate?.seconds * 1000),
        expireDate: new Date(editDomain?.expireDate?.seconds * 1000),
      });
  }, [editDomain]);

  const handleChangeForm = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmitForm = async () => {
    const res = editDomain
      ? await editReminder(REMINDER_COLLECTION_NAME.DOMAIN, formData)
      : await addNewReminder(REMINDER_COLLECTION_NAME.DOMAIN, formData);
    if (res.successful) {
      setFormData(initialForm);
      successToastMessage(res.message);
      handleState();
    } else {
      errorToastMessage("There is something wrong");
    }
  };

  return (
    <React.Fragment>
      <ModalHeader
        headerTitle={`${editDomain ? "Edit " : "Add "}${openTab}`}
        handleClose={handleState}
      />
      <Divider />
      <div className={classes.containerWrapper}>
        <div className={classes.container}>
          <Typography className={classes.label}>
            Domain Name <span className={classes.required}>*</span>
          </Typography>
          <TextField
            fullWidth
            placeholder="Domain Name"
            variant="outlined"
            name="domainName"
            value={formData?.domainName}
            onChange={(e) => handleChangeForm(e.target.name, e.target.value)}
          />
        </div>
        <div className={classes.container}>
          <Typography className={classes.label}>
            Vendor Name <span className={classes.required}>*</span>
          </Typography>
          <TextField
            fullWidth
            placeholder="Vendor Name"
            variant="outlined"
            name="vendorName"
            value={formData?.vendorName}
            onChange={(e) => handleChangeForm(e.target.name, e.target.value)}
          />
        </div>
        <div className={classes.container}>
          <Typography className={classes.label}>
            Client Name <span className={classes.required}>*</span>
          </Typography>
          <TextField
            fullWidth
            placeholder="Client Name"
            variant="outlined"
            name="clientName"
            value={formData?.clientName}
            onChange={(e) => handleChangeForm(e.target.name, e.target.value)}
          />
        </div>
        <div className={classes.container}>
          <Typography className={classes.label}>
            Client Contact <span className={classes.required}>*</span>
          </Typography>
          <TextField
            fullWidth
            placeholder="Client Contact"
            variant="outlined"
            name="clientContact"
            value={formData?.clientContact}
            onChange={(e) => handleChangeForm(e.target.name, e.target.value)}
          />
        </div>
        <div className={classes.dateContainer}>
          <div>
            <Typography className={classes.label}>
              Purchase Date <span className={classes.required}>*</span>
            </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classes.textfield}
                variant="inline"
                format="dd-MM-yyyy"
                id="date-picker-inline"
                value={formData?.purchaseDate}
                onChange={(value) => handleChangeForm("purchaseDate", value)}
                inputVariant="outlined"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div>
            <Typography className={classes.label}>
              Expire Date <span className={classes.required}>*</span>
            </Typography>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classes.textfield}
                variant="inline"
                format="dd/MM/yyyy"
                id="date-picker-inline"
                value={formData?.expireDate}
                onChange={(value) => handleChangeForm("expireDate", value)}
                inputVariant="outlined"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>

        <div className={classes.dateContainer}>
          <div>
            <Typography className={classes.label}>
              Price <span className={classes.required}>*</span>
            </Typography>
            <TextField
              fullWidth
              className={classes.textfield}
              placeholder="Price"
              variant="outlined"
              name="price"
              value={formData?.price}
              onChange={(e) => handleChangeForm(e.target.name, e.target.value)}
            />
          </div>
        </div>
      </div>
      <PrimaryButton
        className={classes.buttonContainer}
        variant="contained"
        color="primary"
        onClick={handleSubmitForm}
      >
        {editDomain ? "Edit" : "Add"}
      </PrimaryButton>
    </React.Fragment>
  );
};
