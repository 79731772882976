import React, { useEffect, useRef } from "react";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import parse from "html-react-parser";

const useStyles = makeStyles((theme) => ({
  blogImage: {
    width: "100%",
    height: "350px",
    marginTop: "20px",
  },
  BgImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  blogDesc: {
    width: "100%",
    "& img": {
      width: "100%",
    },
  },
}));

const BlogDescription = (props) => {
  const { blogImage, blogDescription } = props.blogData;
  const blogRef = useRef(null);
  useEffect(() => {
    blogRef.current.innerHTML = blogDescription;
  }, [blogDescription]);
  const classes = useStyles();
  return (
    <>
      <div className="">
        <div className={classes.blogImage}>
          <img alt="blogPoster" src={blogImage} className={classes.BgImg} />
        </div>

        <div className={classes.blogDesc} ref={blogRef}></div>
      </div>
    </>
  );
};

export default BlogDescription;
