import React from "react";
import LanguageIcon from "@material-ui/icons/Language";
import { formatDate } from "../../common/const";
import { makeStyles } from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  User: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  UserName: {
    fontSize: "16px",
    fontWeight: 500,
    margin: 0,
    padding: 0,
  },
  UserBday: {
    fontSize: "14px",
    fontWeight: 400,
  },
  UserDetails: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
});

export const ReminderNotifications = ({ reminderNotification }) => {
  const classes = useStyles();

  return (
    <div className={classes.UserDetails}>
      {reminderNotification.domain.map((item, index) => (
        <div className={classes.User} key={index}>
          <LanguageIcon />
          <div>
            <Typography variant="h6" gutterBottom className={classes.UserName}>
              Domain-{" "}
              <span style={{ color: "red" }}>
                {item.domainName || item.sslName || item.hostName}
              </span>
            </Typography>
            <div
              style={{ display: "flex", gap: 5 }}
              className={classes.UserBday}
            >
              <p>{item.clientName}</p>
              <p>{item.vendorName}</p>
              <p>{formatDate(item?.expireDate)}</p>
            </div>
          </div>
        </div>
      ))}
      {reminderNotification.host.map((item, index) => (
        <div className={classes.User} key={index}>
          <LanguageIcon />
          <div>
            <Typography variant="h6" gutterBottom className={classes.UserName}>
              Host-{" "}
              <span style={{ color: "red" }}>
                {item.domainName || item.sslName || item.hostName}
              </span>
            </Typography>
            <div
              style={{ display: "flex", gap: 5 }}
              className={classes.UserBday}
            >
              <p>{item.clientName}</p>
              <p>{item.vendorName}</p>
              <p>{formatDate(item?.expireDate)}</p>
            </div>
          </div>
        </div>
      ))}
      {reminderNotification.ssl.map((item, index) => (
        <div className={classes.User} key={index}>
          <LanguageIcon />
          <div>
            <Typography variant="h6" gutterBottom className={classes.UserName}>
              SSL-{" "}
              <span style={{ color: "red" }}>
                {item.domainName || item.sslName || item.hostName}
              </span>
            </Typography>
            <div
              style={{ display: "flex", gap: 5 }}
              className={classes.UserBday}
            >
              <p>{item?.clientName}</p>
              <p>{item?.vendorName}</p>
              <p>{formatDate(item?.expireDate)}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
