import React from "react";
import { Button, IconButton } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import { UploadReview } from "./UploadReview";
import { PreviewGoogleReview } from "./PreviewReview";
import { STUDENTS_TABLE_IMAGE_PATH } from "../../../common/config/configImage";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 900,
      borderRadius: 0,
    },
  },
  container: {
    padding: "25px 40px 47px",
    width: 802,
    height: 907,
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    height: 170,
    marginTop: 30,
  },
  button: {
    padding: "13px 0px",
    justifyContent: "start",
    color: "#2F80ED",
    fontSize: 16,
  },
}));

export const AddGoogleReview = ({ studentData, showIcon = true }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [reviewImage, setReview] = React.useState("");

  const handleState = () => {
    setOpen((currentState) => !currentState);
    setReview("");
  };

  const handleReview = (e) => {
    setReview(e.target.files[0]);
  };

  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleState} className={classes.dialog}>
        <div className={classes.container}>
          <UploadReview handleClose={handleState} handleUpload={handleReview} />
          <PreviewGoogleReview
            reviewImage={reviewImage}
            studentData={studentData}
            handleClose={handleState}
          />
        </div>
      </Dialog>
      {showIcon ? (
        <IconButton onClick={handleState}>
          <img
            src={
              studentData?.googleReview
                ? STUDENTS_TABLE_IMAGE_PATH.GOOGLE_REVIEW_COLORED
                : STUDENTS_TABLE_IMAGE_PATH.GOOGLE_REVIEW
            }
            alt={"google-review"}
          />
        </IconButton>
      ) : (
        <Button onClick={handleState} className={classes.button}>
          Show Google Review
        </Button>
      )}
    </React.Fragment>
  );
};
