import React from "react";
import { TabMenu } from "../../../common/TabMenu/TabMenu";
import { TABS_CONFIG } from "../../../common/config/configURL";
import { makeStyles } from "@material-ui/core/styles";
import { ProductStockTable } from "./ProductsTable";
import { AddProductModal } from "../AddProduct/AddProduct";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "31px 61px 27px 57px",
    minHeight: 1024,
    backgroundColor: "#CFCFCF",
  },
  addProduct: {
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: 28,
  },
}));

export const ShopProductTab = () => {
  const classes = useStyles();

  return (
    <div>
      <TabMenu tabs={TABS_CONFIG} />
      <div className={classes.root}>
        <div className={classes.addProduct}>
          <AddProductModal />
        </div>
        <ProductStockTable />
      </div>
    </div>
  );
};
