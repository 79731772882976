import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TablePagination from "@material-ui/core/TablePagination";
import { TextField } from "@material-ui/core";
import { deleteTrainer } from "../../backend/EditDataToFirebase";
import { WarningModal } from "../../common/WarningModal";
import { ActionCol } from "../../common/ActionColumn/ActionCol";
import AddNewTrainer from "../AddTrainer/AddNewTrainer";
import { deleteToast } from "../../common/Toastify";
import { firestore } from "../../backend/firebase";
import OpenTrainerDetails from "./PreviewTrainer/OpenTrainerDetails";
import ExportTrainer from "./ExportTrainer";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  dateContainer: {
    paddingBottom: 30,
    width: 810,
    display: "flex",
    justifyContent: "space-between",
  },
  paidFee: {
    color: theme.palette.success.dark,
  },
  pendingFee: {
    color: theme.palette.error.main,
  },
  container: {
    maxHeight: 600,
  },
}));

function Row(props) {
  const { trainer } = props;
  const [openWarning, setOpenWarning] = React.useState(false);
  const [selectedTrainer, setSelectedTrainer] = React.useState({});
  const [openAddTrainer, setOpenAddTrainer] = React.useState(false);
  const [viewTrainer, setViewTrainer] = React.useState(false);
  const [trainerDetails, setTrainerDetails] = useState({});

  const classes = useRowStyles();

  const onView = (data) => {
    setViewTrainer(true);
    setTrainerDetails(data);
  };

  const onEdit = (data) => {
    setSelectedTrainer(data);
    setOpenAddTrainer(true);
  };

  const onDelete = async (data) => {
    setSelectedTrainer(data);
    setOpenWarning(true);
  };

  const deleteTrainerConfirm = async () => {
    await deleteTrainer(selectedTrainer.id);
    setOpenWarning(false);
    deleteToast("Trainer");
  };

  return (
    <React.Fragment>
      <AddNewTrainer
        state={openAddTrainer}
        setState={setOpenAddTrainer}
        editTrainerData={selectedTrainer}
      />
      <WarningModal
        open={openWarning}
        setOpen={setOpenWarning}
        message={"Trainer"}
        subMessage={
          "All Trainer's data like, fees, courses and etc will be deleted & wont be accessible anymore"
        }
        deleteItem={deleteTrainerConfirm}
      />
      <OpenTrainerDetails
        open={viewTrainer}
        setViewTrainer={setViewTrainer}
        trainerDetails={trainerDetails}
      />
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          {trainer?.uniqueId}
        </TableCell>
        <TableCell>{trainer?.trainerName}</TableCell>
        <TableCell>{trainer?.emailId}</TableCell>
        <TableCell>{trainer?.contactNumber}</TableCell>
        <TableCell>{trainer?.subject.map((sub) => `${sub}, `)}</TableCell>
        <TableCell>
          <div className={classes.paidFee}>{trainer?.experience}</div>
        </TableCell>
        <TableCell>
          <div className={classes.paidFee}>{trainer?.availability}</div>
        </TableCell>
        <TableCell>
          <div className={classes.pendingFee}>{trainer?.preferredMode}</div>
        </TableCell>
        <TableCell>
          <ActionCol
            data={trainer}
            onView={onView}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export function ViewTrainersTable() {
  const classes = useRowStyles();
  const [trainers, setTrainers] = useState([]);
  const [filteredTrainers, setFilteredTrainers] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  //searching sorting
  const [searchValue, setSearchValue] = useState("");
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  useEffect(() => {
    getTrainersList();
  }, []);

  useEffect(() => {
    if (!!startDate && !!endDate) getTrainersList(startDate, endDate);
  }, [startDate, endDate]);

  async function getTrainersList(startDate, endDate) {
    if (startDate && endDate) {
      firestore
        .collection("Trainers")
        .where("joinDate", ">=", startDate)
        .where("joinDate", "<=", endDate)
        .onSnapshot((querySnapshot) => {
          const data = [];
          querySnapshot?.forEach((doc) => {
            data.push({ ...doc.data(), id: doc.id });
          });
          setTrainers(data);
          setFilteredTrainers(data);
        });
      return;
    }
    firestore.collection("Trainers").onSnapshot((querySnapshot) => {
      const data = [];
      querySnapshot?.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      setTrainers(data);
      setFilteredTrainers(data);
    });
  }

  const onSearch = (e) => {
    setSearchValue(e.target.value);
    console.log(e.target.value);
    setFilteredTrainers(searchProductsResult(e.target.value, ""));
  };

  const searchProductsResult = (name, status) => {
    return trainers.filter((pros) => {
      if (name && status) {
        return (
          ((pros.trainerName &&
            pros.trainerName.toLowerCase().includes(name.toLowerCase())) ||
            (pros.address &&
              pros.address.toLowerCase().includes(name.toLowerCase()))) &&
          pros.status &&
          pros.status.toLowerCase() === status.toLowerCase()
        );
      }
      if (name) {
        return (
          (pros.trainerName &&
            pros.trainerName.toLowerCase().includes(name.toLowerCase())) ||
          (pros.address &&
            pros.address.toLowerCase().includes(name.toLowerCase()))
        );
      }
      if (status) {
        return (
          pros.status && pros.status.toLowerCase() === status.toLowerCase()
        );
      }
      return true;
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredTrainers.length - page * rowsPerPage);

  return (
    <div style={{ padding: "32px 38px 64px" }}>
      <div className={classes.dateContainer}>
        <TextField
          style={{ width: 300 }}
          label="Search"
          placeholder="Name, Address, etc..."
          variant="outlined"
          value={searchValue}
          onChange={(e) => {
            onSearch(e);
          }}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            style={{ width: 190 }}
            variant="inline"
            format="dd/MM/yyyy"
            id="date-picker-inline"
            label="Start Date"
            value={startDate}
            onChange={(e) => setStartDate(e)}
            inputVariant="outlined"
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
          <KeyboardDatePicker
            style={{ width: 190 }}
            variant="inline"
            format="dd/MM/yyyy"
            id="date-picker-inline"
            label="End Date"
            value={endDate}
            onChange={(e) => setEndDate(e)}
            inputVariant="outlined"
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
          <ExportTrainer trainers={filteredTrainers} />
        </MuiPickersUtilsProvider>
      </div>
      <TableContainer className={classes.container} component={Paper}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead style={{ position: "relative", zIndex: 1 }}>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Trainer Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Contact No.</TableCell>
              <TableCell>Subjects</TableCell>
              <TableCell>Experience</TableCell>
              <TableCell>Availability</TableCell>
              <TableCell>Mode of teaching</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          {filteredTrainers.length ? (
            <TableBody style={{ position: "relative", zIndex: 0 }}>
              {filteredTrainers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((trainer, index) => {
                  return <Row key={index} trainer={trainer} />;
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          ) : (
            <TableBody>
              <TableCell>
                <p>Please Add a Trainer</p>
              </TableCell>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredTrainers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
