import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { ViewEventCard } from "./EventCard";

export const ViewEventsContainer = ({ events }) => {
  return (
    <React.Fragment>
      <Grid container spacing={5}>
        {events?.length ? (
          events?.map((event, index) => {
            return (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <ViewEventCard event={event} />
              </Grid>
            );
          })
        ) : (
          <Typography variant="h4">Not found</Typography>
        )}
      </Grid>
    </React.Fragment>
  );
};
