import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import { DeleteProduct } from "../../../common/Button";
import { formatRawDate } from "../../../common/const";

const useStyles = makeStyles({
  container: {
    width: "100%",
    maxWidth: 1200,
    backgroundColor: "#fff",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 20,
  },
  trainerName: {
    fontWeight: 400,
    color: "#666666",
  },
  heading: {
    fontWeight: 600,
    color: "#000",
  },

  userInfo: {
    padding: "20px 0",
  },
  userImg: {
    width: 128,
    height: 128,
  },
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: 5,
  },
  userDetails: {
    display: "flex",
    gap: 50,
    marginTop: 30,
  },
  userName: {
    fontWeight: 600,
    color: "#000",
    fontSize: 20,
  },
  birthDate: {
    fontWeight: 400,
    color: "#000",
    fontSize: 14,
  },
  email: {
    color: "#2F80ED",
    textDecoration: "underline",
  },
  experience: {
    fontWeight: 400,
    color: "#000",
    fontSize: 15,
  },
  experienceDetails: {
    marginTop: 20,
    display: "flex",
    gap: 50,
  },
  breakLine: {
    background: "#808080",
  },

  iconBtn: {
    border: "1px solid #B3B3B3",
    borderRadius: 5,
    width: 35,
    height: 35,
    marginLeft: 20,
  },
});

const TrainerDetails = ({ handleDrawerClose, trainerDetails }) => {
  const classes = useStyles();
  const handlerBack = () => {
    handleDrawerClose();
  };
  return (
    <>
      <div className={classes.container}>
        <div className={classes.header}>
          <div>
            <Typography className={classes.heading}>
              Trainer Details of{" "}
              <span className={classes.trainerName}>
                {trainerDetails?.trainerName}
              </span>
            </Typography>
          </div>
          <div>
            {/* <DeleteProduct variant="outlined" style={{ boxShadow: "none" }}>
              Edit Details
            </DeleteProduct> */}

            <IconButton className={classes.iconBtn} onClick={handlerBack}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
        <Divider className={classes.breakLine} />
        <div className={classes.userInfo}>
          <Typography style={{ color: "#000" }} gutterBottom>
            Personal Information
          </Typography>
          <Divider />

          <div className={classes.userDetails}>
            <div className={classes.userImg}>
              <img
                src="https://s3-alpha-sig.figma.com/img/6d7a/2861/9353ad411c0e059a200d73fa5ae9d45f?Expires=1682294400&Signature=EXXOmd6Iqvl26ALumCAV8nhP1Kis8CKMgpgJCYf5~CBbnc3mg67~Elw-imaa9zCjua7IKUW-xmCuBAzR4X8nPrPVdbMmqeY-x3sDA8Cjps7~1-tMbMxa-jkMuJYZ5BN8gPlqkUCDlg10~SgGspFMXpulub8m6NAHFHwFIoSZFAriP~A27MoGFqyE2A6oEi7HiV~2a9Kx7zohoylMyUFBohh8PpOhf4QYPGCW2AZrUswC2jetrjdKpxLMsc9TxZwPaujv0qz4Ug1~tyaqG29NOVEAMhfXdDF7pRoERYcvwgPQw4IEtF4AA7QkzhbESXCLLjLNxeAIh8NZJWF1vP5b6Q__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
                alt=""
                width={128}
                height={128}
                className={classes.img}
              />
            </div>

            <div>
              <Typography className={classes.userName} gutterBottom>
                {trainerDetails?.trainerName}
              </Typography>
              <Typography className={classes.birthDate} gutterBottom>
                Date of Birth :{" "}
                <span className={classes.trainerName}>
                  {formatRawDate(trainerDetails?.dob)}
                </span>
              </Typography>
              <Typography className={classes.birthDate} gutterBottom>
                Phone Number :{" "}
                <span className={classes.trainerName}>
                  {trainerDetails?.contactNumber}
                </span>
              </Typography>

              <Typography className={classes.birthDate} gutterBottom>
                Email Id :{" "}
                <span className={classes.email}>{trainerDetails?.emailId}</span>
              </Typography>
            </div>
          </div>
        </div>

        <div className={classes.userInfo}>
          <Typography style={{ color: "#000" }} gutterBottom>
            Personal Information
          </Typography>
          <Divider />

          <div className={classes.experienceDetails}>
            <div>
              <Typography className={classes.experience} gutterBottom>
                Experience :{" "}
                <span className={classes.trainerName}>
                  {trainerDetails?.experience}
                </span>
              </Typography>
              <Typography className={classes.experience} gutterBottom>
                Subjects :{" "}
                <span className={classes.trainerName}>
                  {trainerDetails?.subject
                    ?.map((subject) => {
                      return subject;
                    })
                    .join(", ")}
                </span>
              </Typography>
            </div>
            <div>
              <Typography className={classes.experience} gutterBottom>
                Availability :{" "}
                <span className={classes.trainerName}> Mon-Fri (9AM-5PM)</span>
              </Typography>
              <Typography className={classes.experience} gutterBottom>
                Mode of Teaching :{" "}
                <span className={classes.trainerName}>
                  {trainerDetails?.preferredMode}
                </span>
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrainerDetails;
