import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BlogHeader from "./BlogHeader";
import BlogDescription from "./BlogDescription";
import { useLocation } from "react-router-dom";
import {getBlog} from "../../../../backend/fetchSingleBlog"

const useStyles = makeStyles((theme) => ({
  continer: {
    width: "100%",
    maxWidth: 1200,
    margin: "0 auto",
    marginTop: 100,
    marginBottom: 20,
  },
}));

const BlogDetails = () => {
  const [blogData, setBlogs] = useState({});
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    getsingleBlog()
  }, [location])


  const getsingleBlog = async () => {
    const blogId = location.pathname.split("/").splice(2).join('');
    const res = await getBlog(blogId)
    setBlogs(res)
  }

  return (
    <>
      <div className={classes.continer}>
        <BlogHeader blogData={blogData} />
        <BlogDescription blogData={blogData} />
      </div>
    </>
  );
};

export default BlogDetails;
