import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import Checkbox from "@material-ui/core/Checkbox";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  status: {
    color: theme.palette.info.main,
  },
  invoice: {
    color: theme.palette.error.main,
  },
  container: {
    maxHeight: 600,
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: "#ffff",
    },
  },

  arrowIcon: {
    width: "30px",
    height: "30px",
  },
}));

function Row({ student, setToAddStudents, toAddStudents }) {
  const classes = useRowStyles();
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setToAddStudents([...toAddStudents, event.target.value]);
    } else {
      setToAddStudents(
        toAddStudents.filter((item) => item !== event.target.value)
      );
    }
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <Checkbox
            color="primary"
            inputProps={{ "aria-label": "primary checkbox" }}
            value={student?.docId}
            onChange={handleChange}
            checked={checked}
          />
        </TableCell>
        <TableCell>{student?.studentName}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export const AddStudentList = ({
  student,
  setToAddStudents,
  toAddStudents,
}) => {
  const classes = useRowStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, student?.length - page * rowsPerPage);

  return (
    <>
      <TableContainer className={classes.container} component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 0 }} />
              <TableCell>Student Name</TableCell>
            </TableRow>
          </TableHead>
          {student?.length ? (
            <TableBody>
              {student
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  return (
                    <Row
                      key={index}
                      student={item}
                      setToAddStudents={setToAddStudents}
                      toAddStudents={toAddStudents}
                    />
                  );
                })}
              {emptyRows > 0 && (
                <TableRow>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell>
                  <p>No Course</p>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={student?.length ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
