import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tab, Tabs, Typography } from "@material-ui/core";
import { PrimaryButton } from "../../../common/Button";
import { BATCH_TABS_CONFIG } from "../../../common/config/configURL";
import { useParams } from "react-router-dom";
import { getBatchDetails } from "../../../backend/getDataFromFirestore";
import { ViewBatchStudents } from "./ViewBatchStudents/ViewBatchStudents";
import { ViewBatchCourses } from "./ViewBatchCourses/ViewBatchCourses";
import { deleteBatch } from "../../../backend/EditDataToFirebase";
import { WarningModal } from "../../../common/WarningModal";
import { deleteToast } from "../../../common/Toastify";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    backgroundColor: "#17191A",
    padding: "30px 90px",
  },
  subHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerTitle: {
    fontWeight: "600",
    color: theme.palette.common.white,
  },
  headerSubTitle: {
    fontWeight: "300",
    color: theme.palette.common.white,
    fontSize: 20,
  },
  tabs: {
    color: "#fff",
    margin: 0,
    padding: 0,
    minHeight: 48,

    "& .MuiTab-wrapper": {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "flex-start",
    },
  },

  tabHeader: {
    background: "#17191A",
    color: "#fff",
  },
  tabBody: {
    padding: "0px 50px",
    backgroundColor: "#17191A",
  },
}));

export const ViewBatchDetails = () => {
  const classes = useStyles();

  const [openDelete, setOpenDelete] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [batch, setBatch] = React.useState({});

  const { id } = useParams();

  useEffect(() => {
    getBatchDetails(setBatch, id);
  }, [id]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleState = async () => {
    setOpenDelete((currentState) => !currentState);
  };

  const handleDeleteConfirm = async () => {
    const res = await deleteBatch(batch?.docId, batch?.batchStudents);
    if (res.successful) {
      deleteToast("Batch");
      window.history.back();
    }
  };

  return (
    <React.Fragment>
      <WarningModal
        open={openDelete}
        setOpen={setOpenDelete}
        message={"Batch"}
        subMessage={"Students won't be able to access these courses"}
        deleteItem={handleDeleteConfirm}
      />
      <div className={classes.headerContainer}>
        <div className={classes.subHeaderContainer}>
          <Typography variant="h5" className={classes.headerTitle}>
            {batch?.batchName}
          </Typography>
          <PrimaryButton variant="contained" onClick={handleState}>
            Delete Batch
          </PrimaryButton>
        </div>
        <Typography variant="h5" className={classes.headerSubTitle}>
          {batch?.description}
        </Typography>
      </div>
      <div className={classes.tabBody}>
        <Tabs
          value={value}
          textColor="primary"
          onChange={handleChange}
          indicatorColor="primary"
          className={classes.tabHeader}
        >
          {BATCH_TABS_CONFIG.map((item, index) => {
            return (
              <Tab
                onClick={(event) => {
                  event.preventDefault();
                }}
                textColor="primary"
                label={<Typography variant="body2">{item.title}</Typography>}
                icon={value === index ? item.active : item.icon}
                className={classes.tabs}
                key={index}
              />
            );
          })}
        </Tabs>
      </div>
      <div style={{ backgroundColor: "#F5F6F8" }}>
        <RenderComponent renderComponent={value} batch={batch} />
      </div>
    </React.Fragment>
  );
};

const RenderComponent = ({ renderComponent, batch }) => {
  switch (renderComponent) {
    case 0:
      return <ViewBatchStudents studentsIds={batch?.batchStudents} />;
    case 1:
      return <ViewBatchCourses courseIds={batch?.batchCourses} />;
    default:
      return <ViewBatchStudents studentsIds={batch?.batchStudents} />;
  }
};
