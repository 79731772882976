import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ADMIN_EMAIL, ADMIN_PASSWORD } from "../../common/const";

const StyledLoginForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const StyledSubmitButton = styled(Button)`
  margin-top: 20px;
`;

const LoginPage = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = formData;
    if (email !== ADMIN_EMAIL) {
      alert("Invalid Email");
      return;
    } else if (password !== ADMIN_PASSWORD) {
      alert("Invalid Password");
      return;
    } else if (email === ADMIN_EMAIL && password === ADMIN_PASSWORD) {
      localStorage.setItem("rc-email", email);
      localStorage.setItem("isLogin", true);
      navigate("/");
    }
  };

  return (
    <div>
      <StyledLoginForm onSubmit={handleFormSubmit}>
        <TextField
          label="Email"
          type="email"
          variant="outlined"
          margin="normal"
          name="email"
          required
          value={formData.email}
          onChange={handleInputChange}
        />
        <TextField
          label="Password"
          type={showPassword ? "text" : "password"}
          variant="outlined"
          margin="normal"
          required
          name="password"
          value={formData.password}
          onChange={handleInputChange}
          InputProps={{
            endAdornment: (
              <StyledPasswordVisibilityButton
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </StyledPasswordVisibilityButton>
            ),
          }}
        />
        <StyledSubmitButton variant="contained" color="primary" type="submit">
          Login
        </StyledSubmitButton>
      </StyledLoginForm>
    </div>
  );
};

const StyledPasswordVisibilityButton = styled.div`
  cursor: pointer;
`;

export default LoginPage;
