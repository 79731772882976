import React from "react";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import Fab from "@material-ui/core/Fab";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import LinkIcon from '@material-ui/icons/Link';
import {
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton, 
  TwitterIcon,
} from "react-share";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  continer: {
    width: "100%",
    maxWidth: 1200,
    margin: "0 auto",
    marginTop: 100,
    marginBottom: 20,
  },
  prevLink: {
    color: "#000",
    textDecoration: "none",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginTop: 20,
  },
  chips: {
    display: "flex",
    gap: 10,
    marginTop: 10,
  },
  chip: {
    color: "#5C36BB",
    padding: "0px 10px",
    fontWeight: 600,
    fontSize: 15,
    border: "1px solid #5C36BB",
  },
  avatar: {
    display: "flex",
    alignItems: "center",
    gap: 15,
  },
  userName: {
    fontWeight: 500,
    fontSize: "16px",
  },
  iconTab: {
    width: "40px",
    height: "40px",
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  Icons: {
    color: "#fff",
    backgroundColor: "#5C36BB",
  },
  sharePost: {
    display: "flex",
    gap: 15,
    marginTop: 15,
  },
  blogheader: {
    fontWeight: 600,
    fontSize: "35px",
  },
  linkIcon : {
    transform : "rotate(-45deg)"
  },
  ShareSection : {
    display : "flex",
    flexDirection : "column",
    alignItems : "flex-end",
  },
}));

const BlogHeader = (props) => {
  const { blogTitle, blogKeywords, blogAuthor } = props.blogData;
  const location = useLocation();
  const classes = useStyles();
  const shareLink = `https://roguecode-admin.netlify.app${location.pathname}`;


  const handlerCopyLink = () => {
    navigator.clipboard.writeText(shareLink);
  }

  return (
    <>
      <div>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to="/view-blog" className={classes.prevLink}>
            Blogs
          </Link>
          <Typography color="textPrimary" style={{ color: "#5C36BB" }}>
            View Blogs
          </Typography>
        </Breadcrumbs>
        <div className={classes.header}>
          <div>
            <Typography variant="h4" className={classes.blogheader}>
              {blogTitle}
            </Typography>
            <div className={classes.chips}>
              {blogKeywords?.map((item, index) => {
                return (
                  <Chip
                    label={item}
                    variant="outlined"
                    className={classes.chip}
                  />
                );
              })}
            </div>
          </div>
          <div className={classes.ShareSection}>
            <div className={classes.avatar}>
              <Avatar alt="Kishan Chaudhary" src="/asstes/students.jpeg" />
              <Typography variant="h5" className={classes.userName}>
                {blogAuthor && blogAuthor}
              </Typography>
            </div>
            <div className={classes.sharePost}>
              <Fab
                aria-label="linkedIn"
                color="neutral"
                className={classes.iconTab}
                title="Share on LinkedIn"
              >
                <LinkedinShareButton
                  url={shareLink}
                  quote={"New Blog"}
                  hashtag={"#react"}
                >
                  <LinkedinIcon size={45} round/>
                </LinkedinShareButton>
              </Fab>

              <Fab
                aria-label="linkedIn"
                color="neutral"
                className={classes.iconTab}
                title="Share on Whatsapp"
              >
                <WhatsappShareButton
                  url={shareLink}
                  quote={"New Blog"}
                  hashtag={"#react"}
                >
                  <WhatsappIcon size={45} round/>
                </WhatsappShareButton>
              </Fab>

              <Fab
                aria-label="linkedIn"
                color="neutral"
                className={classes.iconTab}
                title="Share on Twitter"
              >
                <TwitterShareButton
                  url={shareLink}
                  quote={"New Blog"}
                  hashtag={"#react"}
                
                >
                  <TwitterIcon size={45} round/>
                </TwitterShareButton>
              </Fab>

              <Fab
                aria-label="linkedIn"
                color="neutral"
                className={classes.iconTab}
                onClick={handlerCopyLink}
                title="Copy Link"
              >
                <LinkIcon className={classes.linkIcon}/>
              </Fab>
              
            </div>
          </div>
        </div>
        <Divider style={{ marginTop: 30 }} />
      </div>
    </>
  );
};

export default BlogHeader;
