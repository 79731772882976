import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const deleteToast = (notifyMsg) => {
  toast.success(`Successfully deleted ${notifyMsg}`, {
    toastId: "success2",
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const successToast = (notifyMsg) => {
  toast.success(`${notifyMsg} Created Successfully`, {
    toastId: "success2",
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const errorToast = () => {
  toast.warn(`There is somthing wrong`, {
    toastId: "success2",
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const successToastMessage = (notifyMsg) => {
  toast.success(notifyMsg, {
    toastId: "success2",
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};

export const errorToastMessage = (notifyMsg) => {
  toast.warn(notifyMsg, {
    toastId: "success2",
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};
