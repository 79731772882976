import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BatchHeader } from "./BatchHeader";
import { FormControl, InputLabel, Select, TextField } from "@material-ui/core";
import { BatchList } from "./BatchList";
import { getBatch } from "../../../backend/getDataFromFirestore";

const useStyles = makeStyles((theme) => ({
  dataContainer: {
    background: theme.palette.grey[100],
  },
  searchContainer: {
    padding: "30px 90px",
    width: 680,
    display: "flex",
    justifyContent: "space-between",
  },
  batchContainer: {
    padding: "0px 90px",
    height: "calc(100vh - 385px)",
    overflowY: "scroll",
  },
}));

export const Batch = () => {
  const classes = useStyles();

  const [batches, setBatches] = useState([]);
  const [filteredResult, setFilteredResult] = useState([]); //initialBatch

  const [searchValue, setSearchValue] = useState("");
  const [sort, setSort] = useState("asc");

  useEffect(() => {
    getBatch(setBatches, setFilteredResult);
  }, []);

  function compareAB(a, b) {
    var nameA = a ? a.toString().toUpperCase() : ""; // ignore upper and lowercase
    var nameB = b ? b.toString().toUpperCase() : ""; // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  const searchLeadResult = (name, status) => {
    if (!batches?.length) {
      return;
    }

    if (status === "asc name" && !name) {
      return batches?.sort((a, b) => compareAB(a["batchName"], b["batchName"]));
    } else if (status === "desc name" && !name) {
      return batches?.sort(
        (a, b) => -1 * compareAB(a["batchName"], b["batchName"])
      );
    } else if (status === "asc date" && !name) {
      return batches?.sort((a, b) => compareAB(a["date"], b["date"]));
    } else if (status === "desc date" && !name) {
      return batches?.sort((a, b) => -1 * compareAB(a["date"], b["date"]));
    }

    return batches?.filter((pros) => {
      if (name) {
        return (
          pros?.batchName &&
          pros?.batchName.toLowerCase().includes(name.toLowerCase())
        );
      }
      return true;
    });
  };

  const onSearch = (e) => {
    setSearchValue(e.target.value);
    setFilteredResult(searchLeadResult(e.target.value, sort));
  };

  const onSort = (e) => {
    setSort(e.target.value);
    setFilteredResult(searchLeadResult(searchValue, e.target.value));
  };

  return (
    <React.Fragment>
      <BatchHeader />
      <div className={classes.dataContainer}>
        <div className={classes.searchContainer}>
          <TextField
            size="small"
            style={{ width: 300, height: 38 }}
            label="Search"
            placeholder="Search by Batch name..."
            variant="outlined"
            value={searchValue}
            onChange={onSearch}
          />
          <FormControl
            size="small"
            variant="outlined"
            style={{ width: 180, height: 38 }}
          >
            <InputLabel>Sort By</InputLabel>
            <Select native label="Sort By" value={sort} onChange={onSort}>
              <option value=""></option>
              <option value="asc name">Asc By Name</option>
              <option value="desc name">Desc By Name</option>
              <option value="asc date">Asc By Date</option>
              <option value="desc date">Desc By Date</option>
            </Select>
          </FormControl>
        </div>
        <div className={classes.batchContainer}>
          <BatchList batches={filteredResult} />
        </div>
      </div>
    </React.Fragment>
  );
};
