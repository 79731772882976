import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import DeleteTwoToneIcon from "@material-ui/icons/DeleteTwoTone";
import { deleteBatchCourse } from "../../../../backend/EditDataToFirebase";
import { WarningModal } from "../../../../common/WarningModal";
import { deleteToast } from "../../../../common/Toastify";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 329,
    height: 274,
  },
  cards: {
    width: "100%",
    height: "100%",
    boxShadow: "3.10377px 3.10377px 31.0377px rgba(0, 0, 0, 0.06)",
    borderRadius: 10,
    position: "relative",
  },
  logo: {
    height: 190,
    width: "100%",
    objectFit: "cover",
  },
  courseDetails: {
    padding: "10px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  courseImage: {
    position: "absolute",
    borderRadius: "50%",
    bottom: 20,
    left: 20,
  },
}));

export const CourseCard = ({ course }) => {
  const classes = useStyles();
  const [openWarning, setOpenWarning] = useState(false);
  const { id } = useParams();

  const handleConfirmDelete = async (e) => {
    const res = await deleteBatchCourse(id, course?.courseId);
    setOpenWarning(false);
    if (res.successful) {
      deleteToast("Course");
    }
  };

  return (
    <>
      <div className={classes.cards}>
        <WarningModal
          open={openWarning}
          setOpen={setOpenWarning}
          message={"Course"}
          subMessage={"All Data will be deleted & wont be accessible anymore"}
          deleteItem={handleConfirmDelete}
        />
        <Card className={classes.root}>
          <div style={{ position: "relative" }}>
            <div className={classes.courseImage}>
              <img
                src={course?.CourseLogo}
                alt=""
                width={75}
                height={75}
                style={{ borderRadius: "50%", objectFit: "cover" }}
              />
            </div>

            <div className={classes.logo}>
              <img className={classes.logo} src={course?.courseImage} alt="" />
            </div>
          </div>

          <Divider />
          <div className={classes.courseDetails}>
            <div>
              <Typography style={{ fontWeight: 700 }}>
                {course?.courseTitle}
              </Typography>
              <Typography>{course?.courseDescription}</Typography>
            </div>
            <div>
              <IconButton
                aria-label="delete"
                style={{ color: "#00000052", padding: 0 }}
                id={course?.courseId}
                onClick={() => setOpenWarning(!openWarning)}
              >
                <DeleteTwoToneIcon />
              </IconButton>
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};
