import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import LanguageIcon from "@material-ui/icons/Language";
import { Actions } from "../Actions/Actions";
import { deleteReminder } from "../../../backend/EditDataToFirebase";
import { deleteToast, errorToast } from "../../../common/Toastify";
import { WarningModal } from "../../../common/WarningModal";
import { REMINDER_COLLECTION_NAME } from "../../../common/const";
import { ReminderFormModal } from "../AddReminderModal";
import { isWithinTwoMonths } from "../helpers";

const useRowStyles = makeStyles((theme) => ({
  container: {
    maxHeight: 600,
    zIndex: 0,
    position: "relative",
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: "#ffff",
      fontWeight: "500",
      height: 72,
    },
  },
  availStatus: {
    color: theme.palette.success.main,
  },
  notAvailStatus: {
    color: theme.palette.error.main,
  },
}));

function Row({ domain, setSelectedDomain, setOpenWarning, handleEdit }) {
  const purchaseDate = new Date(domain?.purchaseDate?.seconds * 1000);
  const expireDate = new Date(domain?.expireDate?.seconds * 1000);

  const formatDate = (date) => {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  const onDelete = (data) => {
    setSelectedDomain(data);
    setOpenWarning(true);
  };

  const onEdit = (data) => {
    setSelectedDomain(data);
    handleEdit();
  };

  return (
    <React.Fragment>
      <TableRow
        style={{
          backgroundColor: isWithinTwoMonths(domain?.expireDate)
            ? "#FF474C"
            : "#0000",
        }}
      >
        <TableCell component="th" scope="row">
          <LanguageIcon />
        </TableCell>
        <TableCell>{domain?.domainName}</TableCell>
        <TableCell>{domain?.vendorName}</TableCell>
        <TableCell>{domain?.clientName}</TableCell>
        <TableCell>{domain?.clientContact}</TableCell>
        <TableCell>{formatDate(purchaseDate)}</TableCell>
        <TableCell>{formatDate(expireDate)}</TableCell>
        <TableCell>{domain?.price}</TableCell>
        <TableCell>
          <Actions data={domain} onEdit={onEdit} onDelete={onDelete} />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export function DomainTable({ domainList }) {
  const classes = useRowStyles();
  const [page, setPage] = React.useState(0);
  const [isOpenEdit, setOpenEdit] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [openWarning, setOpenWarning] = React.useState(false);
  const [selectedDomain, setSelectedDomain] = React.useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, domainList?.length - page * rowsPerPage);

  const handleEdit = () => {
    setOpenEdit((currentState) => !currentState);
  };

  async function handleDelete() {
    const res = await deleteReminder(
      REMINDER_COLLECTION_NAME.DOMAIN,
      selectedDomain.docId
    );
    if (res.successful) {
      setOpenWarning(false);
      deleteToast("Domain");
    } else {
      errorToast();
    }
  }

  return (
    <>
      <ReminderFormModal
        isOpen={isOpenEdit}
        openTab={"Domain"}
        handleState={handleEdit}
        selectedReminder={selectedDomain}
      />
      <WarningModal
        open={openWarning}
        setOpen={setOpenWarning}
        message={"Domain"}
        subMessage={"All Data will be deleted & wont be accessible anymore"}
        deleteItem={handleDelete}
      />
      <TableContainer className={classes.container} component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Domain Name</TableCell>
              <TableCell>Vendor Name</TableCell>
              <TableCell>Client Name</TableCell>
              <TableCell>Client contact</TableCell>
              <TableCell>Date of Purchase</TableCell>
              <TableCell>Date of Expire</TableCell>
              <TableCell>Price</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          {domainList?.length ? (
            <TableBody>
              {domainList
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((domain, index) => {
                  return (
                    <Row
                      key={index}
                      domain={domain}
                      setSelectedDomain={setSelectedDomain}
                      setOpenWarning={setOpenWarning}
                      handleEdit={handleEdit}
                    />
                  );
                })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          ) : (
            <TableBody>
              <TableCell>
                <p>Please Add a Domain</p>
              </TableCell>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={domainList?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
