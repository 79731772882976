import React from "react";
import { MarketHeader } from "./MarketHeader";
import { ViewBlogsComponent } from "../../pages/Blogs/ViewBlog/ViewBlogs";

export const MarketingBlogs = () => {
  return (
    <>
      <div>
        <MarketHeader />
        <div>
          <ViewBlogsComponent />
        </div>
      </div>
    </>
  );
};
