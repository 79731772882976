import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import { AddBlog } from "./AddBlog";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "100%",
      borderRadius: 15,
      boxShadow: "0px 4px 12px 0px #00000040",
    },
  },

  dialogmodal: {
    "& .MuiDialog-paperWidthSm": {
      width: "100%",
      maxWidth: "100vw",
      height: "100%",
      maxHeight: "100vh",
      borderRadius: 15,
      boxShadow: "0px 4px 12px 0px #00000040",
    },

    "& .MuiDialog-paper": {
      margin: 0,
    },
  },
  container: {
    width: "100%",
    height: "100%",
  },
}));

export const OpenBlogModal = ({ open = false, setOpen, blogDetails }) => {
  const classes = useStyles();
  const [blogmodal, setBlogModal] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setBlogModal(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        className={blogmodal ? classes.dialogmodal : classes.dialog}
        style={{ zIndex: 1000 }}
      >
        <div className={classes.container}>
          <AddBlog
            handleClose={handleClose}
            blogDetails={blogDetails}
            setBlogModal={setBlogModal}
            blogmodal={blogmodal}
          />
        </div>
      </Dialog>
    </>
  );
};
