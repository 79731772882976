import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AddNewStudent from "./AddStudents/AddNewStudent";
import AddNewTrainer from "./AddTrainer/AddNewTrainer";
import { ViewStudentsTable } from "./ViewStudents/ViewStudents";
import { ViewTrainersTable } from "./ViewTrainers/ViewTrainers";
import { useNavigate } from "react-router-dom";
import ViewRevenue from "./ViewAnalytics/ViewRevenue";

const useStyles = makeStyles((theme) => ({
  container: {
    background: "#17191A",
    width: "100%",
  },
  main: {
    width: "100%",
    position: "relative",
    padding: "0 38px",
  },
  Header: {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "#17191A",
    paddingTop: "30px",
    paddingBottom: "30px",
    borderBottom: "1px solid #333333",
  },
  MainHeading: {
    color: "#F6F8FA",
    fontWeight: "600",
    fontSize: "35px",
  },
  MainPara: {
    fontWeight: "300",
    fontSize: "20px",
    color: "#FFF4D5",
  },
  button: {
    margin: theme.spacing(1),
    fontWeight: 600,
    color: "#1A1A1A",
    background: "#FFFFFF",
    padding: "8px 35px",
    textTransform: "capitalize",
  },
  boxHeading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  root: {
    background: "#242529",
    width: "267px",
    height: "159px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  rate: {
    backgroundColor: "transparent",
    border: "1px solid #4D4D4D",
    borderRadius: "50px",
    padding: "8px",
    color: "#CCCCCC",
  },
  Form: {
    marginTop: "0px",
    display: "flex",
    gap: "20px",
    height: "50px",
  },
  Submit: {
    background: "#000",
    color: "#fff",
  },
  SubmitForm: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
    alignItems: "flex-start",
    height: "50px",
  },
  Icons: {
    height: "20px",
    marginRight: "10px",
    width: "20px",
  },
}));

const Home = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [pages, setPages] = useState("students");
  const [openAddStudent, setOpenAddStudent] = React.useState(false);
  const [openAddTrainer, setOpenAddTrainer] = React.useState(false);

  const [state, setState] = React.useState({
    age: "",
    name: "",
  });

  useEffect(() => {
    if (!window.location.href.indexOf("dashboard") > -1) {
      navigate(`/dashboard`);
    }
  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  return (
    <>
      <AddNewStudent state={openAddStudent} setState={setOpenAddStudent} />
      <AddNewTrainer state={openAddTrainer} setState={setOpenAddTrainer} />
      <div className={classes.container}>
        <div className={classes.main}>
          <div className={classes.Header}>
            <div>
              <p className={classes.MainPara}>Welcome, Admin! 👋</p>
            </div>
            <div>
              <Button
                variant="contained"
                startIcon={<PersonAddIcon />}
                className={classes.button}
                onClick={() => setOpenAddStudent(true)}
              >
                Create Student
              </Button>
              <Button
                style={{ background: "#FFD25F" }}
                variant="contained"
                className={classes.button}
                onClick={() => setOpenAddTrainer(true)}
              >
                <img
                  src="/assets/trainer.svg"
                  alt=""
                  className={classes.Icons}
                />
                Create Trainer
              </Button>
            </div>
          </div>
          <Navbar>
            <NaBtn
              onClick={() => setPages("students")}
              pages={pages}
              style={{
                borderBottom:
                  pages === "students"
                    ? "2px solid  #FFD25F"
                    : "2px solid  transparent",
                color: pages === "students" ? "#FFD25F" : null,
              }}
            >
              <PersonAddIcon />
              Student
            </NaBtn>

            <NaBtn
              onClick={() => setPages("trainers")}
              pages={pages}
              style={{
                borderBottom:
                  pages === "trainers"
                    ? "2px solid  #FFD25F"
                    : "2px solid  transparent",
                color: pages === "trainers" ? "#FFD25F" : null,
              }}
            >
              {pages === "trainers" ? (
                <img
                  src="/assets/trainer-gold.svg"
                  alt=""
                  className={classes.Icons}
                />
              ) : (
                <img
                  src="/assets/trainer-white.png"
                  alt=""
                  className={classes.Icons}
                />
              )}
              Trainers
            </NaBtn>
            <NaBtn
              onClick={() => setPages("analytics")}
              style={{
                borderBottom:
                  pages === "analytics"
                    ? "2px solid  #FFD25F"
                    : "2px solid  transparent",
                color: pages === "analytics" ? "#FFD25F" : null,
              }}
            >
              <AssessmentIcon />
              Analytics
            </NaBtn>
          </Navbar>
        </div>
      </div>
      <div>
        {pages === "students" ? (
          <ViewStudentsTable />
        ) : pages === "trainers" ? (
          <ViewTrainersTable />
        ) : pages === "analytics" ? (
          <ViewRevenue />
        ) : null}
      </div>
    </>
  );
};

const Navbar = styled.div`
  width: 100%;
  max-width: 1128px;
  display: flex;
  align-items: center;
  gap: 20px;
`;

const NaBtn = styled.button`
  background-color: transparent;
  color: #cccccc;
  display: flex;
  align-items: center;
  border-bottom: 2px solid transparent;
  border: none;
  outline: none;
  gap: 10px;
  color: #cccccc;
  transition: all 0.3s ease-in-out;
  padding: 10px;
  border-bottom: 2px solid;
  border-color: red;
`;
export default Home;
