import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as CORE_OVERVIEW } from "../../globals/assets/shop-tab/shop_tab_overview.svg";
import { ReactComponent as CORE_ORDERS } from "../../globals/assets/shop-tab/shop_tab_orders.svg";
import { ReactComponent as CORE_PRODUCT } from "../../globals/assets/shop-tab/shop_tab_product.svg";
import OVERVIEW_GROWTH from "../../globals/assets/overview/growth.svg";
import OVERVIEW_BASKET from "../../globals/assets/overview/basket.svg";
import OVERVIEW_GROUP from "../../globals/assets/overview/group.svg";
import OVERVIEW_CAMERA from "../../globals/assets/overview/camera.svg";
import OVERVIEW_BUGS from "../../globals/assets/overview/bugs.svg";
import { ReactComponent as ACTIVE_OVERVIEW } from "../../globals/assets/shop-tab/shop_active_overview.svg";
import { ReactComponent as ACTIVE_ORDERS } from "../../globals/assets/shop-tab/shop_active_orders.svg";
import { ReactComponent as ACTIVE_PRODUCT } from "../../globals/assets/shop-tab/shop_active_product.svg";
import { ReactComponent as CORE_DOMAIN } from "../../globals/assets/reminder-tab/domain.svg";
import { ReactComponent as CORE_ACTIVE_DOMAIN } from "../../globals/assets/reminder-tab/domain-active.svg";
import { ReactComponent as CORE_SSL } from "../../globals/assets/reminder-tab/ssl.svg";
import { ReactComponent as CORE_ACTIVE_SSL } from "../../globals/assets/reminder-tab/ssl-active.svg";
import { ReactComponent as CORE_HOST } from "../../globals/assets/reminder-tab/host.svg";
import { ReactComponent as CORE_ACTIVE_HOST } from "../../globals/assets/reminder-tab/host-active.svg";

import { ReactComponent as CORE_ASSIGNMENT } from "../../globals/assets/course/assignment-logo.svg";
import { ReactComponent as CORE_PPT } from "../../globals/assets/course/ppt-logo.svg";
import { ReactComponent as CORE_QUIZ } from "../../globals/assets/course/quiz-logo.svg";
import { ReactComponent as CORE_VIDEO } from "../../globals/assets/course/video-logo.svg";

import { ReactComponent as ACTIVE_ASSIGNMENT } from "../../globals/assets/course/active-assignment.svg";
import { ReactComponent as ACTIVE_PPT } from "../../globals/assets/course/active-ppt.svg";
import { ReactComponent as ACTIVE_VIDEO } from "../../globals/assets/course/active-video.svg";
import { ReactComponent as ACTIVE_QUIZ } from "../../globals/assets/course/active-quiz.svg";

import { ReactComponent as ACTIVE_BLOG } from "../../globals/assets/marketing/active-blogs.svg";
import { ReactComponent as ACTIVE_EVENT } from "../../globals/assets/marketing/active-event.svg";
import { ReactComponent as VIEW_BLOG } from "../../globals/assets/marketing/view-blog.svg";
import { ReactComponent as VIEW_EVENT } from "../../globals/assets/marketing/view-event.svg";

export const CORE_LOGIN = "/login";
export const CORE_DASHBOARD = "/dashboard";
export const CORE_BLOGS = "/marketing/view-blogs";
export const CORE_BLOGS_DETAILS = "/view-blog/:id";
export const CORE_EVENTS = "/marketing/view-events";
export const CORE_SHOP_OVERVIEW = "/shop/overview";
export const CORE_SHOP_ORDERS = "/shop/orders";
export const CORE_SHOP_PRODUCT = "/shop/product";
export const CORE_REMINDER_DOMAIN = "/reminder/domain";
export const CORE_REMINDER_SSL = "/reminder/ssl";
export const CORE_REMINDER_HOST = "/reminder/host";
export const CORE_BATCH = "/batch";
export const CORE_BATCH_DETAILS = "/batch/:id";
export const CORE_SETTINGS = "/";
export const CORE_NOT_FOUND = "*";
export const CORE_PRODUCT_VIEW = "/shop/product-view/:id";
export const CORE_COURSE = "/course/view-course";
export const CORE_COURSE_VIEW = "/course/view-course/:id";
//for student
export const CORE_ADD_STUDENT = "/add-student";

export const OVERVIEW_CARDS = [
  {
    id: 1,
    title: "Total sales",
    logo: OVERVIEW_GROWTH,
    totalCount: 150,
  },
  {
    id: 2,
    title: "Users",
    logo: OVERVIEW_GROUP,
    totalCount: 150,
  },
  {
    id: 3,
    title: "Visitors",
    logo: OVERVIEW_CAMERA,
    totalCount: 150,
  },
  {
    id: 4,
    title: "Order remain",
    logo: OVERVIEW_BASKET,
    totalCount: 150,
  },
  {
    id: 5,
    title: "Bug Reported",
    logo: OVERVIEW_BUGS,
    totalCount: 150,
  },
];

export const TABS_CONFIG = [
  {
    title: "Overview",
    icon: <SvgIcon component={CORE_OVERVIEW} />, //<SvgIcon component={CORE_OVERVIEW} />, <AssignmentIcon />
    url: "/shop/overview",
    active: <SvgIcon component={ACTIVE_OVERVIEW} />,
  },
  {
    title: "Orders",
    icon: <SvgIcon component={CORE_ORDERS} />, //<SvgIcon component={CORE_ORDERS} />,
    url: "/shop/orders",
    active: <SvgIcon component={ACTIVE_ORDERS} />,
  },
  {
    title: "Product",
    icon: <SvgIcon component={CORE_PRODUCT} />, //<SvgIcon component={CORE_PRODUCT} />,
    url: "/shop/product",
    active: <SvgIcon component={ACTIVE_PRODUCT} />,
  },
];

export const REMINDER_TABS_CONFIG = [
  {
    title: "Domain",
    icon: <SvgIcon component={CORE_DOMAIN} />,
    url: CORE_REMINDER_DOMAIN,
    active: <SvgIcon component={CORE_ACTIVE_DOMAIN} />,
  },
  {
    title: "SSL",
    icon: <SvgIcon component={CORE_SSL} />,
    url: CORE_REMINDER_SSL,
    active: <SvgIcon component={CORE_ACTIVE_SSL} />,
  },
  {
    title: "Host",
    icon: <SvgIcon component={CORE_HOST} />,
    url: CORE_REMINDER_HOST,
    active: <SvgIcon component={CORE_ACTIVE_HOST} />,
  },
];

export const COURSE_TAB = [
  {
    id: 1,
    title: "SYLLABUS",
    icon: <SvgIcon component={CORE_QUIZ} />,
    active: <SvgIcon component={ACTIVE_QUIZ} />,
  },
  {
    id: 2,
    title: "QUIZES",
    icon: <SvgIcon component={CORE_QUIZ} />,
    active: <SvgIcon component={ACTIVE_QUIZ} />,
  },
  {
    id: 3,
    title: "Assignment",
    icon: <SvgIcon component={CORE_ASSIGNMENT} />,
    active: <SvgIcon component={ACTIVE_ASSIGNMENT} />,
  },
  {
    id: 4,
    title: "PPT",
    icon: <SvgIcon component={CORE_PPT} />,
    active: <SvgIcon component={ACTIVE_PPT} />,
  },
  {
    id: 5,
    title: "Video",
    icon: <SvgIcon component={CORE_VIDEO} />,
    active: <SvgIcon component={ACTIVE_VIDEO} />,
  },
];

export const BATCH_TABS_CONFIG = [
  {
    id: 1,
    title: "Students",
    icon: <SvgIcon component={CORE_QUIZ} />,
    active: <SvgIcon component={ACTIVE_QUIZ} />,
  },
  {
    id: 2,
    title: "Courses",
    icon: <SvgIcon component={CORE_ASSIGNMENT} />,
    active: <SvgIcon component={ACTIVE_ASSIGNMENT} />,
  },
];

export const TABS_MARKET = [
  {
    id: 1,
    title: "Blogs",
    url: CORE_BLOGS,
    icon: <SvgIcon component={VIEW_BLOG} />,
    active: <SvgIcon component={ACTIVE_BLOG} />,
  },
  {
    id: 2,
    title: "Events",
    url: CORE_EVENTS,
    icon: <SvgIcon component={VIEW_EVENT} />,
    active: <SvgIcon component={ACTIVE_EVENT} />,
  },
];
