import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CourseList } from "./EnrollCourseList";
import { FormControl, InputLabel, Select, TextField } from "@material-ui/core";
import { AddBatchCourse } from "../AddBatchCourse/AddBatchCourse";

const useStyles = makeStyles((theme) => ({
  dataContainer: {
    padding: "31px 90px",
    background: theme.palette.grey[100],
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  searchContainer: {
    paddingBottom: 30,
    width: 500,
    display: "flex",
    justifyContent: "space-between",
  },
}));

export const BatchCourseInfo = ({ courses, courseIds }) => {
  const classes = useStyles();

  const [filteredResult, setFilteredResult] = useState([]); //initialBatch

  useEffect(() => {
    setFilteredResult(courses);
  }, [courses]);

  const [searchValue, setSearchValue] = useState("");
  const [sort, setSort] = useState("asc");

  function compareAB(a, b) {
    var nameA = a ? a.toString().toUpperCase() : ""; // ignore upper and lowercase
    var nameB = b ? b.toString().toUpperCase() : ""; // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  const searchLeadResult = (name, status) => {
    if (!courses?.length) {
      return;
    }

    if (status === "asc" && !name) {
      return courses?.sort((a, b) =>
        compareAB(a["courseTitle"], b["courseTitle"])
      );
    } else if (status === "desc" && !name) {
      return courses?.sort(
        (a, b) => -1 * compareAB(a["courseTitle"], b["courseTitle"])
      );
    }

    return courses?.filter((pros) => {
      if (name) {
        return (
          pros?.courseTitle &&
          pros?.courseTitle.toLowerCase().includes(name.toLowerCase())
        );
      }
      return true;
    });
  };

  const onSearch = (e) => {
    setSearchValue(e.target.value);
    setFilteredResult(searchLeadResult(e.target.value, sort));
  };

  const onSort = (e) => {
    setSort(e.target.value);
    setFilteredResult(searchLeadResult(searchValue, e.target.value));
  };

  return (
    <div className={classes.dataContainer}>
      <div className={classes.headerContainer}>
        <div className={classes.searchContainer}>
          <TextField
            size="small"
            style={{ width: 300, height: 38 }}
            label="Search"
            placeholder="Search by course name..."
            variant="outlined"
            value={searchValue}
            onChange={onSearch}
          />
          <FormControl
            size="small"
            variant="outlined"
            style={{ width: 180, height: 38 }}
          >
            <InputLabel>Sort By</InputLabel>
            <Select native label="Sort By" value={sort} onChange={onSort}>
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </Select>
          </FormControl>
        </div>
        <AddBatchCourse courseIds={courseIds} />
      </div>
      <CourseList courses={filteredResult} />
    </div>
  );
};
