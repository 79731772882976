import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { MarketHeader } from "./MarketHeader";
import { ViewEventsComponent } from "../../pages/Events/ViewEvents/ViewEvents";
const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 0,
  },
}));

export const MarketingEvets = () => {
  const classes = useStyles();
  return (
    <>
      <div>
        <MarketHeader />
        <div className={classes.container}>
          <ViewEventsComponent />
        </div>
      </div>
    </>
  );
};
