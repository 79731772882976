import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import AddStudent from "./AddStudent";

const useStyles = makeStyles({
  fullList: {
    padding: "20px",

  },
});

export default function AddNewStudent({ state, setState, editStudentData }) {
  const classes = useStyles();

  const handleDrawerClose = () => setState(false);
  const classe = `container mt-5 ${classes.fullList}`;

  return (
    <div>
      <React.Fragment key={"right"}>
        <SwipeableDrawer
          anchor={"right"}
          open={state}
          onClose={handleDrawerClose}
        >
          <div className={classe}>
            <AddStudent
              handleDrawerClose={handleDrawerClose}
              editStudentData={editStudentData}
            />
          </div>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
