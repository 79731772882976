import React from "react";
import * as XLSX from "xlsx";
import pick from "lodash.pick";
import { saveAs } from "file-saver";
import { AddProductButton } from "../../../common/Button";
import {
  DOMAIN_LOG_CSV_Config,
  HOST_LOG_CSV_Config,
  SSL_LOG_CSV_Config,
} from "./constant";

const ExcelExport = ({ tabName, entriesData = [] }) => {
  const FILE = {
    TYPE: "text/plain;charset=UTF-8",
    EXTENSION: ".csv",
    ORIGIN: "A2",
  };

  const exportToCsv = () => {
    const fileType = FILE.TYPE;
    const fileExtension = FILE.EXTENSION;

    const exportModel = [];
    const headers = [];
    CSV_CONFIG[tabName]?.forEach((column) => {
      if (!column.hidden) {
        exportModel.push(column.key);
        headers.push(column.label);
      }
    });
    const filteredData = entriesData.map((c) => {
      const formattedData = { ...c };
      exportModel.forEach((key) => {
        if (key === "purchaseDate" || key === "expireDate") {
          const timestampData = formattedData[key];

          const dateString = new Date(
            timestampData.seconds * 1000
          ).toLocaleDateString("en-US", {
            timeZone: "Asia/Kolkata",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });

          formattedData[key] = dateString;
        }
      });
      return pick(formattedData, exportModel);
    });
    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [headers]);
    XLSX.utils.sheet_add_json(ws, filteredData, {
      origin: FILE.ORIGIN,
      skipHeader: true,
    });
    const csv = XLSX.utils.sheet_to_csv(ws);
    const data = new Blob([csv], { type: fileType });
    saveAs(data, `entries${fileExtension}`);
  };

  return (
    <AddProductButton variant="outlined" onClick={exportToCsv}>
      {`Download ${tabName}`}
    </AddProductButton>
  );
};

export default ExcelExport;

const CSV_CONFIG = {
  Domain: DOMAIN_LOG_CSV_Config,
  SSL: SSL_LOG_CSV_Config,
  Host: HOST_LOG_CSV_Config,
};
