import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  cards: {
    width: 329,
    height: 274,
    marginTop: 30,
  },
  root: {
    width: "100%",
    height: "100%",
    boxShadow: "3.10377px 3.10377px 31.0377px rgba(0, 0, 0, 0.06)",
    borderRadius: 10,
    position: "relative",
  },
  logo: {
    height: 190,
    width: "100%",
    objectFit: "cover",
  },
  courseImage: {
    position: "absolute",
    borderRadius: "50%",
    bottom: 20,
    left: 20,
  },
}));

const CourseCards = ({ handleEditCourse, course }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.cards}>
        <Link
          to={`/course/view-course/${course?.courseId}`}
          state={{ data: course }}
        >
          <Card className={classes.root}>
            <div style={{ position: "relative" }}>
              <div className={classes.courseImage}>
                <img
                  src={course?.CourseLogo}
                  alt=""
                  width={75}
                  height={75}
                  style={{ borderRadius: "50%", objectFit: "cover" }}
                />
              </div>

              <div className={classes.logo}>
                <img
                  className={classes.logo}
                  src={course?.courseImage}
                  alt=""
                />
              </div>
            </div>

            <Divider />
            <div style={{ padding: 20 }}>
              <Typography style={{ fontWeight: 700 }}>
                {course?.courseTitle}
              </Typography>
              <Typography>{course?.courseDescription}</Typography>
            </div>
          </Card>
        </Link>
        <div>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={() => handleEditCourse(course)}
          >
            Edit
          </Button>
        </div>
      </div>
    </>
  );
};

export default CourseCards;
