import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { addStudentReview } from "../../../backend/EditDataToFirebase";
import {
  getDownloadURL,
  uploadFileToStorage,
} from "../../../backend/UploadMedia";
import { PrimaryButton } from "../../../common/Button";
import { successToastMessage } from "../../../common/Toastify";

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    marginTop: 30,
  },
  image: {
    width: 722,
    border: "1px solid #B3B3B3",
    borderRadius: 8,
  },
  headerTitle: {
    margin: "20px 0px",
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 27,
  },
}));

export const PreviewGoogleReview = ({
  reviewImage,
  studentData,
  handleClose,
}) => {
  const classes = useStyles();

  const handleUpload = async (file) => {
    if (typeof file === "string") return { url: file };
    const id =
      Date.now().toString(36) + Math.random().toString(36).substring(2);
    console.log(id);
    const ref = `/student-documents/${id}`;
    const uploadTask = uploadFileToStorage(ref, file);
    const url = await getDownloadURL(uploadTask);
    return url;
  };

  const handleUploadReview = async () => {
    const imageData = await handleUpload(reviewImage);
    const res = await addStudentReview(studentData.id, imageData);
    if (res.successful) {
      handleClose();
      successToastMessage("Google review added Successfully");
    }
  };

  return (
    <div className={classes.imageContainer}>
      <div className={classes.headerTitle}>
        <Typography style={{ fontWeight: "500" }} variant="h7">
          Preview of Uploaded Data
        </Typography>
      </div>
      {reviewImage ? (
        <img
          className={classes.image}
          alt="review_image"
          src={URL.createObjectURL(reviewImage)}
        />
      ) : studentData?.googleReview ? (
        <img
          className={classes.image}
          alt="review_image"
          src={studentData?.googleReview}
        />
      ) : null}
      <div className={classes.footer}>
        <PrimaryButton
          variant="contained"
          disabled={!reviewImage}
          color="primary"
          onClick={handleUploadReview}
        >
          Save
        </PrimaryButton>
      </div>
    </div>
  );
};
