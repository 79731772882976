import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as CORE_COURSE } from "../../globals/assets/course/course.svg";

const useStyles = makeStyles((theme) => ({
  header: {
    background: "#17191A",
    display: "flex",
    justifyContent: "space-between",
    padding: "30px 90px",
    color: "#F6F8FA",
    alignItems: "center",
  },
  button: {
    height: 42,
    width: 229,
    borderRadius: 0,
    textTransform: "capitalize",
    fontWeight: 500,
  },
  heading: {
    color: "#FFF4D5",
    fontWeight: 300,
    fontSize: 20,
  },
}));

const CourseHeader = ({ handleModal }) => {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      <div>
        <Typography variant="h5">Subject</Typography>
        <Typography className={classes.heading}>
          Lets create some amazing content.
        </Typography>
      </div>
      <div>
        <Button
          variant="contained"
          startIcon={<SvgIcon component={CORE_COURSE} />}
          className={classes.button}
          onClick={handleModal}
        >
          Add Subject
        </Button>
      </div>
    </div>
  );
};

export default CourseHeader;
