import { Avatar, Button, Divider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AddGoogleReview } from "../ViewStudents/AddGoogleReview/AddReview";
import { AddCertificate } from "../ViewStudents/AddCertificate/AddCertificate";

const useStyles = makeStyles({
  root: {
    height: 323,
  },
  header: {
    fontWeight: "500",
    padding: "18px 0px 8px",
    fontSize: 16,
  },
  detailsContainer: {
    display: "flex",
    gap: 69,
    padding: "28px 0px 48px",
  },
  detailsWrapper: {
    width: 410,
    display: "flex",
    flexDirection: "column",
    gap: 5,
  },
  details: {
    color: "#909090",
  },
  studentAvatar: {
    height: 120,
    width: 120,
  },
});

export const ShowStudentsDetails = ({ studentsDetails }) => {
  const classes = useStyles();

  const formatDate = (date) => {
    let updatedDate = new Date(date?.seconds * 1000);
    updatedDate = updatedDate.toLocaleDateString("en-US", {
      month: "short",
      year: "numeric",
      day: "numeric",
    });
    return updatedDate;
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.header}>
        Student’s Personal Information
      </Typography>
      <Divider />
      <div className={classes.detailsContainer}>
        <Avatar
          alt={studentsDetails?.profilePhoto}
          src={
            studentsDetails?.profilePhoto
              ? studentsDetails?.profilePhoto
              : "/assets/profile-icon.png"
          }
          className={classes.studentAvatar}
        />
        <div>
          <Typography variant="h6" style={{ paddingBottom: 5 }}>
            {studentsDetails?.studentName}
          </Typography>
          <div style={{ display: "flex" }}>
            <div className={classes.detailsWrapper}>
              <Typography variant="subtitle2">
                Date of Birth :{" "}
                <span className={classes.details}>
                  {formatDate(studentsDetails?.dob)}
                </span>
              </Typography>
              <Typography variant="subtitle2">
                Phone Number :{" "}
                <span className={classes.details}>
                  {studentsDetails?.contactNumber}
                </span>
              </Typography>
              <Typography variant="subtitle2">
                Parent’s Contact No. :{" "}
                <span className={classes.details}>
                  {studentsDetails?.parentsContactNumber}
                </span>
              </Typography>
              <Typography variant="subtitle2">
                Address :{" "}
                <span className={classes.details}>
                  {studentsDetails?.address}
                </span>
              </Typography>
              <Typography variant="subtitle2">
                Reference :{" "}
                <span className={classes.details}>
                  {studentsDetails?.reference}
                </span>
              </Typography>
              <Typography variant="subtitle2">
                Unique Id :{" "}
                <span className={classes.details}>
                  {studentsDetails?.uniqueId}
                </span>
              </Typography>
              <AddCertificate studentData={studentsDetails} showIcon={false} />
            </div>
            <div className={classes.detailsWrapper}>
              <Typography variant="subtitle2">
                Blood Group :{" "}
                <span className={classes.details}>
                  {studentsDetails?.bloodGroup}
                </span>
              </Typography>
              <Typography variant="subtitle2">
                Board :{" "}
                <span className={classes.details}>
                  {studentsDetails?.boardName}
                </span>
              </Typography>
              <Typography variant="subtitle2">
                School Name. :{" "}
                <span className={classes.details}>
                  {studentsDetails?.collegeName}
                </span>
              </Typography>
              <Typography variant="subtitle2">
                Emergency Contact Name :{" "}
                <span className={classes.details}>
                  {studentsDetails?.emergencyContactName}
                </span>
              </Typography>
              <Typography variant="subtitle2">
                Emergency Contact No. :{" "}
                <span className={classes.details}>
                  {studentsDetails?.emergencyContactNumber}
                </span>
              </Typography>
              <Typography variant="subtitle2">
                Email :{" "}
                <span className={classes.details}>
                  {studentsDetails?.email}
                </span>
              </Typography>
              <AddGoogleReview studentData={studentsDetails} showIcon={false} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
