import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    margin: "28px 39px 44px 40px",
    transition: "all 0.5s ease-in-out",
    width: "500px",

    "& .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar": {
      border: "none",
    },

    "& .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused)": {
      borderRadius: 8,
      minHeight: 381,
      overflowY: "auto",
      height: "100%",
      maxHeight: 500,
    },

    "& .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners":
      {
        borderRadius: 8,
        minHeight: 381,
        overflowY: "auto",
        height: "100%",
        maxHeight: 500,
      },

    "& .ck.ck-balloon-panel": {
      zIndex: 1200,
    },

    "& .ck.ck-input": {
      zIndex: 1200,
    },

    "& .ck-labled-field-view": {
      "&:focus": {
        boxShadow: "10px 10px 10px 10px red",
      },
    },
  },

  containerExpand: {
    margin: 0,
    width: "100vw",
    padding: 20,
    marginBottom: 50,
  },
}));

export const BlogDescription = ({ formData, handleChange, isExpand }) => {
  const classes = useStyles();
  const editorBody = !isExpand
    ? `${classes.container}`
    : `${classes.container} ${classes.containerExpand}`;

  return (
    <div className={editorBody}>
      <CKEditor
        editor={ClassicEditor}
        data={formData?.blogDescription ?? ""}
        onBlur={(event, editor) => {
          const data = editor.getData();
          handleChange("blogDescription", data);
        }}
      />
    </div>
  );
};
