import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { ModalBtn } from "../../../common/Button";
import AddIcon from "@material-ui/icons/Add";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import { createAssignment } from "../../../backend/sendCourseData";
import { successToastMessage } from "../../../common/Toastify";
import { useParams } from "react-router-dom";
import DateRangeIcon from "@material-ui/icons/DateRange";
import {
  uploadFileToStorage,
  getDownloadURL,
} from "../../../backend/UploadMedia";
import { editAssignment } from "../../../backend/EditCourseDetails";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.5)",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 100,
    bottom: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  box: {
    background: "#fff",
    width: 450,
    color: "#5F5F5F",
    borderRadius: 15,
  },
  header: {
    padding: "15px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  content: {
    padding: "25px 30px",
  },
  label: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    marginBottom: 10,
    color: "#000",
  },
  link: {
    transform: "rotate(-45deg)",
  },
  Btn: {
    paddingTop: 50,
    textAlign: "center",
  },
  description: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      height: 100,
      display: "block",
      padding: 10,
    },

    "& .MuiOutlinedInput-input": {
      padding: 0,
    },
  },
}));

const initialValue = {
  title: "",
  description: "",
  marks: "",
  submissionDate: "",
  file: "",
};

const ModalAssignment = ({
  modalAssignment,
  setModalAssignment,
  assignmentDetails,
}) => {
  const { id } = useParams();
  const classes = useStyles();
  const [assignmentForm, setAssignmentForm] = useState(initialValue);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (assignmentDetails) setAssignmentForm({ ...assignmentDetails });
  }, [assignmentDetails]);

  const handlerSubmit = async (e) => {
    setLoading(true);
    let result;
    const { title, description, marks, submissionDate, file } = assignmentForm;

    if (!assignmentForm.id) {
      const res = await uploadFile(file);
      const data = {
        title,
        description,
        marks,
        submissionDate,
        file: res.url,
      };
      result = await createAssignment(data, id);
    } else {
      let fileUrl = file;
      if (typeof file === "object") {
        fileUrl = (await uploadFile(file)).url;
      }
      const data = {
        ...assignmentForm,
        file: fileUrl,
      };
      result = await editAssignment(assignmentForm.id, id, data);
    }

    if (result.successfull) {
      if (assignmentForm.id) {
        successToastMessage("Assignment Updated Successfully");
      } else {
        successToastMessage("Assignment Created Successfully");
      }
      setAssignmentForm(initialValue);
      setModalAssignment(!modalAssignment);
    }
    setLoading(false);
  };

  const handlerChange = (name, value) => {
    setAssignmentForm({ ...assignmentForm, [`${name}`]: value });
  };

  const uploadFile = async (file) => {
    if (typeof file === "string") return { url: file };
    const id =
      Date.now().toString(36) + Math.random().toString(36).substring(2);
    const ref = `/course-assignment/${id}`;
    const uploadTask = await uploadFileToStorage(ref, file);
    const url = await getDownloadURL(uploadTask);
    return { url, ref };
  };
  return (
    <>
      {modalAssignment && (
        <div className={classes.main}>
          <div className={classes.box}>
            <div className={classes.header}>
              <Typography variant="h6">
                {assignmentForm.id ? "Edit ASSIGNMENT" : "Add ASSIGNMENT"}
              </Typography>
              <div
                onClick={() => setModalAssignment(!modalAssignment)}
                style={{ cursor: "pointer" }}
              >
                <ClearIcon style={{ color: "#000", fontSize: 22 }} />
              </div>
            </div>
            <Divider />
            <div className={classes.content}>
              <div style={{ marginBottom: 30 }}>
                <label htmlFor="" className={classes.label}>
                  <ListAltIcon />
                  Assignment Title:
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  style={{ width: "100%", height: 35 }}
                  placeholder="Title"
                  name="title"
                  value={assignmentForm.title}
                  onChange={(e) => handlerChange(e.target.name, e.target.value)}
                />
              </div>
              <div style={{ marginBottom: 30, marginTop: 20 }}>
                <label htmlFor="" className={classes.label}>
                  <MenuBookIcon />
                  Description:
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Add Description"
                  className={classes.description}
                  name="description"
                  value={assignmentForm.description}
                  onChange={(e) => handlerChange(e.target.name, e.target.value)}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  gap: 20,
                }}
              >
                <div>
                  <label htmlFor="" className={classes.label}>
                    {" "}
                    <DateRangeIcon /> Submission Date
                  </label>

                  <TextField
                    type="date"
                    variant="outlined"
                    placeholder=""
                    name="submissionDate"
                    value={assignmentForm.submissionDate}
                    onChange={(e) =>
                      handlerChange(e.target.name, e.target.value)
                    }
                  />
                </div>
                <div>
                  <label htmlFor="" className={classes.label}>
                    {" "}
                    <ListAltIcon /> Total Mark
                  </label>
                  <TextField
                    type="number"
                    variant="outlined"
                    placeholder="Add Marks"
                    name="marks"
                    value={assignmentForm.marks}
                    onChange={(e) =>
                      handlerChange(e.target.name, e.target.value)
                    }
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 20,
                }}
              >
                <label htmlFor="" className={classes.label}>
                  {" "}
                  <ListAltIcon /> Select File
                </label>

                <input
                  type="file"
                  name="file"
                  onChange={(e) =>
                    handlerChange(e.target.name, e.target.files[0])
                  }
                />

                <p>(max. 3MB)</p>
              </div>
              <div>
                {typeof assignmentForm?.file === "string"
                  ? assignmentForm?.file
                  : null}
              </div>

              <div className={classes.Btn}>
                <ModalBtn
                  disabled={loading}
                  variant="contained"
                  onClick={handlerSubmit}
                >
                  {assignmentForm.id ? (
                    <>
                      <EditIcon style={{ marginRight: 5 }} />
                      Edit
                    </>
                  ) : (
                    <>
                      <AddIcon style={{ marginRight: 5 }} />
                      Add
                    </>
                  )}
                </ModalBtn>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalAssignment;
