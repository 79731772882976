import { makeStyles } from "@material-ui/core/styles";
import { FormControl, Select, TextField, Typography } from "@material-ui/core";
import { AddProductFiles } from "./AddProductFiles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: "500",
  },
  headingWrapper: {
    paddingBottom: 14,
    borderBottom: "1px solid #CCCCCC",
  },
  container: {
    display: "flex",
    alignItems: "center",
    gap: 15,
    margin: "24px 0px",
  },
  required: {
    color: theme.palette.error.main,
  },
  label: { paddingBottom: 8 },
  textfield: { width: 206 },
}));

export const AddProductInfo = ({
  formData,
  handleChangeForm,
  isEditProduct,
}) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.headingWrapper}>
        <Typography variant="subtitle1" className={classes.heading}>
          Product Information
        </Typography>
      </div>
      {!isEditProduct && (
        <AddProductFiles
          formData={formData}
          handleChangeForm={handleChangeForm}
        />
      )}
      <div className={classes.container}>
        <div>
          <Typography className={classes.label}>
            Product Name <span className={classes.required}>*</span>
          </Typography>
          <TextField
            style={{ width: 427 }}
            placeholder="Product Name"
            variant="outlined"
            name="productName"
            value={formData.productName}
            onChange={(e) => handleChangeForm("productName", e.target.value)}
          />
        </div>

        <div>
          <Typography className={classes.label}>
            Product code<span className={classes.required}>*</span>
          </Typography>
          <TextField
            className={classes.textfield}
            placeholder="Product code"
            variant="outlined"
            name="productCode"
            value={formData.productCode}
            onChange={(e) => handleChangeForm("productCode", e.target.value)}
          />
        </div>
      </div>
      <div className={classes.container}>
        <div>
          <Typography className={classes.label}>
            Category<span className={classes.required}>*</span>
          </Typography>
          <FormControl variant="outlined">
            <Select
              native
              placeholder="Category"
              value={formData.productCategory}
              className={classes.textfield}
              onChange={(e) =>
                handleChangeForm("productCategory", e.target.value)
              }
            >
              <option aria-label="None" value="" />
              <option value={"Bag"}>Bags</option>
              <option value={"Bottle"}>Bottle</option>
              <option value={"Hoodie"}>Hoodies</option>
              <option value={"T-shirt"}>T-shirts</option>
            </Select>
          </FormControl>
        </div>
        <div>
          <Typography className={classes.label}>
            MRP<span className={classes.required}>*</span>
          </Typography>
          <TextField
            className={classes.textfield}
            type="number"
            placeholder="MRP"
            variant="outlined"
            name="productMRP"
            value={formData.productMRP}
            onChange={(e) =>
              handleChangeForm("productMRP", e.target.valueAsNumber || 0)
            }
          />
        </div>
        <div>
          <Typography className={classes.label}>
            Discounted Price<span className={classes.required}>*</span>
          </Typography>
          <TextField
            className={classes.textfield}
            type="number"
            placeholder="Discounted Price"
            variant="outlined"
            name="discountPrice"
            value={formData?.discountPrice}
            onChange={(e) =>
              handleChangeForm("discountPrice", e.target.valueAsNumber || 0)
            }
          />
        </div>
      </div>
      <div className={classes.container}>
        <div>
          <Typography className={classes.label}>
            No. of stock<span className={classes.required}>*</span>
          </Typography>
          <TextField
            className={classes.textfield}
            type="number"
            placeholder="No. of stock"
            variant="outlined"
            name="stock"
            value={formData.stock}
            onChange={(e) =>
              handleChangeForm("stock", e.target.valueAsNumber || 0)
            }
          />
        </div>
      </div>
    </React.Fragment>
  );
};
