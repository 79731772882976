import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import { AddProductButton } from "../../../../common/Button";
import AddIcon from "@material-ui/icons/Add";
import { AddCoursePage } from "./AddCoursesPage";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "100%",
      borderRadius: 15,
      boxShadow: "0px 4px 12px 0px #00000040",
    },
  },

  container: {
    width: "100%",
    height: "100%",
  },
}));

export const AddBatchCourse = ({ courseIds }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleState = () => {
    setOpen((currentState) => !currentState);
  };

  return (
    <>
      <AddProductButton
        variant="outlined"
        startIcon={<AddIcon />}
        onClick={handleState}
      >
        Add Courses
      </AddProductButton>
      <Dialog
        open={open}
        onClose={handleState}
        className={classes.dialog}
        style={{ zIndex: 1000 }}
      >
        <div className={classes.container}>
          <AddCoursePage handleClose={handleState} courseIds={courseIds} />
        </div>
      </Dialog>
    </>
  );
};
