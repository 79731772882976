import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: "500",
  },
  headingWrapper: {
    paddingBottom: 14,
    borderBottom: "1px solid #CCCCCC",
  },
  container: {
    position: "relative",
    margin: "30px 0px",
    transition: "all 0.5s ease-in-out",
    width: "653px",

    "& .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar": {
      border: "none",
    },

    "& .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused)": {
      borderRadius: 8,
      minHeight: 410,
      overflowY: "auto",
      height: "100%",
      maxHeight: 500,
    },

    "& .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners":
      {
        borderRadius: 8,
        minHeight: 410,
        overflowY: "auto",
        height: "100%",
        maxHeight: 500,
      },

    "& .ck.ck-balloon-panel": {
      zIndex: 1200,
    },

    "& .ck.ck-input": {
      zIndex: 1200,
    },

    "& .ck-labled-field-view": {
      "&:focus": {
        boxShadow: "10px 10px 10px 10px red",
      },
    },
  },
}));

export const EventDescription = ({ formData, handleChangeForm }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.headingWrapper}>
        <Typography variant="subtitle1" className={classes.heading}>
          Description
        </Typography>
      </div>
      <div className={classes.container}>
        <CKEditor
          editor={ClassicEditor}
          data={formData?.longDescription ?? ""}
          onBlur={(event, editor) => {
            const data = editor.getData();
            handleChangeForm("longDescription", data);
          }}
        />
      </div>
    </React.Fragment>
  );
};
