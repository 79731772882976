import { firestore } from "./firebase";

export const getCourseOption = async (setCourseOption, setOptionObject) => {
  firestore
    .collection("CourseOptions")
    .doc("CourseOptions")
    .onSnapshot((doc) => {
      const { options } = doc?.data();
      const array = Object.keys(options);
      setCourseOption(array ?? []);
      setOptionObject(options)
    });
};

export const setCourseOptions = async (data) => {
  const collectionRef = firestore
    .collection("CourseOptions")
    .doc("CourseOptions");
  await collectionRef.set({options: data}).catch((e) => {
    console.log(e);
    return { successful: false, message: "There is some Problem" };
  });
  return {
    successful: true,
    message: "Data has been successfully created.",
  };
};
