import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ToolBar from "../ToolBar";
import VideoCard from "./VideoCard";
import ModalVideo from "./ModalVideo";
import { useParams } from "react-router-dom";
import { getVideoData } from "../../../backend/getCourseData";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "40px 90px",
  },
  cards: {
    display: "flex",
    flexWrap: "wrap",
    gap: 22,
  },
}));

const VideoPreview = ({ course }) => {
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [videoData, setVideoData] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    handlerGetAssignment(id);
  }, [id]);

  const handlerGetAssignment = async (id) => {
    await getVideoData(setVideoData, id);
  };

  const handleModal = () => {
    setModal(true);
  };

  return (
    <>
      <div className={classes.container}>
        <ToolBar btnText="Add Video" handleModal={handleModal} />
        <div className={classes.cards}>
          {videoData?.map((item, index) => {
            return <VideoCard video={item} key={index} />;
          })}
        </div>
      </div>
      <ModalVideo setModal={setModal} modal={modal} />
    </>
  );
};

export default VideoPreview;
