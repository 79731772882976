import React from "react";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
const useStyles = makeStyles((theme) => ({
  // button: {
  //     margin: theme.spacing(1),
  //     background: '#fff',
  // },

  container: {
    background: "#17191A",
    width: "100%",
  },
  main: {
    width: "100%",
    maxWidth: "1128px",
    margin: "0 auto",
    position: "relative",
  },
  Header: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "30px",
    paddingBottom: "30px",
    borderBottom: "1px solid #333333",
  },
  MainHeading: {
    color: "#F6F8FA",
    fontWeight: "600",
    fontSize: "35px",
  },
  MainPara: {
    fontWeight: "300",
    fontSize: "24px",
    color: "#FFF4D5",
  },
  button: {
    margin: theme.spacing(1),
    fontWeight: 600,
    color: "#1A1A1A",
    padding: "8px 35px",
    textTransform: "capitalize",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: "180px",
    minHeight: "40px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  boxHeading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  root: {
    background: "#242529",
    width: "100%",
    height: "140px",
    padding: "16px 18px 15px 22px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  rate: {
    backgroundColor: "transparent",
    border: "1px solid #4D4D4D",
    borderRadius: "50px",
    color: "#CCCCCC",
    width: 45,
    height: 22,
    fontSize: 11,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  Analytics: {
    paddingTop: "30px",
    paddingBottom: "30px",
  },
  AnalyticsBox: {
    display: "flex",
    justifyContent: "space-between",
  },
  Form: {
    marginTop: "0px",
    display: "flex",
    gap: "20px",
  },
  Submit: {
    background: "#000",
    color: "#fff",
  },
  SubmitForm: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  Icons: {
    height: "20px",
    marginRight: "10px",
    width: "20px",
  },
  selectEmpty: {
    border: "1px solid #4D4D4D",
  },
}));

const Box = (props) => {
  const { id, icon, rate, para, numberOfData } = props.item;
  const classes = useStyles();
  return (
    <>
      <Card className={classes.root}>
        <div className={classes.boxHeading}>
          <img src={icon} alt="" height={25} width={25} />
          <div className={classes.rate}>{rate}</div>
        </div>
        <div className={classes.Title}>
          <p style={{ color: "#ccc", fontSize: "12px" }}>{para}</p>
          <h3 style={{ color: "#fff", fontSize: "28px", fontWeight: "400" }}>
            {numberOfData}
          </h3>
        </div>
      </Card>
    </>
  );
};

export default Box;
