import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTableCell-body": {
      minWidth: 120,
      maxWidth: 120,
    },
    "& .MuiTableCell-body:first-child": { maxWidth: 50, minWidth: 50 },
  },
  expand: {
    borderBottom: 0,
  },
}));

const CollapsRow = ({ student, open }) => {
  const classes = useRowStyles();

  return (
    <>
      <TableRow className={classes.root}>
        <TableCell style={{ padding: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table>
                <TableBody>
                  <TableHead></TableHead>
                  <TableBody>
                    {student?.courseDetails
                      ?.map((item, index) => (
                        <>
                          <TableRow>
                            <TableCell
                              className={classes.expand}
                              style={{ width: 55 }}
                            ></TableCell>
                            <TableCell
                              className={classes.expand}
                              style={{ width: 55 }}
                            ></TableCell>
                            <TableCell
                              className={classes.expand}
                              style={{ width: 55 }}
                            ></TableCell>
                            <TableCell
                              className={classes.expand}
                              style={{ width: 80 }}
                            >
                              {item?.courseName}
                            </TableCell>
                            <TableCell
                              className={classes.expand}
                              style={{ width: 110 }}
                            >
                              {item?.courseFee}
                            </TableCell>
                            <TableCell
                              className={classes.expand}
                              style={{ width: 105, color: "#219653" }}
                            >
                              {item?.totalPaidFee}
                            </TableCell>
                            <TableCell
                              className={classes.expand}
                              style={{ width: 100, color: "#F44336" }}
                            >
                              {parseFloat(item?.courseFee) -
                                parseFloat(item?.totalPaidFee)}
                            </TableCell>
                            <TableCell
                              className={classes.expand}
                              style={{ width: 65 }}
                            ></TableCell>
                            <TableCell
                              className={classes.expand}
                              style={{ width: 65 }}
                            ></TableCell>
                          </TableRow>
                        </>
                      ))
                      .slice(1)}
                  </TableBody>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default CollapsRow;
