import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import TablePagination from "@material-ui/core/TablePagination";
import { Button, TextField } from "@material-ui/core";
import { deleteStudent } from "../../backend/EditDataToFirebase";
import { WarningModal } from "../../common/WarningModal";
import { ActionCol } from "../../common/ActionColumn/ActionCol";
import { deleteToast } from "../../common/Toastify";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import AddNewStudent from "../AddStudents/AddNewStudent";
import { AddFees } from "../../common/Button";
import AddIcon from "@material-ui/icons/Add";
import { OpenFeeModal } from "../AddStudentFee/OpenAddFees";

import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { OpenViewStudentDetails } from "../StudentDetails/OpenViewStudentDetails";
import { firestore } from "../../backend/firebase";
import { AddCertificate } from "./AddCertificate/AddCertificate";
import { AddGoogleReview } from "./AddGoogleReview/AddReview";
import CollapsRow from "./StudentsTable/CollapsRow";
import ExportStudent from "./ExportStudent";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
    "& .MuiTableCell-body": {
      width: 120,
      maxWidth: 120,
    },
    "& .MuiTableCell-body:first-child": { maxWidth: 50, minWidth: 50 },
  },
  dateContainer: {
    paddingBottom: 30,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  paidFee: {
    color: theme.palette.success.dark,
  },
  pendingFee: {
    color: theme.palette.error.main,
  },
  container: {
    maxHeight: 600,
  },
  expandTable: {
    marginLeft: "17vw",
  },
  tableRow: {
    display: "flex",
  },

  expand: {
    width: "75px",
    "& .MuiTableCell-root": {
      borderBottom: 0,
    },
  },

  arrowIcon: {
    width: "30px",
    height: "30px",
  },
}));

function Row(props) {
  const { student } = props;
  const [selectedStudent, setSelectedStudent] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [openWarning, setOpenWarning] = React.useState(false);
  const [openAddStudent, setOpenAddStudent] = React.useState(false);
  const [openAddFees, setOpenAddFees] = React.useState(false);
  const [openViewDetails, setOpenViewDetails] = React.useState(false);

  const classes = useRowStyles();
  const totalPaidFee = student?.courseDetails?.[0]?.totalPaidFee ?? 0;

  const onAddFees = (data) => {
    setSelectedStudent(data);
    setOpenAddFees(true);
  };

  const onView = (data) => {
    setSelectedStudent(data);
    setOpenViewDetails(true);
  };

  const onEdit = (data) => {
    setSelectedStudent(data);
    setOpenAddStudent(true);
  };

  const onDelete = async (data) => {
    setSelectedStudent(data);
    setOpenWarning(true);
  };

  const deleteStudentConfirm = async () => {
    await deleteStudent(selectedStudent.id);
    setOpenWarning(false);
    deleteToast("Student");
  };

  return (
    <React.Fragment>
      <OpenViewStudentDetails
        state={openViewDetails}
        setState={setOpenViewDetails}
        student={selectedStudent}
      />
      <AddNewStudent
        state={openAddStudent}
        setState={setOpenAddStudent}
        editStudentData={selectedStudent}
      />
      <OpenFeeModal
        state={openAddFees}
        setState={setOpenAddFees}
        student={selectedStudent}
      />
      <WarningModal
        open={openWarning}
        setOpen={setOpenWarning}
        message={"Student"}
        subMessage={
          "All student data like, fees, courses and etc will be deleted & wont be accessible anymore"
        }
        deleteItem={deleteStudentConfirm}
      />
      <TableRow className={classes.root}>
        {student.courseDetails.length > 1 ? (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <ArrowDropDownIcon className={classes.arrowIcon} />
              ) : (
                <ArrowRightIcon className={classes.arrowIcon} />
              )}
            </IconButton>
          </TableCell>
        ) : (
          <TableCell></TableCell>
        )}
        <TableCell>{student?.studentName}</TableCell>
        <TableCell>{student?.contactNumber}</TableCell>
        <TableCell>{student?.courseDetails?.[0]?.courseName}</TableCell>
        <TableCell>{student?.courseDetails?.[0]?.courseFee}</TableCell>
        <TableCell>
          <div className={classes.paidFee}>{totalPaidFee}</div>
        </TableCell>
        <TableCell>
          <div className={classes.pendingFee}>
            {student?.courseDetails?.[0]?.courseFee - totalPaidFee}
          </div>
        </TableCell>
        <TableCell style={{ display: "flex", maxWidth: "unset" }}>
          <AddFees
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={() => onAddFees(student)}
          >
            Add Fees
          </AddFees>
          <ActionCol
            data={student}
            onView={onView}
            onEdit={onEdit}
            onDelete={onDelete}
          />
          <AddCertificate studentData={student} />
          <AddGoogleReview studentData={student} />
        </TableCell>
      </TableRow>
      {student.courseDetails.length > 1 && (
        <CollapsRow student={student} open={open} />
      )}

      {/* <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow> */}
    </React.Fragment>
  );
}

export function ViewStudentsTable() {
  const classes = useRowStyles();
  const [students, setStudents] = useState([]);
  const [filteredStudent, setFilteredStudents] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  //searching sorting
  const [searchValue, setSearchValue] = useState("");
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [sort, setSort] = useState(null);
  const [filter, setFilter] = useState("");

  async function getStudentsList(startDate, endDate) {
    let query = firestore.collection("Students");

    if (startDate && endDate) {
      query = query
        .where("joinDate", ">=", startDate)
        .where("joinDate", "<=", endDate);
    }

    query.onSnapshot((querySnapshot) => {
      const data = [];
      querySnapshot?.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      setStudents(data);
      setFilteredStudents(data);
    });
  }

  useEffect(() => {
    getStudentsList(startDate, endDate);
  }, [startDate, endDate]);

  const onSearch = (e) => {
    setSearchValue(e.target.value);
    setFilteredStudents(searchProductsResult(e.target.value, ""));
  };

  const searchProductsResult = (name, status) => {
    return students.filter((pros) => {
      if (name && status) {
        return (
          ((pros.studentName &&
            pros.studentName.toLowerCase().includes(name.toLowerCase())) ||
            (pros.address &&
              pros.address.toLowerCase().includes(name.toLowerCase()))) &&
          pros.status &&
          pros.status.toLowerCase() === status.toLowerCase()
        );
      }
      if (name) {
        return (
          (pros.studentName &&
            pros.studentName.toLowerCase().includes(name.toLowerCase())) ||
          (!!pros.courseDetails?.length &&
            pros.courseDetails.find((course) => {
              return (
                course?.courseName &&
                course?.courseName.toLowerCase().includes(name.toLowerCase())
              );
            }))
        );
      }
      if (status) {
        return (
          pros.status && pros.status.toLowerCase() === status.toLowerCase()
        );
      }
      return true;
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlerSort = (e) => {
    let sortedData = [];
    setSort(e.target.value);
    switch (e.target.value) {
      case "ASC":
        sortedData = students.sort((a, b) => {
          return a["studentName"].toLowerCase() < b["studentName"].toLowerCase()
            ? -1
            : 1;
        });
        break;
      case "DESC":
        sortedData = students.sort((a, b) => {
          return a["studentName"].toLowerCase() < b["studentName"].toLowerCase()
            ? 1
            : -1;
        });
        break;
      default:
        sortedData = students;
        break;
    }
    setFilteredStudents(sortedData);
  };

  const handlerFilter = (e) => {
    const { name, value } = e.target;
    setFilter(value);
    if (value) {
      if (sort === "ASC") {
        const filteredData = students.sort((a, b) => {
          return a["studentName"].toLowerCase() < b["studentName"].toLowerCase()
            ? 1
            : -1;
        });
        setFilteredStudents(filteredData);
      } else {
        const filteredData = students.sort((a, b) => {
          return a["studentName"].toLowerCase() < b["studentName"].toLowerCase()
            ? -1
            : 1;
        });
        setFilteredStudents(filteredData);
      }
    } else {
      setFilteredStudents(students);
    }
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredStudent.length - page * rowsPerPage);

  let totalCourseFee = 0;
  let totalPaidFee = 0;

  filteredStudent.forEach((entry) => {
    const courseDetails = entry.courseDetails || [];
    courseDetails.forEach((course) => {
      totalCourseFee += parseFloat(course.courseFee) || 0;
      totalPaidFee += parseFloat(course.totalPaidFee) || 0;
    });
  });

  return (
    <div style={{ padding: "32px 38px 64px" }}>
      <div className={classes.dateContainer}>
        <TextField
          style={{ width: 300 }}
          label="Search"
          placeholder="Name and Course"
          variant="outlined"
          value={searchValue}
          onChange={(e) => {
            onSearch(e);
          }}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            style={{ width: 190 }}
            variant="inline"
            format="dd/MM/yyyy"
            id="date-picker-inline"
            label="Start Date"
            value={startDate}
            onChange={setStartDate}
            inputVariant="outlined"
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
          <KeyboardDatePicker
            style={{ width: 190 }}
            variant="inline"
            format="dd/MM/yyyy"
            id="date-picker-inline"
            label="End Date"
            value={endDate}
            onChange={setEndDate}
            inputVariant="outlined"
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>

        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="sortby">Sort By</InputLabel>
          <Select
            native
            label="Sort By"
            id="sortby"
            value={sort}
            onChange={handlerSort}
          >
            <option value=""></option>
            <option value="ASC">Ascending</option>
            <option value="DESC">Descending</option>
          </Select>
        </FormControl>

        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel>Filter</InputLabel>
          <Select
            label="Filter"
            style={{ width: 150 }}
            value={filter}
            onChange={handlerFilter}
          >
            <MenuItem value="Student Name">Student Name</MenuItem>
          </Select>
        </FormControl>
        <ExportStudent students={filteredStudent} />
      </div>
      <TableContainer className={classes.container} component={Paper}>
        <Table stickyHeader>
          <TableHead style={{ position: "relative", zIndex: 1 }}>
            <TableRow>
              <TableCell />
              <TableCell>Name</TableCell>
              <TableCell>Contact No.</TableCell>
              <TableCell>Course</TableCell>
              <TableCell>
                Total Fees <br />
                <span style={{ fontSize: 12 }}>
                  (Rs.
                  <span style={{ color: "red" }}>
                    {totalCourseFee.toLocaleString("en-IN")}
                  </span>
                  )
                </span>
              </TableCell>
              <TableCell>
                Fees Paid <br />
                <span style={{ fontSize: 12 }}>
                  (Rs.{" "}
                  <span style={{ color: "red" }}>
                    {totalPaidFee.toLocaleString("en-IN")}
                  </span>
                  )
                </span>
              </TableCell>
              <TableCell>
                Pending Fees <br />
                <span style={{ fontSize: 12 }}>
                  (Rs.{" "}
                  <span style={{ color: "red" }}>
                    {(totalCourseFee - totalPaidFee).toLocaleString("en-IN")}
                  </span>
                  )
                </span>
              </TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          {filteredStudent.length ? (
            <TableBody style={{ position: "relative", zIndex: 0 }}>
              {filteredStudent
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((student, index) => {
                  return <Row key={index} student={student} />;
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          ) : (
            <TableBody>
              <TableCell>
                <p>Please Add a Student</p>
              </TableCell>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredStudent.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
