import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    boxShadow: "3.10377px 3.10377px 31.0377px rgba(0, 0, 0, 0.06)",
    borderRadius: 10,
    position: "relative",
  },
  logo: {
    height: 190,
    width: "100%",
    objectFit: "cover",
  },
  courseImage: {
    position: "absolute",
    borderRadius: "50%",
    bottom: 20,
    left: 20,
  },
}));

export const CoursePreview = ({ course }) => {
  const classes = useStyles();

  let courseImage;
  let CourseLogo;

  if (course?.courseImage) {
    if (typeof course?.courseImage === "string") {
      courseImage = course?.courseImage;
    } else {
      courseImage = URL.createObjectURL(course?.courseImage);
    }
  }

  if (course?.CourseLogo) {
    if (typeof course?.CourseLogo === "string") {
      CourseLogo = course?.CourseLogo;
    } else {
      CourseLogo = URL.createObjectURL(course?.CourseLogo);
    }
  }

  return (
    <div>
      <h4>Course Card Preview</h4>
      <Card className={classes.root}>
        <div style={{ position: "relative" }}>
          <div className={classes.courseImage}>
            <img
              src={CourseLogo}
              alt=""
              width={75}
              height={75}
              style={{ borderRadius: "50%", objectFit: "cover" }}
            />
          </div>

          <div className={classes.logo}>
            <img className={classes.logo} src={courseImage} alt="" />
          </div>
        </div>

        <Divider />
        <div style={{ padding: 20 }}>
          <Typography style={{ fontWeight: 700 }}>
            {course?.courseTitle}
          </Typography>
          <Typography>{course?.courseDescription}</Typography>
        </div>
      </Card>
    </div>
  );
};
