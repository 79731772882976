import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    height: 191,
    backgroundColor: "#17191A",
    padding: "64px 90px 69px",
  },
  headerTitle: {
    fontWeight: "600",
    color: theme.palette.common.white,
  },
  headerSubTitle: {
    fontWeight: "300",
    color: theme.palette.common.white,
  },
}));

export const ReminderHeader = () => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.headerContainer}>
        <Typography variant="h4" className={classes.headerTitle}>
          Reminder
        </Typography>
        <Typography variant="h5" className={classes.headerSubTitle}>
          Notify about all important dates
        </Typography>
      </div>
    </React.Fragment>
  );
};
