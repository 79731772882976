import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { ModalBtn } from "../../../common/Button";
import AddIcon from "@material-ui/icons/Add";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import QueryBuilderOutlinedIcon from "@material-ui/icons/QueryBuilderOutlined";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { createQuiz, getAllQuizDetails } from "../../../backend/sendCourseData";
import { successToastMessage } from "../../../common/Toastify";
import { useParams } from "react-router-dom";
import { editQuizz } from "../../../backend/EditCourseDetails";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.5)",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 100,
    bottom: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  box: {
    background: "#fff",
    width: 450,
    color: "#5F5F5F",
    borderRadius: 15,
  },
  header: {
    padding: "15px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  content: {
    padding: "25px 30px",
  },
  label: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    marginBottom: 10,
    color: "#000",
  },
  link: {
    transform: "rotate(-45deg)",
  },
  Btn: {
    paddingTop: 50,
    textAlign: "center",
  },
  description: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      height: 100,
      display: "block",
      padding: 10,
    },

    "& .MuiOutlinedInput-input": {
      padding: 0,
    },
  },
}));

const initialValue = {
  title: "",
  minute: "",
  hour: "",
  description: "",
  quizId: "",
};

const ModalQuiz = ({ modal, setModal, editQuiz }) => {
  const { id } = useParams();
  const classes = useStyles();
  const [quizForm, setQuizForm] = useState(initialValue);
  const [quizList, setQuizList] = useState([]);

  useEffect(() => {
    if (editQuiz) setQuizForm({ ...editQuiz });
  }, [editQuiz]);

  useEffect(() => {
    handlerGetQuiz();
  }, []);

  const handlerGetQuiz = async () => {
    const res = await getAllQuizDetails();
    setQuizList(res);
  };

  const handlerSubmit = async (e) => {
    const filterData = await quizList.filter((item, index) => {
      return item.id.includes(quizForm.quizId);
    });
    let result;

    if (!quizForm?.id) {
      const data = {
        title: quizForm.title,
        description: quizForm.description,
        duration: Number(quizForm.hour) * 60 + Number(quizForm.minute),
        quizId: quizForm.quizId,
        questions: filterData[0]?.questions?.length,
      };
      result = await createQuiz(data, id);
    } else {
      result = await editQuizz(quizForm.id, id, quizForm);
    }

    if (result.successful) {
      setQuizForm(initialValue);
      setModal(!modal);
      successToastMessage("Course Created Successfully");
    }
  };

  const handlerChange = (name, value) => {
    setQuizForm({ ...quizForm, [`${name}`]: value });
  };

  return (
    <>
      {modal && (
        <div className={classes.main}>
          <div className={classes.box}>
            <div className={classes.header}>
              <Typography variant="h6">
                {quizForm?.id ? "Edit QUIZ" : "Add QUIZ"}
              </Typography>
              <div
                onClick={() => setModal(!modal)}
                style={{ cursor: "pointer" }}
              >
                <ClearIcon style={{ color: "#000", fontSize: 22 }} />
              </div>
            </div>
            <Divider />
            <div className={classes.content}>
              <div style={{ marginBottom: 30 }}>
                <label htmlFor="" className={classes.label}>
                  <ListAltIcon />
                  Quiz Title:
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  style={{ width: "100%", height: 35 }}
                  placeholder="Quiz Title"
                  name="title"
                  value={quizForm.title}
                  onChange={(e) => handlerChange(e.target.name, e.target.value)}
                />
              </div>
              <div style={{ marginBottom: 30, marginTop: 20 }}>
                <label htmlFor="" className={classes.label}>
                  <MenuBookIcon />
                  Quiz Description:
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Add Description"
                  className={classes.description}
                  name="description"
                  value={quizForm.description}
                  onChange={(e) => handlerChange(e.target.name, e.target.value)}
                />
              </div>

              <div>
                <label htmlFor="" className={classes.label}>
                  <QueryBuilderOutlinedIcon />
                  Time Duration:{" "}
                </label>
                <div style={{ display: "flex", gap: 10 }}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Hours"
                    style={{ width: 100 }}
                    type="number"
                    name="hour"
                    value={quizForm.hour}
                    onChange={(e) =>
                      handlerChange(e.target.name, e.target.value)
                    }
                  />
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Min"
                    style={{ width: 100 }}
                    type="number"
                    name="minute"
                    value={quizForm.minute}
                    onChange={(e) =>
                      handlerChange(e.target.name, e.target.value)
                    }
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 20,
                }}
              >
                <label htmlFor="" className={classes.label}>
                  {" "}
                  <ListAltIcon /> Select File
                </label>
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    style={{ width: "100%" }}
                    name="quizId"
                    value={quizForm.quizId}
                    onChange={(e) =>
                      handlerChange(e.target.name, e.target.value)
                    }
                  >
                    <MenuItem value="" disabled selected>
                      <em>None</em>
                    </MenuItem>
                    {quizList.map((item, index) => {
                      return <MenuItem value={item.id}>{item.title}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </div>

              <div className={classes.Btn}>
                <ModalBtn variant="contained" onClick={handlerSubmit}>
                  {quizForm?.id ? (
                    <>
                      <EditIcon style={{ marginRight: 5 }} />
                      Edit
                    </>
                  ) : (
                    <>
                      <AddIcon style={{ marginRight: 5 }} />
                      Add
                    </>
                  )}
                </ModalBtn>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalQuiz;
