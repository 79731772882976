import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import TrainerDetails from "./TrainerDetails";

const useStyles = makeStyles({
  container: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: 1228,
    },
  },
  fullList: {
    minWidth: "1228px",
    padding: "32px 38px 32px",
  },
});

const OpenTrainerDetails = ({ open, setViewTrainer, trainerDetails }) => {
  const classes = useStyles();

  const handleDrawerClose = () => {
    setViewTrainer(false);
  };
  const classe = `container mt-5 ${classes.fullList}`;
  return (
    <div>
      <Dialog
        className={classes.container}
        open={open}
        onClose={handleDrawerClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className={classe}>
          <TrainerDetails
            handleDrawerClose={handleDrawerClose}
            trainerDetails={trainerDetails}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default OpenTrainerDetails;
