import { firestore } from "./firebase";

export const addBlog = async (data) => {
  console.log(data);
  const collectionRef = firestore.collection("Blogs").doc();
  await collectionRef
    .set({
      ...data,
      docId: collectionRef.id,
    })
    .catch((e) => {
      console.log(e);
      return { successfull: false, message: "There is some Problem" };
    });
  return {
    successfull: true,
    message: "Data has been successfully created.",
  };
};

export const createStudent = async (data) => {
  console.log(data);
  const collectionRef = firestore.collection("Students").doc();
  await collectionRef
    .set({
      ...data,
      docId: collectionRef.id,
    })
    .catch((e) => {
      console.log(e);
      return { successful: false, message: "There is some Problem" };
    });
  return {
    successful: true,
    message: "Data has been successfully created.",
  };
};

export const createTrainer = async (data) => {
  const collectionSize = (await firestore.collection("Trainers").get()).size;
  const lastTwoDigitsOfYear = new Date().getFullYear() % 100;
  const uniqueId = `RCT${lastTwoDigitsOfYear}${collectionSize + 1}`;

  const collectionRef = firestore.collection("Trainers").doc();
  await collectionRef
    .set({
      ...data,
      uniqueId,
      docId: collectionRef.id,
    })
    .catch((e) => {
      console.log(e);
      return { successful: false, message: "There is some Problem" };
    });
  return {
    successful: true,
    message: "Data has been successfully created.",
  };
};

export const createEvent = async (data) => {
  const collectionRef = firestore.collection("Events").doc();
  await collectionRef
    .set({
      ...data,
      docId: collectionRef.id,
    })
    .catch((e) => {
      console.log(e);
      return { successful: false, message: "There is some Problem" };
    });
  return {
    successful: true,
    message: "Data has been successfully created.",
  };
};

export const addNewProduct = async (data) => {
  const collectionRef = firestore.collection("Products").doc();
  await collectionRef
    .set({
      ...data,
      docId: collectionRef.id,
    })
    .catch((e) => {
      console.log(e);
      return { successful: false, message: "There is some Problem" };
    });
  return {
    successful: true,
    message: "Product has been successfully added.",
  };
};

export const addNewReminder = async (collectionName, data) => {
  const collectionRef = firestore.collection(collectionName).doc();
  await collectionRef
    .set({
      ...data,
      docId: collectionRef.id,
    })
    .catch((e) => {
      console.log(e);
      return { successful: false, message: "There is some Problem" };
    });
  return {
    successful: true,
    message: "Reminder has been successfully added.",
  };
};

export const addBatch = async (data) => {
  const collectionRef = firestore.collection("Batch").doc();
  await collectionRef
    .set({
      ...data,
      docId: collectionRef.id,
      date: new Date(),
    })
    .catch((e) => {
      console.log(e);
      return { successfull: false, message: "There is some Problem" };
    });
  return {
    successfull: true,
    message: "Batch has been successfully created.",
  };
};

export const addNewQuiz = async (data) => {
  if (!data) return;

  const collectionRef = firestore.collection("Quiz").doc();
  await collectionRef
    .set({
      ...data,
      docId: collectionRef.id,
    })
    .catch((e) => {
      console.log(e);
      return { successfull: false, message: "There is some Problem" };
    });
  return {
    successfull: true,
    message: "Quiz has been successfully created.",
  };
};
