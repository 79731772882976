import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, Select, TextField, Typography } from "@material-ui/core";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import { Autocomplete } from "@material-ui/lab";
import { EVENT_KEYWORDS } from "../../../common/const";
import UPLOAD_IMAGE from "../../../globals/assets/input-images/upload-image.svg";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontWeight: "500",
  },
  headingWrapper: {
    paddingBottom: 14,
    borderBottom: "1px solid #CCCCCC",
  },
  container: {
    display: "flex",
    alignItems: "center",
    gap: 15,
    margin: "24px 0px",
  },
  required: {
    color: theme.palette.error.main,
  },
  label: { paddingBottom: 8 },
  textfield: { width: 319 },
}));

export const EventForm = ({ formData, handleChange }) => {
  const classes = useStyles();
  const refData = React.useRef();
  const image = formData?.id
    ? formData?.eventPoster
    : formData?.eventPoster
    ? URL.createObjectURL(formData?.eventPoster)
    : null;

  function handlePoster(image) {
    handleChange("eventPoster", image);
  }

  return (
    <React.Fragment>
      <div className={classes.headingWrapper}>
        <Typography variant="subtitle1" className={classes.heading}>
          Event Information
        </Typography>
      </div>
      {!formData?.id && (
        <div style={{ paddingTop: 30 }}>
          <label>
            <img width={653} alt="upload Excel" src={UPLOAD_IMAGE} />
            <input
              alt="event poster"
              type="file"
              accept="image/*"
              name="uploadEvent"
              style={{ display: "none" }}
              onChange={(e) => handlePoster(e.target.files[0])}
            />
          </label>
        </div>
      )}
      {image ? (
        <div style={{ paddingTop: 20 }}>
          <img
            alt="poster"
            height={200}
            width={400}
            style={{ objectFit: "contain" }}
            src={image}
          />
        </div>
      ) : null}
      <div className={classes.container}>
        <div>
          <Typography className={classes.label}>
            Event Name <span className={classes.required}>*</span>
          </Typography>
          <TextField
            className={classes.textfield}
            placeholder="Event Name"
            variant="outlined"
            name="eventName"
            value={formData.eventName}
            onChange={(e) => handleChange("eventName", e.target.value)}
          />
        </div>
        <div>
          <Typography className={classes.label}>
            Event Form Link<span className={classes.required}>*</span>
          </Typography>
          <TextField
            className={classes.textfield}
            placeholder="Form Link"
            variant="outlined"
            name="formLink"
            value={formData.formLink}
            onChange={(e) => handleChange("formLink", e.target.value)}
          />
        </div>
      </div>
      <div className={classes.container}>
        <div>
          <Typography className={classes.label}>
            Event Short Description<span className={classes.required}>*</span>
          </Typography>
          <TextField
            style={{ width: 420 }}
            className={classes.textfield}
            placeholder="Event short description (upto- 100-150 letters)"
            variant="outlined"
            name="eventDescription"
            value={formData.eventDescription}
            onChange={(e) => handleChange("eventDescription", e.target.value)}
          />
        </div>
        <div>
          <FormControl variant="outlined" className={classes.formControl}>
            <Typography className={classes.label}>
              Event Category<span className={classes.required}>*</span>
            </Typography>
            <Select
              native
              style={{ width: 218 }}
              placeholder="Event Category"
              name="eventCategory"
              value={formData?.eventCategory}
              onChange={(e) => handleChange("eventCategory", e.target.value)}
              inputProps={{
                name: "age",
                id: "outlined-age-native-simple",
              }}
            >
              <option aria-label="None" value="" />
              <option value={"workshop"}>Workshop</option>
              <option value={"bootcamp"}>Bootcamp</option>
              <option value={"webinar"}>Webinar</option>
            </Select>
          </FormControl>
        </div>
      </div>
      <div className={classes.container}>
        <div>
          <Typography className={classes.label}>
            Event Tags<span className={classes.required}>*</span>
          </Typography>
          <Autocomplete
            placeholder="Event Tags"
            multiple
            options={EVENT_KEYWORDS}
            ref={refData}
            name="topics"
            value={formData.tags}
            getOptionLabel={(option) => option}
            onChange={(e, value) => {
              handleChange("tags", value);
            }}
            renderInput={(params) => (
              <TextField
                style={{ minWidth: 319 }}
                {...params}
                name="tags"
                variant="outlined"
                placeholder="Event Tags"
              />
            )}
          />
        </div>
        <div>
          <FormControl variant="outlined" className={classes.formControl}>
            <Typography className={classes.label}>
              Event Mode<span className={classes.required}>*</span>
            </Typography>
            <Select
              native
              className={classes.textfield}
              placeholder="Event Mode"
              name="eventMode"
              value={formData?.eventMode}
              onChange={(e) => handleChange("eventMode", e.target.value)}
              inputProps={{
                name: "age",
                id: "outlined-age-native-simple",
              }}
            >
              <option aria-label="None" value="" />
              <option value={"online"}>Online</option>
              <option value={"offline"}>Offline</option>
            </Select>
          </FormControl>
        </div>
      </div>
      <div className={classes.container}>
        <div>
          <Typography className={classes.label}>
            Registration Date and Time
            <span className={classes.required}>*</span>
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              className={classes.textfield}
              disableToolbar
              inputVariant="outlined"
              name="registrationDate"
              variant="inline"
              value={formData.registrationDate}
              onChange={(date) => {
                handleChange("registrationDate", date);
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div>
          <Typography className={classes.label}>
            Event Date and Time<span className={classes.required}>*</span>
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              className={classes.textfield}
              disableToolbar
              inputVariant="outlined"
              name="eventDate"
              variant="inline"
              value={formData.eventDate}
              onChange={(date) => {
                handleChange("eventDate", date);
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
    </React.Fragment>
  );
};
