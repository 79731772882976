import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import { useNavigate } from "react-router-dom";
import { getProducts } from "../../../backend/getDataFromFirestore";

const useRowStyles = makeStyles((theme) => ({
  root: {
    cursor: "pointer",
    "& > *": {
      fontWeight: "500",
    },
  },
  container: {
    maxHeight: 600,
    zIndex: 0,
    position: "relative",
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: "#ffff",
      fontWeight: "600",
      height: 72,
    },
  },
  availStatus: {
    color: theme.palette.success.main,
  },
  notAvailStatus: {
    color: theme.palette.error.main,
  },
}));

function Row({ product }) {
  const classes = useRowStyles();
  const navigate = useNavigate();
  const handlerViewProductDetails = (id) => {
    navigate(`/shop/product-view/${id}`);
  };

  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        onClick={(e) => handlerViewProductDetails(product.docId)}
      >
        <TableCell
          component="th"
          scope="row"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <img
            style={{ height: 78 }}
            src={product?.productImage[0]}
            alt={product?.productCode}
          />
        </TableCell>
        <TableCell>{product?.productCode}</TableCell>
        <TableCell>{product?.productName}</TableCell>
        <TableCell>{product?.productCategory}</TableCell>
        <TableCell>{product?.productMRP}</TableCell>
        <TableCell>{product?.discountPrice}</TableCell>
        <TableCell>
          <div
            className={
              product?.stock > 0 ? classes.availStatus : classes.notAvailStatus
            }
          >
            {product?.stock > 0 ? "In stock" : "Out of stock"}
          </div>
        </TableCell>
        <TableCell>
          <div>{product?.stock}</div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export function ProductStockTable() {
  const classes = useRowStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [productStock, setProducts] = useState([]);

  useEffect(() => {
    getProducts(setProducts);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, productStock.length - page * rowsPerPage);

  return (
    <>
      <TableContainer className={classes.container} component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>ID Code</TableCell>
              <TableCell>Product Name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>MRP</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>In stock</TableCell>
            </TableRow>
          </TableHead>
          {productStock.length ? (
            <TableBody>
              {productStock
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((product, index) => {
                  return <Row key={index} product={product} />;
                })}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          ) : (
            <TableBody>
              <TableCell>
                <p>Please Add a Product</p>
              </TableCell>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={productStock.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
