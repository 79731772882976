import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useNavigate } from "react-router-dom";
import { deleteBlog } from "../../../backend/EditDataToFirebase";
import { getBlogs } from "../../../backend/getDataFromFirestore";
import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { ViewBlogCard } from "./BlogCard";
import { BlogHeader } from "./ViewBlogHeader";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    backgroundColor: "#000000",
    padding: "64px 90px 69px",
  },
  subHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerTitle: {
    fontWeight: "600",
    color: theme.palette.common.white,
  },
  headerSubTitle: {
    fontWeight: "300",
    color: theme.palette.common.white,
  },
  dataContainer: {
    padding: "31px 90px",
  },
  searchContainer: {
    paddingBottom: 30,
    width: 500,
    display: "flex",
    justifyContent: "space-between",
  },
}));

export const ViewBlogsComponent = () => {
  const classes = useStyles();

  const [blogs, setBlogs] = useState([]);
  const [filteredResult, setFilteredResult] = useState([]);
  // searching and sorting
  const [searchValue, setSearchValue] = useState("");
  const [sort, setSort] = useState("asc");

  useEffect(() => {
    fetchBlogs();
  }, []);

  function compareAB(a, b) {
    var nameA = a ? a.toString().toUpperCase() : ""; // ignore upper and lowercase
    var nameB = b ? b.toString().toUpperCase() : ""; // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  const searchLeadResult = (name, status) => {
    if (!blogs?.length) {
      return;
    }

    if (status === "asc" && !name) {
      return blogs?.sort((a, b) => compareAB(a["blogTitle"], b["blogTitle"]));
    } else if (status === "desc" && !name) {
      return blogs?.sort(
        (a, b) => -1 * compareAB(a["blogTitle"], b["blogTitle"])
      );
    }

    return blogs?.filter((pros) => {
      if (name) {
        return (
          pros?.blogTitle &&
          pros?.blogTitle.toLowerCase().includes(name.toLowerCase())
        );
      }
      return true;
    });
  };

  const fetchBlogs = async () => {
    await getBlogs(setBlogs, setFilteredResult);
  };

  const onSearch = (e) => {
    setSearchValue(e.target.value);
    setFilteredResult(searchLeadResult(e.target.value, sort));
  };

  const onSort = (e) => {
    setSort(e.target.value);
    setFilteredResult(searchLeadResult(searchValue, e.target.value));
  };

  return (
    <React.Fragment>
      <BlogHeader />
      <div className={classes.dataContainer}>
        <div className={classes.searchContainer}>
          <TextField
            size="small"
            style={{ width: 300, height: 38 }}
            label="Search"
            placeholder="Search by Blog name..."
            variant="outlined"
            value={searchValue}
            onChange={onSearch}
          />
          <FormControl
            size="small"
            variant="outlined"
            style={{ width: 180, height: 38 }}
          >
            <InputLabel>Sort By</InputLabel>
            <Select native label="Sort By" value={sort} onChange={onSort}>
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </Select>
          </FormControl>
        </div>
        <Grid container spacing={5}>
          {filteredResult?.length ? (
            filteredResult?.map((blog, index) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <ViewBlogCard blog={blog} />
                </Grid>
              );
            })
          ) : (
            <Typography variant="h4">Not found</Typography>
          )}
        </Grid>
      </div>
    </React.Fragment>
  );
};
