import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { INPUT_IMAGE_PATH } from "../../../common/config/configImage";

const useStyles = makeStyles({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "18px",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
  },
});

export const UploadReview = ({ handleClose, handleUpload }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.header}>
        <Typography style={{ fontWeight: "500" }} variant="h7">
          Upload Review Image
        </Typography>
        <Button onClick={handleClose}>
          <CloseIcon />
        </Button>
      </div>
      <div>
        <label>
          <img alt="upload Excel" src={INPUT_IMAGE_PATH.UPLOAD_EXCEL} />
          <input
            accept="image/*"
            name="google-review"
            type="file"
            style={{ display: "none" }}
            onChange={handleUpload}
          />
        </label>
      </div>
    </>
  );
};
