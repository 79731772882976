import { firestore } from "./firebase";

export const getAllCourseDetails = async (setCourseData) => {
  firestore.collection("Course").onSnapshot((querySnapshot) => {
    setCourseData(
      querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
    );
  });
};

export const getQuizData = async (setQuizData, id) => {
  firestore
    .collection("Course")
    .doc(id)
    .collection("Quiz")
    .onSnapshot((querySnapshot) => {
      setQuizData(
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    });
};

export const getAssignmentData = async (setAssignmentData, id) => {
  firestore
    .collection("Course")
    .doc(id)
    .collection("Assignment")
    .onSnapshot((querySnapshot) => {
      setAssignmentData(
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    });
};

export const getPptData = async (setPptData, id) => {
  firestore
    .collection("Course")
    .doc(id)
    .collection("PPT")
    .onSnapshot((querySnapshot) => {
      setPptData(
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    });
};

export const getVideoData = async (setVideoData, id) => {
  firestore
    .collection("Course")
    .doc(id)
    .collection("Video")
    .onSnapshot((querySnapshot) => {
      setVideoData(
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    });
};

export const getSyllabusData = async (setSyllabusData, id) => {
  firestore
    .collection("Course")
    .doc(id)
    .collection("Syllabus")
    .onSnapshot((querySnapshot) => {
      setSyllabusData(
        querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
      );
    });
};
