import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { CreateTrainer } from "./AddTrainer";

const useStyles = makeStyles({
  fullList: {
    padding: "20px",
  },
});

export default function AddNewTrainer({ state, setState, editTrainerData }) {
  const classes = useStyles();

  const handleDrawerClose = () => setState(false);
  const classe = `container mt-5 ${classes.fullList}`;

  return (
    <div>
      <React.Fragment key={"right"}>
        <SwipeableDrawer
          anchor={"right"}
          open={state}
          onClose={handleDrawerClose}
        >
          <div className={classe}>
            <CreateTrainer
              handleDrawerClose={handleDrawerClose}
              editTrainerData={editTrainerData}
            />
          </div>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
