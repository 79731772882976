import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ReminderHeader } from "../ReminderTabHeader";
import { TabMenu } from "../../../common/TabMenu/TabMenu";
import { REMINDER_TABS_CONFIG } from "../../../common/config/configURL";
import { DomainTable } from "./DomainTable";
import { getReminder } from "../../../backend/getDataFromFirestore";
import { REMINDER_COLLECTION_NAME } from "../../../common/const";
import { FormControl, InputLabel, Select, TextField } from "@material-ui/core";
import { AddProductButton } from "../../../common/Button";
import AddIcon from "@material-ui/icons/Add";
import { ReminderFormModal } from "../AddReminderModal";
import { AddMultipleReminderModal } from "../AddMultipleReminder";
import ExcelExport from "../Actions/ExcelDownload";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 1024,
    padding: "31px 61px 27px 57px",
    backgroundColor: "#CFCFCF",
  },
  searchContainer: {
    paddingBottom: 30,
    width: "75%",
    display: "flex",
    justifyContent: "space-between",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export const ViewDomain = () => {
  const classes = useStyles();
  const [domainList, setDomainList] = useState([]);
  const [filteredResult, setFilteredResult] = useState([]);
  // searching and sorting
  const [searchValue, setSearchValue] = useState("");
  const [sort, setSort] = useState("asc");
  const [isOpen, setOpen] = React.useState(false);
  const [isAddMultipleDomain, setAddMultipleDomain] = React.useState(false);

  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  useEffect(() => {
    getReminder(
      REMINDER_COLLECTION_NAME.DOMAIN,
      setDomainList,
      setFilteredResult
    );
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      const res = filterDataByDateRange(startDate, endDate);
      setFilteredResult(res ?? []);
    }
  }, [endDate, startDate]);

  const filterDataByDateRange = (startDate, endDate) => {
    const startDateTime = new Date(startDate).getTime();
    const endDateTime = new Date(endDate).getTime();

    const filteredData = domainList.filter((item) => {
      const expireDateTime = item.expireDate.seconds * 1000; // Convert seconds to milliseconds
      return expireDateTime >= startDateTime && expireDateTime <= endDateTime;
    });

    return filteredData;
  };

  const onSearch = (e) => {
    setSearchValue(e.target.value);
    setFilteredResult(searchLeadResult(e.target.value, sort));
  };

  const onSort = (e) => {
    setSort(e.target.value);
    setFilteredResult(searchLeadResult(searchValue, e.target.value));
  };

  function compareAB(a, b) {
    var nameA = a ? a.toString().toUpperCase() : ""; // ignore upper and lowercase
    var nameB = b ? b.toString().toUpperCase() : ""; // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  const searchLeadResult = (name, status) => {
    if (!domainList?.length) {
      return;
    }

    if (status === "asc" && !name) {
      return domainList?.sort((a, b) =>
        compareAB(a["domainName"], b["domainName"])
      );
    } else if (status === "desc" && !name) {
      return domainList?.sort(
        (a, b) => -1 * compareAB(a["domainName"], b["domainName"])
      );
    }

    return domainList?.filter((pros) => {
      if (name) {
        return (
          pros?.domainName &&
          pros?.domainName.toLowerCase().includes(name.toLowerCase())
        );
      }
      return true;
    });
  };

  const handleState = () => {
    setOpen((currentState) => !currentState);
  };

  const handleMultipleDomain = () => {
    setAddMultipleDomain((currentState) => !currentState);
  };

  return (
    <React.Fragment>
      <AddMultipleReminderModal
        isOpen={isAddMultipleDomain}
        openTab={"Domain"}
        toggleView={handleMultipleDomain}
      />
      <ReminderFormModal
        isOpen={isOpen}
        openTab={"Domain"}
        handleState={handleState}
      />
      <ReminderHeader />
      <TabMenu tabs={REMINDER_TABS_CONFIG} />
      <div className={classes.root}>
        <div className={classes.headerContainer}>
          <div className={classes.searchContainer}>
            <TextField
              size="small"
              style={{ width: 300, height: 38 }}
              label="Search"
              placeholder="Search by Domain name..."
              variant="outlined"
              value={searchValue}
              onChange={onSearch}
            />
            <FormControl
              size="small"
              variant="outlined"
              style={{ width: 180, height: 38 }}
            >
              <InputLabel>Sort By</InputLabel>
              <Select native label="Sort By" value={sort} onChange={onSort}>
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
              </Select>
            </FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                size="small"
                style={{ width: 190, height: 0 }}
                variant="inline"
                format="dd-MM-yyyy"
                label="Start Date"
                value={startDate}
                onChange={setStartDate}
                inputVariant="outlined"
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <KeyboardDatePicker
                size="small"
                style={{ width: 190, height: 0 }}
                variant="inline"
                format="dd/MM/yyyy"
                label="End Date"
                value={endDate}
                onChange={setEndDate}
                inputVariant="outlined"
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
        <div style={{ paddingBottom: 30 }}>
          <AddProductButton
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={handleMultipleDomain}
          >
            Add Bulk Domain
          </AddProductButton>
          <AddProductButton
            style={{ margin: "0px 10px" }}
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={handleState}
          >
            Add Domain
          </AddProductButton>
          <ExcelExport tabName="Domain" entriesData={filteredResult} />
        </div>
        <DomainTable domainList={filteredResult} />
      </div>
    </React.Fragment>
  );
};
