import React, { useState } from "react";
import { Divider, IconButton, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { PublishChanges } from "../../../../common/Button";
import { AddCourseList } from "./AddCourseList";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { editBatchCourse } from "../../../../backend/EditDataToFirebase";
import { successToastMessage } from "../../../../common/Toastify";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "6px 5px 0px 32px",
  },
  header: {
    fontSize: 27,
    fontWeight: "600",
    color: "#5F5F5F",
  },
  closeIcon: { fontSize: "30px" },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minWidth: 450,
    padding: "30px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
  },
  dataContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 30,
  },
}));

export const AddCourses = ({ handleClose, courses }) => {
  const classes = useStyles();
  const { id } = useParams();

  useEffect(() => {
    setFilteredCourses(courses);
  }, [courses]);

  const [searchValue, setSearchValue] = useState("");
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [toAddCourses, setToAddCourses] = useState([]);

  const searchLeadResult = (name) => {
    if (!courses?.length) {
      return;
    }
    return courses?.filter((pros) => {
      if (name) {
        return (
          pros?.courseTitle &&
          pros?.courseTitle.toLowerCase().includes(name.toLowerCase())
        );
      }
      return true;
    });
  };

  const onSearch = (e) => {
    setSearchValue(e.target.value);
    if (courses?.length) {
      setFilteredCourses(searchLeadResult(e.target.value));
    }
  };

  const handlerSubmitCourse = async () => {
    const res = await editBatchCourse(id, toAddCourses);
    if (res.successful) {
      handleClose();
      successToastMessage(res.message);
    }
  };

  return (
    <React.Fragment>
      <div className={classes.headerContainer}>
        <Typography className={classes.header}>Add Courses</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </div>
      <Divider />

      <div className={classes.content}>
        <div className={classes.dataContainer}>
          <TextField
            fullWidth
            size="small"
            style={{ height: 38 }}
            label="Search"
            placeholder="Search by course name..."
            variant="outlined"
            value={searchValue}
            onChange={onSearch}
          />
          <AddCourseList
            course={filteredCourses}
            setToAddCourses={setToAddCourses}
            toAddCourses={toAddCourses}
          />
        </div>
        <div className={classes.buttonContainer}>
          <PublishChanges endIcon={<AddIcon />} onClick={handlerSubmitCourse}   disabled={!courses.length}>
            Add
          </PublishChanges>
        </div>
      </div>
    </React.Fragment>
  );
};
