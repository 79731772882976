import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { FEE_RECIEPT_IMAGE_PATH } from "../common/config/configImage";
import prianka_sign from "../globals/assets/signature/Priyanka_Sign.png";

const useStyles = makeStyles({
  container: {
    position: "fixed",
    inset: 0,
    backgroundColor: "rgba(0,0,0,0.2)",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    zIndex: 99,
  },
  box: {
    backgroundColor: "#fff",
    width: "100%",
    maxWidth: "1300px",
    padding: "30px",
    overflow: "auto",
    // height: 1100,
    marginTop: 20,
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  logo: {
    width: "66px",
    height: "50px",
    objectFit: "cover",
    marginRight: "10px",
  },

  dateSections: {
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
    alignItems: "flex-end",
  },

  address: {
    color: "#7E7E7E",
    fontSize: 13,
  },
  tableContent: {
    marginTop: 30,
    width: "100%",
  },
  table: {
    border: "1px solid #ccc",
    width: "100%",
  },
  tableHeader: {
    border: "1px solid #ccc",
    background: "#F3B303",
    padding: "15px 0px !important",
    textAlign: "center",
  },
  tableData: {
    border: "1px solid #ccc",
    padding: "15px 0px !important",
    textAlign: "center",
  },
  amountBody: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 30,
  },
  paymentSection: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 30,
    alignItems: "flex-start",
  },
  signSection: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    padding: 10,
  },
  sign: {
    transform: "rotate(-90deg)",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  bgSign: {
    height: "100%",
  },
  headerLeft: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  addressBody: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  socialIcons: {
    display: "flex",
    gap: 20,
    alignItems: "center",
  },
  title: {
    color: "#F6B501",
  },
  commonFlex: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  student: {
    alignItems: "flex-end",
    marginTop: 20,
    display: "flex",
    justifyContent: "space-between",
  },
});

const Viewpdf = React.forwardRef((props, ref) => {
  const { loader, students, studentDetails } = props;

  const classes = useStyles();
  const date = new Date().toLocaleDateString();

  return (
    <>
      {loader ? (
        <div className={classes.container} ref={ref}>
          <div className={classes.box}>
            <div className={classes.header}>
              <div className={classes.headerLeft}>
                <img
                  src="/assets/rogue-logo.svg"
                  alt="logo"
                  className={classes.logo}
                />
                <div className={classes.addressBody}>
                  <Typography variant="h6" style={{ color: "#F6B501" }}>
                    Rogue Code Academy
                  </Typography>
                  <Typography className={classes.address}>
                    25, Dattani Trade Centre, near Borivali Station, Shanti
                    Nagar,
                  </Typography>
                  <Typography className={classes.address}>
                    Borivali West, Mumbai, Maharashtra 400092
                  </Typography>
                </div>
              </div>

              <div className={classes.socialIcons}>
                <img src={FEE_RECIEPT_IMAGE_PATH.LINKEDIN_LOGO} alt="" />
                <img src={FEE_RECIEPT_IMAGE_PATH.FACEBOOK_LOGO} alt="" />
                <img src={FEE_RECIEPT_IMAGE_PATH.INSTAGRAM_LOGO} alt="" />
                <img src={FEE_RECIEPT_IMAGE_PATH.YOUTUBE_LOGO} alt="" />
                <img src={FEE_RECIEPT_IMAGE_PATH.TWITTER_LOGO} alt="" />
              </div>
            </div>

            <div className={classes.student}>
              <div className={classes.commonFlex}>
                <Typography gutterBottom style={{ color: "#787878" }}>
                  Receipt To
                </Typography>
                <Typography style={{ color: "#000", fontWeight: 600 }}>
                  {studentDetails?.studentName}
                </Typography>

                <Typography style={{ color: "#000" }}>
                  {studentDetails?.address}
                </Typography>
                <Typography style={{ color: "#000" }}>
                  {studentDetails?.contactNumber}
                </Typography>
              </div>

              <div>
                <Typography style={{ color: "#000" }}>
                  Receipt Date: {date}
                </Typography>
                <Typography style={{ color: "#000" }}>
                  Receipt Number: 55255
                </Typography>
              </div>
            </div>

            <div className={classes.tableContent}>
              <table className={classes.table}>
                <thead>
                  <tr>
                    <th className={classes.tableHeader}>Sr.</th>
                    <th className={classes.tableHeader}>Course Name</th>
                    <th className={classes.tableHeader}>Couse Fees</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className={classes.tableData}>1</td>
                    <td className={classes.tableData}>
                      {students?.courseName}
                    </td>
                    <td className={classes.tableData}>
                      Rs. {students?.courseFee}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className={classes.amountBody}>
              <div className={classes.commonFlex}>
                <Typography className={classes.title}>
                  Total Amount: Rs. {students?.courseFee}
                </Typography>
                <Typography style={{ color: "#606060" }}>
                  Received: Rs. {students?.paidFee}
                </Typography>
                <Typography style={{ color: "#606060" }}>
                  Balance: Rs. {students?.courseFee - students?.paidFee}
                </Typography>
              </div>
            </div>

            <div className={classes.paymentSection}>
              <div className={classes.commonFlex}>
                <Typography
                  style={{ color: "#000", fontWeight: 600 }}
                  gutterBottom
                >
                  Transfer to
                </Typography>
                <Typography style={{ color: "#000", fontWeight: 500 }}>
                  UPI:- roguecode@hdfcbank
                </Typography>
                <Typography style={{ color: "#000", fontWeight: 500 }}>
                  A/C:- 50200053626256
                </Typography>
                <Typography style={{ color: "#000", fontWeight: 500 }}>
                  IFSC:- HDFC0000145
                </Typography>
                <Typography style={{ color: "#000", fontWeight: 500 }}>
                  Branch HDFC Bank-Borivali-West Branch, Mumbai
                </Typography>
              </div>
              <div>
                <Typography
                  style={{ color: "#000", fontWeight: 600 }}
                  gutterBottom
                >
                  Payment Info
                </Typography>
                <Typography>Payment method: {students?.paymentMode}</Typography>
              </div>
            </div>

            <div className={classes.signSection}>
              <div className={classes.bgSign}>
                <img
                  src={prianka_sign}
                  alt="signature"
                  height={50}
                  width={200}
                  style={{ objectFit: "cover" }}
                />
              </div>
              <Typography
                variant="h6"
                style={{ color: "#F6B501", fontWeight: 600 }}
              >
                Authorised Signatory
              </Typography>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
});

export default Viewpdf;
