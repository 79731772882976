import { firestore } from "./firebase";



export const getBlog = async (id) => {
    const collectionRef = await firestore.collection("Blogs").doc(id).get();
    if(!collectionRef.exists){
        return null
    }
    return collectionRef.data()
}