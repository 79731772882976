import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Notifications } from "./Notifications";
import { SidebarDrawer } from "./Sidebar/Sidebar";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Button, Popover, Typography } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import { CORE_ADD_STUDENT, CORE_LOGIN } from "../common/config/configURL";
import { ADMIN_EMAIL } from "../common/const";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#17191A",
    borderBottom: " 1px solid #333333",
    position: "relative",
    zIndex: 2,
  },
  Navbar: {
    backgroundColor: "#17191A",
    padding: "0 40px ",
    borderBottom: "1px solid #333333",
  },
  grow: {
    flexGrow: 1,
    position: "relative",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#303030",
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "420px",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#B3B3B3",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    padding: "10px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",

    [theme.breakpoints.up("md")]: {
      width: "100ch",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  bellIcon: {
    color: "#B3B3B3",
  },

  logOut: {
    padding: "15px 20px",
    display: "flex",
    flexDirection: "column",
    gap: 15,
    width: 150,
  },

  profileImg: {
    cursor: "pointer",
  },

  logoutOption: {
    display: "flex",
    gap: 15,
    color: "#B10000",
  },

  logoutOptionButton: {
    display: "flex",
    gap: 15,
    color: "#B10000",

    background: "transparent",
    border: "none",
    padding: 0,
  },

  settingText: {
    fontSize: 18,
    fontWeight: 600,
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isHideNavbar =
    location.pathname === CORE_ADD_STUDENT || location.pathname === CORE_LOGIN;

  const loginEmail = localStorage.getItem("rc-email");
  const isLogin = localStorage.getItem("isLogin");

  useEffect(() => {
    if (!isHideNavbar && (!isLogin || loginEmail !== ADMIN_EMAIL)) {
      navigate(CORE_LOGIN);
    }
  }, [location.pathname, loginEmail, isLogin, navigate, isHideNavbar]);

  const handleLogout = () => {
    setAnchorEl(null);
    localStorage.removeItem("rc-email");
    localStorage.removeItem("isLogin");
    navigate(CORE_LOGIN);
  };

  return (
    <>
      <div
        className={classes.container}
        style={{ display: isHideNavbar ? "none" : "" }}
      >
        <div className={classes.grow}>
          <AppBar position="fixed" className={classes.Navbar}>
            <Toolbar style={{ minHeight: 56 }}>
              <div className={classes.grow} />
              <Notifications open={open} setOpen={setOpen} />
              <div>
                <Profile
                  className={classes.profileImg}
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                >
                  <img src="/assets/user.svg" alt="Logo" />
                </Profile>
                <div>
                  <Popover
                    id={"profile"}
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <div className={classes.logOut}>
                      <div
                        className={classes.logoutOption}
                        style={{ color: "#3E3E3E" }}
                      >
                        <SettingsIcon />
                        <Typography className={classes.settingText}>
                          Setting
                        </Typography>
                      </div>
                      <button
                        className={classes.logoutOptionButton}
                        onClick={handleLogout}
                      >
                        <ExitToAppIcon />
                        <Typography className={classes.settingText}>
                          Log out
                        </Typography>
                      </button>
                    </div>
                  </Popover>
                </div>
              </div>
              <ArrowDropDownIcon className={classes.bellIcon} />
            </Toolbar>
          </AppBar>

          <SidebarDrawer />
        </div>
      </div>
    </>
  );
};

const Profile = styled.div`
  width: 34px;
  height: 34px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
`;

export default Navbar;
