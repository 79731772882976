import { Select, TextField, Typography } from "@material-ui/core";
import styled from "styled-components";

export const CourseCard = ({ course, handleChange }) => {
  const pendingFees =
    parseFloat(course?.courseFee) -
    parseFloat(course?.totalPaidFee) -
    (course?.paidFee ? parseFloat(course?.paidFee) : 0);

  return Object.keys(course).length ? (
    <Course>
      <CourseHeader>
        <div>
          <Typography>Course Name</Typography>
          <h4>{course?.courseName}</h4>
        </div>
        <div>
          <Typography>Total Fees</Typography>
          <h4>{course?.courseFee}</h4>
        </div>
        <div>
          <Typography>Pending Fees</Typography>
          <h4 style={{ color: "#F44336" }}>{pendingFees}</h4>
        </div>
      </CourseHeader>
      <PaySection>
        <FeesHeader>
          <div>
            <label htmlFor="amount">Amount to be Paid</label>
            <TextField
              variant="outlined"
              type="number"
              name="paidFee"
              value={course?.paidFee}
              placeholder="Enter Paid Fees"
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="type">Payment Type</label>
            <Select
              variant="outlined"
              native
              placeholder="Select Payment Type"
              name="paymentMode"
              value={course?.paymentMode}
              onChange={handleChange}
            >
              <option value="" />
              <option value={"Net Banking"}>Net Banking</option>
              <option value={"UPI"}>UPI</option>
              <option value={"Cash"}>Cash</option>
            </Select>
          </div>
          <div>
            <label htmlFor="account">Account Number</label>
            <TextField
              variant="outlined"
              name="accountNumber"
              value={course?.accountNumber}
              type="text"
              placeholder="Enter Account Number"
              onChange={handleChange}
            />
          </div>
        </FeesHeader>
      </PaySection>
    </Course>
  ) : null;
};

const Course = styled.div`
  margin-top: 10px;
  border-radius: 10px;
  border: 1px solid #b3b3b3;
`;

const CourseHeader = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 30px;
  border-bottom: 1px solid #cccccc;

  > div {
    h4 {
      font-size: 18px;
      font-weight: 600;
    }
  }
`;

const PaySection = styled.div`
  padding: 15px;
`;

const FeesHeader = styled(CourseHeader)`
  border-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 5px;
    label {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 5px;
    }
  }
`;
