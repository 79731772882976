import { makeStyles } from "@material-ui/core/styles";
import UPLOAD_PRODUCT from "../../../globals/assets/input-images/uploadProduct.svg";
import { errorToastMessage } from "../../../common/Toastify";
import React from "react";

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    margin: "26px 0px",
  },
}));

export const AddProductFiles = ({ formData, handleChangeForm }) => {
  const classes = useStyles();

  const isVideo = (fileType) => {
    if (fileType === "video/quicktime" || fileType === "video/mp4") {
      return true;
    } else return false;
  };

  return (
    <React.Fragment>
      <div className={classes.imageContainer}>
        <label>
          <img width={653} alt="upload product" src={UPLOAD_PRODUCT} />
          <input
            type="file"
            multiple
            accept=".png, .jpg, .jpeg, video/mp4, video/*"
            name="uploadProduct"
            style={{ display: "none" }}
            onChange={(e) => {
              if (e.target.files[0].size <= 3000000) {
                let images = formData?.productImage ?? [];
                images.push(e.target.files[0]);
                handleChangeForm("productImage", images);
              } else {
                errorToastMessage("File size should be upto 3mb only...");
              }
            }}
          />
        </label>
      </div>
      <div>
        {!!formData?.productImage?.length &&
          formData?.productImage?.map((file) => {
            return isVideo(file.type) ? (
              <video width="65" controls="controls" preload="metadata">
                <source
                  src={`${URL.createObjectURL(file)}#t=0.5`}
                  type="video/mp4"
                />
              </video>
            ) : (
              <img
                style={{ padding: "0px 10px" }}
                width={65}
                alt={file.name}
                src={URL.createObjectURL(file)}
              />
            );
          })}
      </div>
    </React.Fragment>
  );
};
