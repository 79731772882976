import React from "react";
import { TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { INPUT_IMAGE_PATH } from "../../../common/config/configImage";

const useStyles = makeStyles({
  title: {
    fontWeight: "600",
    marginLeft: 5,
  },
  titleContainer: {
    display: "flex",
    marginBottom: 11,
  },
  fieldContainer: {
    marginBottom: 24,
  },
  description: {
    "& .MuiOutlinedInput-root": {
      height: 122,
    },
  },
});

export const AddBatchForm = ({ formData, handleChange }) => {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.fieldContainer}>
        <div className={classes.titleContainer}>
          <img alt="blog_title" src={INPUT_IMAGE_PATH.BLOG_TITLE} />
          <Typography className={classes.title} variant="body1">
            Batch Title:
          </Typography>
        </div>
        <TextField
          size="small"
          style={{ height: 38 }}
          fullWidth
          variant="outlined"
          placeholder="Batch Title"
          value={formData.batchName}
          onChange={(e) => {
            handleChange("batchName", e?.target?.value);
          }}
        />
      </div>
      <div className={classes.fieldContainer}>
        <div className={classes.titleContainer}>
          <img alt="course_topic" src={INPUT_IMAGE_PATH.BLOG_TOPIC} />
          <Typography className={classes.title} variant="body1">
            Description:
          </Typography>
        </div>
        <TextField
          size="small"
          style={{ minHeight: 38 }}
          fullWidth
          variant="outlined"
          placeholder="Batch Description"
          // className={classes.description}
          value={formData.description}
          onChange={(e) => {
            handleChange("description", e?.target?.value);
          }}
        />
      </div>
      {/* <div className={classes.fieldContainer}>
        <div className={classes.titleContainer}>
          <img alt="blog_visibility" src={INPUT_IMAGE_PATH.BLOG_PEOPLE} />
          <Typography className={classes.title} variant="body1">
            Batch Picture:
          </Typography>
        </div>
        <input
          accept="image/*"
          type="file"
          onChange={(e) => handleChange("batchProfile", e.target.files[0])}
        />
        <p style={{ fontSize: 14, paddingTop: 3 }}>
          (Image should be. 300*300px)
        </p>
      </div> */}
    </div>
  );
};
