import React, { useState, useEffect } from "react";
import { BatchStudentsInfo } from "./BatchStudentsInfo";
import { getBatchStudentsDetails } from "../../../../backend/getDataFromFirestore";

export const ViewBatchStudents = ({ studentsIds }) => {
  const [studentsData, setStudentsData] = useState([]);

  useEffect(() => {
    const studentIds = [];
    for (let i = 0; i < studentsIds?.length; i++) {
      studentIds.push(studentsIds[i].trim());
    }

    handlerbatchStudents(studentIds);
  }, [studentsIds]);

  const handlerbatchStudents = async (studentIds) => {
    const res = await getBatchStudentsDetails(studentIds);
    setStudentsData(res);
  };

  return (
    <BatchStudentsInfo studentsData={studentsData} studentsIds={studentsIds} />
  );
};
