import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Typography } from "@material-ui/core";
import { AddProductButton, DeleteProduct } from "../../../common/Button";
import { WarningModal } from "../../../common/WarningModal";
import { deleteToast, errorToast } from "../../../common/Toastify";
import { deleteProduct } from "../../../backend/EditProductDetails";
import { useNavigate } from "react-router-dom";
import { AddProductModal } from "../AddProduct/AddProduct";

const useStyles = makeStyles((theme) => ({
  product: {
    padding: "0 30px",
    display: "flex",
    width: 1000,
  },

  highlited: {
    color: "#002E63",
  },
}));

const ProductHeader = ({ product }) => {
  const classes = useStyles();
  const [modalWarning, setModalWarning] = useState(false);
  const navigate = useNavigate();

  const handlerBack = () => {
    window.history.back();
  };

  const handlerDelete = (e) => {
    e.preventDefault();
    setModalWarning(true);
  };

  async function handlerConfirmDelete() {
    const res = await deleteProduct(product.docId);
    if (res.successfull) {
      setModalWarning(false);
      deleteToast("Product");
      window.history.back();
    } else {
      errorToast();
    }
  }

  return (
    <>
      <WarningModal
        open={modalWarning}
        setOpen={setModalWarning}
        message={"Product"}
        subMessage={"All Data will be deleted & wont be accessible anymore"}
        deleteItem={handlerConfirmDelete}
      />
      <div>
        <div className={classes.root}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p style={{ display: "flex", cursor: "pointer" }}>
              <ArrowBackIcon onClick={handlerBack} />
              <Typography style={{ marginLeft: 10 }}>
                {product.productCode}
              </Typography>
            </p>

            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
              <AddProductModal editProduct={true} productData={product} />

              <DeleteProduct onClick={handlerDelete}>Delete</DeleteProduct>
            </div>
          </div>

          <div className={classes.product}>
            <div style={{ display: "flex", gap: 30 }}>
              {product?.productImage && (
                <div style={{ background: "#fff" }}>
                  <img src={product?.productImage[0]} alt="" width={391} />
                </div>
              )}

              {product?.productImage?.length > 1 &&
                product?.productImage
                  ?.map((item, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 10,
                        }}
                      >
                        <div style={{ background: "#fff" }}>
                          <img src={item} alt="" width={72} />
                        </div>
                      </div>
                    );
                  })
                  .slice(1)}
            </div>

            <div style={{ marginLeft: 90 }}>
              <Typography
                variant="h5"
                style={{ fontWeight: 700, fontSize: 26 }}
                gutterBottom
              >
                {product?.productName}
              </Typography>
              <Typography variant="h6" gutterBottom>
                Discounted price:{" "}
                <span className={classes.highlited}>
                  Rs {product?.discountPrice}
                </span>
              </Typography>
              <Typography variant="h6" gutterBottom>
                MRP:{" "}
                <span className={classes.highlited}>
                  Rs. {product?.productMRP}
                </span>
              </Typography>
              <Typography variant="h6" gutterBottom>
                Category:{" "}
                <span className={classes.highlited}>
                  {" "}
                  {product?.productCategory}
                </span>
              </Typography>
              <Typography variant="h6" gutterBottom>
                No. of stock:{" "}
                <span className={classes.highlited}>{product.stock}</span>
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductHeader;
