import React from "react";
import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { PrimaryButton } from "../../../common/Button";
import { EventForm } from "./AddEventForm";
import { ModalHeader } from "../../../common/ModalHeader/ModalHeader";
import {
  getDownloadURL,
  uploadFileToStorage,
} from "../../../backend/UploadMedia";
import { createEvent } from "../../../backend/sendDataToFirestore";
import { successToastMessage } from "../../../common/Toastify";
import { editEvent } from "../../../backend/EditDataToFirebase";
import { EventDescription } from "./AddEventsDescription";
import ProductInfo1 from "../../../globals/assets/add-products/product_info_1.svg";
import Description1 from "../../../globals/assets/add-products/description_1.svg";
import ProductInfo2 from "../../../globals/assets/add-products/product_info_2.svg";
import Description2 from "../../../globals/assets/add-products/description_2.svg";

const useStyles = makeStyles({
  stepper: {
    display: "flex",
    alignItems: "center",
    height: 104,
  },
  buttonContainer: { display: "flex", justifyContent: "flex-end" },
  buttonContainer2: {
    position: "fixed",
    bottom: 50,
  },
  createButton: {
    position: "fixed",
    right: 38,
  },
});

export const ScheduleEvent = ({ handleDrawerState, eventDetails }) => {
  const classes = useStyles();
  const initialFormData = {
    eventName: "",
    eventDescription: "",
    longDescription: "",
    tags: [],
    formLink: "",
    eventPoster: "",
    eventDate: new Date(),
    registrationDate: new Date(),
    eventMode: "online",
    eventCategory: "",
    creationDate: new Date(),
  };
  const [formData, setFormData] = React.useState(initialFormData);
  const [active, setActive] = React.useState(0);

  React.useEffect(() => {
    console.log(eventDetails);
    if (eventDetails) {
      setFormData({
        ...eventDetails,
        eventDate: new Date(eventDetails?.eventDate?.seconds * 1000),
        registrationDate: new Date(eventDetails?.eventDate?.seconds * 1000),
      });
    }
  }, [eventDetails]);

  const handleChange = (name, value) => {
    setFormData({ ...formData, [`${name}`]: value });
  };

  const handleCreateEvent = async () => {
    const imageData = await handleUpload(formData.eventPoster);
    console.log("imageData", imageData);
    console.log("getUrl");
    const res = await createEvent({
      ...formData,
      eventPoster: imageData.url,
    });
    if (res.successful) {
      handleDrawerState();
      successToastMessage("Event has been created successfully");
    }
  };

  const handleEditEvent = async () => {
    const res = await editEvent(formData);
    if (res.successful) {
      handleDrawerState();
      successToastMessage("Event has been updated successfully");
    }
  };

  const handleUpload = async (file) => {
    console.log(typeof file);
    if (typeof file === "string") return { url: file };
    const id =
      Date.now().toString(36) + Math.random().toString(36).substring(2);
    console.log(id);
    const ref = `/event-documents/${id}`;
    const uploadTask = uploadFileToStorage(ref, file);
    const url = await getDownloadURL(uploadTask);
    return { url, ref };
  };

  return (
    <React.Fragment>
      <ModalHeader headerTitle={"Event"} handleClose={handleDrawerState} />
      <Divider />
      <div className={classes.stepper}>
        {active === 0 ? (
          <>
            <img alt={ProductInfo1} src={ProductInfo1} />
            <img alt={Description1} src={Description1} />
          </>
        ) : (
          <>
            <img alt={ProductInfo2} src={ProductInfo2} />
            <img alt={Description2} src={Description2} />
          </>
        )}
      </div>
      {active === 0 ? (
        <EventForm formData={formData} handleChange={handleChange} />
      ) : (
        <EventDescription formData={formData} handleChangeForm={handleChange} />
      )}
      {active === 0 ? (
        <div className={classes.buttonContainer}>
          <PrimaryButton
            className={classes.buttonContainer2}
            variant="contained"
            color="primary"
            onClick={() => setActive(1)}
          >
            Next
          </PrimaryButton>
        </div>
      ) : (
        <div className={classes.buttonContainer2}>
          <PrimaryButton variant="contained" onClick={() => setActive(0)}>
            Back
          </PrimaryButton>
          <PrimaryButton
            className={classes.createButton}
            variant="contained"
            color="primary"
            onClick={() =>
              formData.id ? handleEditEvent() : handleCreateEvent()
            }
          >
            {formData.id ? "Edit" : "Create"}
          </PrimaryButton>
        </div>
      )}
    </React.Fragment>
  );
};
