import { firebasee, firestore } from "./firebase";

export const editBlog = async (data) => {
  const collectionRef = firestore.collection("Blogs").doc(data?.docId);
  await collectionRef
    .update({
      ...data,
      updatedDate: new Date(),
    })
    .catch((e) => {
      return { successfull: false, message: "There is some Problem" };
    });
  return {
    successfull: true,
    message: "Data has been successfully created.",
  };
};

export async function deleteBlog(id) {
  const collectionRef = firestore.collection("Blogs").doc(id);

  await collectionRef.delete().catch((error) => {
    console.error("Error removing document: ", error);
    return { successfull: false, message: "There is some Problem" };
  });
  return {
    successfull: true,
    message: "Blog has been successfully deleted.",
  };
}

export const editStudent = async (data) => {
  const collectionRef = firestore.collection("Students").doc(data.id);
  await collectionRef
    .update({
      ...data,
    })
    .catch((e) => {
      console.log(e);
      return { successful: false, message: "There is some Problem" };
    });
  return {
    successful: true,
    message: "Updated user successfully",
  };
};

export async function deleteStudent(id) {
  firestore
    .collection("Students")
    .doc(id)
    .delete()
    .catch((error) => {
      console.error("Error removing document: ", error);
    });
}

export const editTrainer = async (data) => {
  const collectionRef = firestore.collection("Trainers").doc(data.id);
  await collectionRef
    .update({
      ...data,
    })
    .catch((e) => {
      console.log(e);
      return { successful: false, message: "There is some Problem" };
    });
  return {
    successful: true,
    message: "Data has been successfully created.",
  };
};

export async function deleteTrainer(id) {
  firestore
    .collection("Trainers")
    .doc(id)
    .delete()
    .catch((error) => {
      console.error("Error removing document: ", error);
    });
}

export async function addStudentFees(
  studentId,
  newReceipt,
  updatedCourseDetails
) {
  const collectionRef = firestore.collection("Students").doc(studentId);
  await collectionRef
    .update({
      feeReceipt: firebasee.FieldValue.arrayUnion(newReceipt),
      courseDetails: updatedCourseDetails,
    })
    .catch((e) => {
      console.log(e);
      return { successful: false, message: "There is some Problem" };
    });
  return {
    successful: true,
    message: "Data has been successfully created.",
  };
}

export async function addStudentReview(studentId, googleReview) {
  const collectionRef = firestore.collection("Students").doc(studentId);
  await collectionRef
    .update({
      googleReview,
    })
    .catch((e) => {
      console.log(e);
      return { successful: false, message: "There is some Problem" };
    });
  return {
    successful: true,
    message: "Data has been successfully created.",
  };
}

export const editEvent = async (data) => {
  const collectionRef = firestore.collection("Events").doc(data.id);
  await collectionRef
    .update({
      ...data,
    })
    .catch((e) => {
      console.log(e);
      return { successful: false, message: "There is some Problem" };
    });
  return {
    successful: true,
    message: "Data has been successfully created.",
  };
};

export async function deleteEvent(id) {
  const collectionRef = firestore.collection("Events").doc(id);

  await collectionRef.delete().catch((error) => {
    console.error("Error removing document: ", error);
    return { successfull: false, message: "There is some Problem" };
  });
  return {
    successfull: true,
    message: "Blog has been successfully deleted.",
  };
}

export async function deleteReminder(collectionName, id) {
  const collectionRef = firestore.collection(collectionName).doc(id);

  await collectionRef.delete().catch((error) => {
    console.error("Error removing document: ", error);
    return { successful: false, message: "There is some Problem" };
  });
  return {
    successful: true,
    message: "Blog has been successfully deleted.",
  };
}

export async function editReminder(collectionName, data) {
  const collectionRef = firestore.collection(collectionName).doc(data.id);
  await collectionRef
    .update({
      ...data,
    })
    .catch((e) => {
      console.log(e);
      return { successful: false, message: "There is some Problem" };
    });
  return {
    successful: true,
    message: "Reminder has been successfully Updated.",
  };
}

export const editBatchCourse = async (id, toAddCourses) => {
  const collectionRef = firestore.collection("Batch").doc(id);
  await collectionRef
    .update({
      batchCourses: firebasee.FieldValue.arrayUnion(...toAddCourses),
    })
    .catch((e) => {
      console.log(e);
      return { successful: false, message: "There is some Problem" };
    });
  return {
    successful: true,
    message: "Course has been successfully created.",
  };
};

export const editBatchStudents = async (id, toAddStudents) => {
  const collectionRef = await firestore
    .collection("Students")
    .where("docId", "in", toAddStudents)
    .get();

  for (let i = 0; i < collectionRef.docs.length; i++) {
    await collectionRef.docs[i].ref
      .update({
        batchIds: [id],
      })
      .catch((e) => {
        console.log(e);
        return { successful: false, message: "There is some Problem" };
      });
  }
  const batchRef = firestore.collection("Batch").doc(id);
  await batchRef
    .update({
      batchStudents: firebasee.FieldValue.arrayUnion(...toAddStudents),
    })
    .catch((e) => {
      console.log(e);
      return { successful: false, message: "There is some Problem" };
    });
  return {
    successful: true,
    message: "Student has been successfully created.",
  };
};

export const deleteBatch = async (batchId, batchStudentsIds = []) => {
  const batch = firestore.batch();
  if (batchStudentsIds?.length) {
    batchStudentsIds?.forEach((studentId) => {
      const studentRef = firestore.collection("Students").doc(studentId);
      batch.update(studentRef, {
        batchIds: firebasee.FieldValue.arrayRemove(batchId),
      });
    });
    batch.commit().catch((e) => {
      console.log(e);
      return { successful: false, message: "There is some Problem" };
    });
  }

  await firestore
    .collection("Batch")
    .doc(batchId)
    .delete()
    .catch((e) => {
      console.log(e);
      return { successful: false, message: "There is some Problem" };
    });

  return {
    successful: true,
    message: "Batch has been successfully deleted",
  };
};

export const deleteBatchCourse = async (id, courseId) => {
  const collectionRef = firestore.collection("Batch").doc(id);
  await collectionRef
    .update({
      batchCourses: firebasee.FieldValue.arrayRemove(courseId),
    })
    .catch((e) => {
      console.log(e);
      return { successful: false, message: "There is some Problem" };
    });
  return {
    successful: true,
    message: "Course has been successfully deleted",
  };
};

export const deleteBatchStudent = async (batchId, studentId) => {
  const batchCollectionRef = firestore.collection("Batch").doc(batchId);
  const studentCollectionRef = firestore.collection("Students").doc(studentId);
  await batchCollectionRef
    .update({
      batchStudents: firebasee.FieldValue.arrayRemove(studentId),
    })
    .catch((e) => {
      console.log(e);
      return { successful: false, message: "There is some Problem" };
    });

  await studentCollectionRef
    .update({
      batchIds: firebasee.FieldValue.arrayRemove(batchId),
    })
    .catch((e) => {
      console.log(e);
      return { successful: false, message: "There is some Problem" };
    });
  return {
    successful: true,
    message: "Students has been successfully deleted",
  };
};

export const editProduct = async (data) => {
  const collectionRef = firestore.collection("Products").doc(data?.docId);
  await collectionRef
    .update({
      ...data,
    })
    .catch((e) => {
      console.log(e);
      return { successful: false, message: "There is some Problem" };
    });
  return {
    successful: true,
    message: "Product has been successfully added.",
  };
};

export const updateBatch = async (data) => {
  const collectionRef = firestore.collection("Batch").doc(data?.docId);
  await collectionRef
    .update({
      ...data,
    })
    .catch((e) => {
      console.log(e);
      return { successfull: false, message: "There is some Problem" };
    });
  return {
    successfull: true,
    message: "Batch has been successfully updated.",
  };
};
