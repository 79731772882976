import React from "react";
import { addNewProduct } from "../../../backend/sendDataToFirestore";
import { PrimaryButton } from "../../../common/Button";
import { makeStyles } from "@material-ui/core/styles";
import {
  errorToastMessage,
  successToastMessage,
} from "../../../common/Toastify";
import {
  getDownloadURL,
  uploadFileToStorage,
} from "../../../backend/UploadMedia";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useState } from "react";
import { editProduct } from "../../../backend/EditDataToFirebase";

const useStyles = makeStyles({
  buttonContainer1: { right: 60, position: "fixed", bottom: 50 },
  buttonContainer2: {
    position: "fixed",
    bottom: 50,
  },
});

export const FormFooter = ({
  active,
  setActive,
  formData,
  handleClose,
  handleResetForm,
  editProductData,
}) => {
  const classes = useStyles();

  const [showSpinner, setShowSpinner] = useState(false);

  const isFormValidate = () => {
    if (
      !formData.productImage.length ||
      !formData.productName ||
      !formData.productCode ||
      !formData.productCategory ||
      !formData.productMRP ||
      !formData.discountPrice ||
      !formData.stock ||
      !formData.description
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleUpload = async (file) => {
    if (typeof file === "string") return { url: file };
    const id =
      Date.now().toString(36) + Math.random().toString(36).substring(2);
    console.log(id);
    const ref = `/products-documents/${id}`;
    const uploadTask = uploadFileToStorage(ref, file);
    const url = await getDownloadURL(uploadTask);
    return url;
  };

  const handleCreateProduct = async () => {
    try {
      if (isFormValidate()) {
        setShowSpinner(true);
        let res;
        const imagesLink = [];
        for (let image of formData.productImage) {
          const url = await handleUpload(image);
          imagesLink.push(url);
        }
        if (!editProductData?.docId) {
          res = await addNewProduct({
            ...formData,
            productImage: imagesLink,
          });
        } else {
          res = await editProduct({
            ...formData,
          });
        }
        if (res.successful) {
          handleResetForm();
          successToastMessage(res.message);
          handleClose();
        } else {
          errorToastMessage("There is something wrong");
        }
        setShowSpinner(false);
      } else {
        errorToastMessage("Please fill all fields");
      }
    } catch (error) {}
  };

  return (
    <React.Fragment>
      {active === 1 ? (
        <PrimaryButton
          className={classes.buttonContainer1}
          variant="contained"
          color="primary"
          onClick={() => setActive(2)}
        >
          Next
        </PrimaryButton>
      ) : null}
      {active === 2 ? (
        <div className={classes.buttonContainer2}>
          <PrimaryButton variant="contained" onClick={() => setActive(1)}>
            Back
          </PrimaryButton>
          <PrimaryButton
            style={{ left: 261 }}
            variant="contained"
            color="primary"
            onClick={handleCreateProduct}
            disabled={showSpinner}
            endIcon={
              showSpinner ? <CircularProgress color="secondary" /> : null
            }
          >
            {!showSpinner && !editProductData?.docId
              ? "Add"
              : !showSpinner && editProductData?.docId
              ? "Edit"
              : null}
          </PrimaryButton>
        </div>
      ) : null}
    </React.Fragment>
  );
};
