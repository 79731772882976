import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { PrimaryButton } from "../../../common/Button";
import { ReactComponent as BatchIcon } from "../../../globals/assets/batch/batch-prediction.svg";
import { BatchModal } from "../AddBatch/AddNewBatch";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    backgroundColor: "#17191A",
    padding: "30px 90px",
    borderBottom: "1px solid #333333",
  },
  subHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerTitle: {
    color: theme.palette.common.white,
  },
  headerSubTitle: {
    fontWeight: "300",
    color: theme.palette.common.white,
    fontSize: 20,
  },
}));

export const BatchHeader = () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleState = () => {
    setOpen((currentState) => !currentState);
  };

  const handleModal = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <BatchModal open={open} handleModal={handleModal} />
      <div className={classes.headerContainer}>
        <div className={classes.subHeaderContainer}>
          <Typography variant="h5" className={classes.headerTitle}>
            Batch
          </Typography>
          <PrimaryButton
            startIcon={<BatchIcon />}
            variant="contained"
            color="primary"
            onClick={handleState}
          >
            Create Batch
          </PrimaryButton>
        </div>
        <Typography variant="h5" className={classes.headerSubTitle}>
          Batch for all students and for there material.
        </Typography>
      </div>
    </React.Fragment>
  );
};
