export function isWithinTwoMonths(dateObject) {
  if (!dateObject) return false;

  const providedDate = new Date(
    dateObject.seconds * 1000 + dateObject.nanoseconds / 1e6
  );

  const currentDate = new Date();

  if (providedDate < currentDate) {
    return true;
  }
  const differenceInMillis = currentDate - providedDate;
  const differenceInMonths = Math.abs(
    differenceInMillis / (30 * 24 * 60 * 60 * 1000)
  );

  return differenceInMonths <= 2 ? true : false;
}
