import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Paper, Typography } from "@material-ui/core";
import { formatRawDate } from "../../../common/const";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    gap: 7,
    marginBottom: 10,
    "& span": {
      color: "gray"
    }
  },
}));

const OrderDetails = ({ order, setOrder }) => {
  const classes = useStyles();

  console.log(order);

  const {
    contactNo,
    name,
    status,
    orderDate,
    email,
    products = [],
    address,
    orderId,
  } = order;

  const handleClose = () => {
    setOrder(null);
  };

  return (
    <Modal
      className={classes.modal}
      open={!!order}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={!!order}>
        <div className={classes.paper}>
          <div className={classes.wrapper}>
            <Typography>
              Order Id: <span>{orderId}</span>
            </Typography>
            <Typography>
              Name: <span>{name}</span>
            </Typography>
            <Typography>
              Email: <span>{email}</span>
            </Typography>
            <Typography>
              Address: <span>{address}</span>
            </Typography>
            <Typography>
              Contact No: <span>{contactNo}</span>
            </Typography>
            <Typography>
              Status: <span>{status}</span>
            </Typography>
            <Typography>
              Ordered Date: <span>{formatRawDate(orderDate)}</span>
            </Typography>
          </div>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Product Code</TableCell>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Product Category</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {products?.map((product) => (
                  <TableRow key={product.docId}>
                    <TableCell>
                      <img
                        height={50}
                        alt={product.productName}
                        src={product?.productImage[0]}
                      />
                    </TableCell>
                    <TableCell>{product.productCode}</TableCell>
                    <TableCell>{product.productName}</TableCell>
                    <TableCell>{product.discountPrice}</TableCell>
                    <TableCell>{product.productCategory}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Fade>
    </Modal>
  );
};

export default OrderDetails;
