import { SIDEBAR_ROUTES_IMAGE_PATH } from "./config/configImage";
import {
  CORE_BLOGS,
  CORE_DASHBOARD,
  CORE_REMINDER_DOMAIN,
  CORE_SHOP_OVERVIEW,
  CORE_COURSE,
  CORE_BATCH,
} from "./config/configURL";

export const BLOG_KEYWORDS = [
  "IT",
  "AI/ML",
  "React",
  "JS",
  "Angular",
  "Marketing",
];

export const EVENT_KEYWORDS = [
  "IT",
  "AI/ML",
  "React",
  "JS",
  "Angular",
  "Marketing",
];

export const SIDEBAR_TABS = [
  {
    title: "Dashboard",
    icon: SIDEBAR_ROUTES_IMAGE_PATH.DASHBOARD,
    path: CORE_DASHBOARD,
    includeParams: "/dashboard",
  },
  {
    title: "Subject",
    icon: SIDEBAR_ROUTES_IMAGE_PATH.COURSE,
    path: CORE_COURSE,
    includeParams: "/course",
  },
  {
    title: "Batch",
    icon: SIDEBAR_ROUTES_IMAGE_PATH.BATCH,
    path: CORE_BATCH,
    includeParams: "/batch",
  },
  {
    title: "Marketing",
    icon: SIDEBAR_ROUTES_IMAGE_PATH.MARKETING,
    path: CORE_BLOGS,
    includeParams: "/marketing",
  },
  {
    title: "Shop",
    icon: SIDEBAR_ROUTES_IMAGE_PATH.SHOP,
    path: CORE_SHOP_OVERVIEW,
    includeParams: "/shop",
  },
  {
    title: "Reminder",
    icon: SIDEBAR_ROUTES_IMAGE_PATH.REMINDER,
    path: CORE_REMINDER_DOMAIN,
    includeParams: "/reminder",
  },
];

export const SAMPLE_UPLOAD_STUDENTS_URL =
  "https://firebasestorage.googleapis.com/v0/b/rogue-code-portal.appspot.com/o/student-bulk-upload-sample%2FMultiple%20Students%20Data%20Sample.xlsx?alt=media&token=0e6a15f7-7ee0-4ec5-877b-91e5b09d2d7d";

export const BLOOD_GROUP = [
  {
    id: 1,
    group: "A+",
  },
  {
    id: 2,
    group: "A-",
  },
  {
    id: 3,
    group: "B+",
  },
  {
    id: 4,
    group: "B-",
  },

  {
    id: 5,
    group: "AB+",
  },
  {
    id: 6,
    group: "AB-",
  },
  {
    id: 7,
    group: "O+",
  },
  {
    id: 8,
    group: "O-",
  },
];

export const REMINDER_COLLECTION_NAME = {
  DOMAIN: "DomainReminder",
  SSL: "SslReminder",
  HOST: "HostReminder",
};

export const formatDate = (rowDate) => {
  const date = new Date(rowDate?.seconds * 1000);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

export const ADMIN_EMAIL = "roguecode@gmail.com";
export const ADMIN_PASSWORD = "12345678";

export function formatRawDate(duration) {
  const durationInMilliseconds =
    duration.seconds * 1000 + Math.round(duration.nanoseconds / 1e6);
  const referenceDate = new Date(0); // January 1, 1970
  const resultDate = new Date(referenceDate.getTime() + durationInMilliseconds);

  const day = resultDate.getDate();
  const month = resultDate.getMonth() + 1; // Month is zero-based
  const year = resultDate.getFullYear();

  return `${day}/${month}/${year}`;
}
const DISPLAY_SIZE = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};

export const DEVICE = {
  mobileS: `(max-width: ${DISPLAY_SIZE.mobileS})`,
  mobileM: `(max-width: ${DISPLAY_SIZE.mobileM})`,
  mobileL: `(max-width: ${DISPLAY_SIZE.mobileL})`,
  tablet: `(max-width: ${DISPLAY_SIZE.tablet})`,
  laptop: `(max-width: ${DISPLAY_SIZE.laptop})`,
  laptopL: `(max-width: ${DISPLAY_SIZE.laptopL})`,
  desktop: `(max-width: ${DISPLAY_SIZE.desktop})`,
};
