import { useEffect } from "react";
import { BatchCourseInfo } from "./BatchCourseInfo";
import { getBatchCourseDetails } from "../../../../backend/getDataFromFirestore";
import { useState } from "react";

export const ViewBatchCourses = ({ courseIds }) => {
  const [batchCourses, setBatchCourses] = useState([]);
  

  useEffect(() => {
    handleBatchCourse(courseIds);
  }, [courseIds]);

  const handleBatchCourse = async (courseIds) => {
    const res = await getBatchCourseDetails(courseIds);
    setBatchCourses(res);
  };

  return <BatchCourseInfo courses={batchCourses} courseIds={courseIds} />;
};
