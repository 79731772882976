export const DOMAIN_LOG_CSV_Config = [
  {
    key: "domainName",
    label: "Domain Name",
    hidden: false,
  },
  {
    key: "vendorName",
    label: "Vendor Name",
    hidden: false,
  },
  {
    key: "clientName",
    label: "Client Name",
    hidden: false,
  },
  {
    key: "clientContact",
    label: "Client Contact",
    hidden: false,
  },
  {
    key: "purchaseDate",
    label: "Date of Purchase",
    hidden: false,
  },
  {
    key: "expireDate",
    label: "Date of Expire",
    hidden: false,
  },
  {
    key: "price",
    label: "Price",
    hidden: false,
  },
];

export const SSL_LOG_CSV_Config = [
  {
    key: "sslName",
    label: "SSL Name",
    hidden: false,
  },
  {
    key: "vendorName",
    label: "Vendor Name",
    hidden: false,
  },
  {
    key: "clientName",
    label: "Client Name",
    hidden: false,
  },
  {
    key: "clientContact",
    label: "Client Contact",
    hidden: false,
  },
  {
    key: "purchaseDate",
    label: "Date of Purchase",
    hidden: false,
  },
  {
    key: "expireDate",
    label: "Date of Expire",
    hidden: false,
  },
  {
    key: "price",
    label: "Price",
    hidden: false,
  },
];

export const HOST_LOG_CSV_Config = [
  {
    key: "hostName",
    label: "Host Name",
    hidden: false,
  },
  {
    key: "vendorName",
    label: "Vendor Name",
    hidden: false,
  },
  {
    key: "clientName",
    label: "Client Name",
    hidden: false,
  },
  {
    key: "clientContact",
    label: "Client Contact",
    hidden: false,
  },
  {
    key: "purchaseDate",
    label: "Date of Purchase",
    hidden: false,
  },
  {
    key: "expireDate",
    label: "Date of Expire",
    hidden: false,
  },
  {
    key: "price",
    label: "Price",
    hidden: false,
  },
];

export const EXPORT_STUDENTS_CSV_Config = [
  {
    key: "uniqueId",
    label: "Id",
    hidden: false,
  },
  {
    key: "studentName",
    label: "Student Name",
    hidden: false,
  },
  {
    key: "parentsContactNumber",
    label: "Parents Contact No.",
    hidden: false,
  },
  {
    key: "email",
    label: "Email Address",
    hidden: false,
  },
  {
    key: "contactNumber",
    label: "Contact No.",
    hidden: false,
  },
  {
    key: "emergencyContactName",
    label: "Emergency Contact Name",
    hidden: false,
  },
  {
    key: "bloodGroup",
    label: "Blood Group",
    hidden: false,
  },
  {
    key: "address",
    label: "Address",
    hidden: false,
  },
  {
    key: "reference",
    label: "Reference",
    hidden: false,
  },
  {
    key: "joinDate",
    label: "Join Date",
    hidden: false,
  },
  {
    key: "collegeName",
    label: "School/College Name",
    hidden: false,
  },
  {
    key: "boardName",
    label: "Board Name",
    hidden: false,
  },
  {
    key: "dob",
    label: "D.O.B",
    hidden: false,
  },
  {
    key: "emergencyContactNumber",
    label: "Emergency Contact No.",
    hidden: false,
  },
];

export const EXPORT_TRAINERS_CSV_Config = [
  {
    key: "uniqueId",
    label: "Id",
    hidden: false,
  },
  {
    key: "trainerName",
    label: "Trainer Name",
    hidden: false,
  },
  {
    key: "address",
    label: "Address",
    hidden: false,
  },
  {
    key: "availability",
    label: "Availability",
    hidden: false,
  },
  {
    key: "contactNumber",
    label: "Contact No.",
    hidden: false,
  },
  {
    key: "dob",
    label: "D.O.B",
    hidden: false,
  },
  {
    key: "emailId",
    label: "Email Address",
    hidden: false,
  },
  {
    key: "experience",
    label: "Experience",
    hidden: false,
  },

  {
    key: "preferredMode",
    label: "Preferred Mode",
    hidden: false,
  },
  {
    key: "subject",
    label: "Subject",
    hidden: true,
  },
];
