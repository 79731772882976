import React, { useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import { Actions } from "./BlogAction";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { WarningModal } from "../../../common/WarningModal";
import { deleteBlog } from "../../../backend/EditDataToFirebase";
import { deleteToast, errorToast } from "../../../common/Toastify";
import { OpenBlogModal } from "../AddBlog/AddNewBlog";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 300,
    borderRadius: 16,
    boxShadow: "0px 3.4721407890319824px 6.944281578063965px 0px #00000033",
    backgroundColor: "#FFF",
    overflow: "hidden",
    height: 353,
  },
  media: {
    objectFit: "cover",
    height: 259,
    width: "100%",
  },
  blogImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  content: {
    padding: "6px 13px 0px 11px",
    marginBottom: 6,
    overflow: "hidden",
    height: 50,
  },
  blogTitle: {
    fontWeight: "600",
    color: "#000",
  },
  desc: {
    fontSize: 12,
    color: "#666666",
  },
  footer: {
    height: 33,
    padding: "0px 13px 0px 11px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  leftFooter: { display: "flex", alignItems: "center" },
  date: {
    fontSize: 13,
    marginLeft: 3,
    color: "#000",
  },
}));

export const ViewBlogCard = ({ blog }) => {
  const classes = useStyles();
  const [openAddBlog, setOpenAddBlog] = React.useState(false);
  const [openWarning, setOpenWarning] = React.useState(false);
  const textRef = useRef();
  useEffect(() => {
    textRef.current.innerHTML = blog?.blogDescription;
  }, [blog]);

  const formatDate = (blogDate) => {
    let updatedDate = new Date(blogDate?.seconds * 1000);
    updatedDate = updatedDate.toLocaleDateString("en-US", {
      month: "short",
      year: "numeric",
      day: "numeric",
    });
    return updatedDate;
  };

  const onEdit = (data) => {
    setOpenAddBlog(true);
  };

  const onDelete = async (data) => {
    setOpenWarning(true);
  };

  async function handleConfirmDelete() {
    const res = await deleteBlog(blog.id);
    if (res.successfull) {
      setOpenWarning(false);
      deleteToast("Blog");
    } else {
      errorToast();
    }
  }

  return (
    <React.Fragment>
      <OpenBlogModal
        open={openAddBlog}
        setOpen={setOpenAddBlog}
        blogDetails={blog}
      />
      <WarningModal
        open={openWarning}
        setOpen={setOpenWarning}
        message={"Blog"}
        subMessage={"All Data will be deleted & wont be accessible anymore"}
        deleteItem={handleConfirmDelete}
      />

      <Card className={classes.root}>
        <Link to={`/view-blog/${blog.id}`}>
          <div className={classes.media}>
            <img src={blog?.blogImage} alt="" className={classes.media} />
          </div>
          <div className={classes.content}>
            <Typography variant="subtitle1" className={classes.blogTitle}>
              {blog?.blogTitle.slice(0, 30)}...
            </Typography>
            <p className={classes.desc} ref={textRef}></p>
          </div>
        </Link>
        <Divider />
        <div className={classes.footer}>
          <div className={classes.leftFooter}>
            <AccessTimeIcon style={{ fontSize: "16px", color: "#666" }} />
            <Typography className={classes.date} variant="body2">
              {formatDate(blog?.updatedDate)}
            </Typography>
          </div>
          <div>
            <Actions data={blog} onEdit={onEdit} onDelete={onDelete} />
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
};
