import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { createStudent } from "../../backend/sendDataToFirestore";
import swal from "sweetalert";

const useStyles = makeStyles({
  header: {
    margin: "20px 0px",
  },
  table: {
    minWidth: 650,
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "40px 0px 0px",
  },
});

export const StudentsTable = ({
  studentsData,
  setStudentData,
  handleClose,
}) => {
  const classes = useStyles();
  const [showSpinner, setShowSpinner] = React.useState(false);

  const handleSubmit = async () => {
    setShowSpinner(true);
    const res = await changeDataKeys();
    try {
      for (const student of res) {
        await createStudent(student);
      }
      setShowSpinner(false);
      swal("Success!!!", "Students Created Successfully", "success").then(
        () => {
          setStudentData([]);
          handleClose();
        }
      );
    } catch (error) {
      setShowSpinner(false);
      alert(error);
    }
  };

  const changeDataKeys = () => {
    const data = studentsData.map((student) => {
      return {
        profilePhoto: "",
        studentName: student["Student Name"],
        email: student["Email Address"],
        contactNumber: "+91" + JSON.stringify(student["Contact No."]),
        parentsContactNumber: student["Parents Contact No."],
        address: student["Address"],
        dob: codeCourseDate(student["D.O.B"]),
        collegeName: student["School/College Name"],
        boardName: student["Board Name"],
        bloodGroup: student["Blood Group"],
        reference: student["Reference"],
        emergencyContactName: student["Emergency Contact Name"],
        emergencyContactNumber: student["Emergency Contact No."],
        feeReceipt: [],
        courseDetails: handleCourseData(student),
        joinDate: new Date(),
      };
    });
    return data;
  };

  const handleCourseData = (student) => {
    const data = student?.["Course Name"]?.map((data, index) => {
      return {
        courseName: student?.["Course Name"][index],
        courseDuration: codeCourseDate(student?.["Course Duration"][index]),
        courseFee: parseFloat(student?.["Course Fee"][index]),
        totalPaidFee: 0,
      };
    });
    return data;
  };

  const codeCourseDate = (date) => {
    let rowDate = date?.split("/");
    return new Date(rowDate[2], rowDate[1] - 1, rowDate[0]);
  };

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h7">Preview of Uploaded Data</Typography>
      </div>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="sticky table" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ minWidth: "9em" }}>Student Name</TableCell>
              <TableCell style={{ minWidth: "9em" }}>Email Address</TableCell>
              <TableCell style={{ minWidth: "9em" }}>Contact No.</TableCell>
              <TableCell style={{ minWidth: "9em" }}>
                Parents Contact No.
              </TableCell>
              <TableCell style={{ minWidth: "9em" }}>Address</TableCell>
              <TableCell style={{ minWidth: "9em" }}>D.O.B</TableCell>
              <TableCell style={{ minWidth: "9em" }}>
                School/College Name
              </TableCell>
              <TableCell style={{ minWidth: "9em" }}>Board Name</TableCell>
              <TableCell style={{ minWidth: "9em" }}>Blood Group</TableCell>
              <TableCell style={{ minWidth: "9em" }}>Reference</TableCell>
              <TableCell style={{ minWidth: "9em" }}>
                Emergency Contact Name
              </TableCell>
              <TableCell style={{ minWidth: "9em" }}>
                Emergency Contact No.
              </TableCell>
              <TableCell style={{ minWidth: "9em" }}>Course Name</TableCell>
              <TableCell style={{ minWidth: "9em" }}>Course Duration</TableCell>
              <TableCell style={{ minWidth: "9em" }}>Course Fee</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studentsData?.length
              ? studentsData?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {row["Student Name"]}
                    </TableCell>{" "}
                    <TableCell>{row["Email Address"]}</TableCell>
                    <TableCell>{row["Contact No."]}</TableCell>
                    <TableCell>{row["Parents Contact No."]}</TableCell>
                    <TableCell>{row["Address"]}</TableCell>
                    <TableCell>{row["D.O.B"]}</TableCell>
                    <TableCell>{row["School/College Name"]}</TableCell>
                    <TableCell>{row["Board Name"]}</TableCell>
                    <TableCell>{row["Blood Group"]}</TableCell>
                    <TableCell>{row["Reference"]}</TableCell>
                    <TableCell>{row["Emergency Contact Name"]}</TableCell>
                    <TableCell>{row["Emergency Contact No."]}</TableCell>
                    <TableCell>{row["Course Name"]}</TableCell>
                    <TableCell>{row["Course Duration"]}</TableCell>
                    <TableCell>{row["Course Fee"]}</TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.footer}>
        {showSpinner && (
          <CircularProgress
            style={{
              color: "#18D26E",
            }}
          />
        )}
        <Button
          disabled={studentsData.length === 0}
          variant="contained"
          onClick={handleSubmit}
        >
          Bulk Upload
        </Button>
      </div>
    </>
  );
};
