import React from "react";
import { TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { INPUT_IMAGE_PATH } from "../../../common/config/configImage";
import { BLOG_KEYWORDS } from "../../../common/const";

const useStyles = makeStyles({
  title: {
    fontWeight: "600",
    marginLeft: 5,
  },
  titleContainer: {
    display: "flex",
    marginBottom: 11,
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
    height: 170,
    marginTop: 30,
  },
  fieldContainer: {
    marginBottom: 24,
    "& .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']":
    {
      height: "100%",
    },
  },
  textfield: {
    "& >div": {
      height: "100%"
    }
  }
});

export const AddBlogDetails = ({ formData, handleChange }) => {
  const classes = useStyles();
  const refData = React.useRef();

  return (
    <React.Fragment>
      <div className={classes.fieldContainer}>
        <div className={classes.titleContainer}>
          <img alt="blog_title" src={INPUT_IMAGE_PATH.BLOG_TITLE} />
          <Typography className={classes.title} variant="body1">
            Blog Title:
          </Typography>
        </div>
        <TextField
          size="small"
          style={{ height: 38 }}
          fullWidth
          variant="outlined"
          placeholder="Blog Title"
          value={formData.blogTitle}
          onChange={(e) => {
            handleChange("blogTitle", e?.target?.value);
          }}
        />
      </div>
      <div className={classes.fieldContainer}>
        <div className={classes.titleContainer}>
          <img alt="blog_topic" src={INPUT_IMAGE_PATH.BLOG_TOPIC} />
          <Typography className={classes.title} variant="body1">
            Blog Topic:
          </Typography>
        </div>
        <Autocomplete
          style={{ width: 298 }}
          freeSolo
          multiple
          options={BLOG_KEYWORDS}
          ref={refData}
          name="topics"
          value={formData.blogKeywords}
          getOptionLabel={(option) => option}
          onChange={(e, value) => {
            handleChange("blogKeywords", value);
          }}
          renderInput={(params) => (
            <TextField
              className={classes.textfield}
              size="small"
              {...params}
              name="topics"
              variant="outlined"
              placeholder="Blog Topics"
            />
          )}
          size="small"
        />
      </div>

      <div className={classes.fieldContainer}>
        <div className={classes.titleContainer}>
          <img alt="blog_title" src={INPUT_IMAGE_PATH.BLOG_AUTHOR} />
          <Typography className={classes.title} variant="body1">
            Blog Author:
          </Typography>
        </div>
        <TextField
          size="small"
          style={{ height: 38 }}
          fullWidth
          variant="outlined"
          placeholder="Blog Author"
          value={formData.blogAuthor}
          onChange={(e) => {
            handleChange("blogAuthor", e?.target?.value);
          }}
        />
      </div>

      <div className={classes.fieldContainer}>
        <div className={classes.titleContainer}>
          <img alt="blog_visibility" src={INPUT_IMAGE_PATH.BLOG_PEOPLE} />
          <Typography className={classes.title} variant="body1">
            Blog Image:
          </Typography>
        </div>
        {!formData.id ? (
          <>
            <input
              accept="image/*"
              type="file"
              onChange={(e) => handleChange("blogImage", e.target.files[0])}
            />
            <Typography
              style={{
                fontSize: 11,
                fontWeight: 300,
                lineHeight: "24px",
              }}
            >
              Image should be 1500*1000
            </Typography>
          </>
        ) : null}
      </div>
      {formData?.blogImage ? (
        <div className={classes.imageContainer}>
          <img
            alt="blog_image"
            src={
              !formData.id
                ? URL.createObjectURL(formData?.blogImage)
                : formData?.blogImage
            }
          />
        </div>
      ) : null}
    </React.Fragment>
  );
};
