import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { Divider } from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import parse from "html-react-parser";
import { deleteEvent } from "../../../backend/EditDataToFirebase";
import { deleteToast, errorToast } from "../../../common/Toastify";
import { WarningModal } from "../../../common/WarningModal";
import { Actions } from "../../../common/ActionColumn/ActionColS";
import { ScheduleEventModal } from "../AddEvent/AddEventModal";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 259,
    height: 353,
    borderRadius: 16,
    boxShadow: "0px 3.4721407890319824px 6.944281578063965px 0px #00000033",
  },
  media: {
    height: 250,
    paddingTop: "56.25%", // 16:9
  },
  content: {
    height: 63,
    padding: "6px 13px 0px 11px",
    marginBottom: 6,
    overflow: "hidden",
  },
  blogTitle: { fontWeight: "600", color: "#41423F" },
  desc: {
    fontSize: 12,
    lineHeight: "12px",
    fontWeight: "300",
  },
  footer: {
    height: 33,
    padding: "0px 13px 0px 11px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  leftFooter: { display: "flex", alignItems: "center" },
  date: { fontSize: 13, marginLeft: 3 },
}));

export const ViewEventCard = ({ event }) => {
  const classes = useStyles();
  const [openAddEvent, setOpenAddEvent] = React.useState(false);
  const [openWarning, setOpenWarning] = React.useState(false);

  const formatDate = (blogDate) => {
    let updatedDate = new Date(blogDate?.seconds * 1000);
    updatedDate = updatedDate.toLocaleDateString("en-US", {
      month: "short",
      year: "numeric",
      day: "numeric",
    });
    return updatedDate;
  };

  const onView = (data) => {};

  const onEdit = (data) => {
    setOpenAddEvent(true);
  };

  const onDelete = async (data) => {
    setOpenWarning(true);
  };

  async function handleConfirmDelete() {
    const res = await deleteEvent(event.id);
    if (res.successfull) {
      setOpenWarning(false);
      deleteToast("Event");
    } else {
      errorToast();
    }
  }

  const handleState = () => {
    setOpenAddEvent((currentState) => !currentState);
  };

  return (
    <React.Fragment>
      <ScheduleEventModal
        open={openAddEvent}
        handleState={handleState}
        eventDetails={event}
      />
      <WarningModal
        open={openWarning}
        setOpen={setOpenWarning}
        message={"Event"}
        subMessage={"All Data will be deleted & wont be accessible anymore"}
        deleteItem={handleConfirmDelete}
      />

      <Card className={classes.root}>
        <CardMedia className={classes.media} image={event?.eventPoster} />
        <div className={classes.content}>
          <Typography variant="body2" className={classes.blogTitle}>
            {event?.eventName}
          </Typography>
          <Typography className={classes.desc}>
            {event?.eventDescription ? parse(event?.eventDescription) : null}
          </Typography>
        </div>
        <Divider />
        <div className={classes.footer}>
          <div className={classes.leftFooter}>
            <AccessTimeIcon style={{ fontSize: "16px" }} />
            <Typography className={classes.date} variant="body2">
              {formatDate(event?.eventDate)}
            </Typography>
          </div>
          <div>
            <Actions
              data={event}
              onView={onView}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
};
