import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import DeleteTwoToneIcon from "@material-ui/icons/DeleteTwoTone";
import PersonIcon from "@material-ui/icons/Person";
import { WarningModal } from "../../../../common/WarningModal";
import { deleteBatchStudent } from "../../../../backend/EditDataToFirebase";
import { useParams } from "react-router-dom";
import { deleteToast } from "../../../../common/Toastify";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  status: {
    color: theme.palette.info.main,
  },
  invoice: {
    color: theme.palette.error.main,
  },
  container: {
    maxHeight: 600,
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: "#ffff",
    },
  },

  arrowIcon: {
    width: "30px",
    height: "30px",
  },
}));

function Row({ student }) {
  const classes = useRowStyles();
  const [openDelete, setOpenDelete] = React.useState(false);
  const { id } = useParams();

  const formatDate = (date) => {
    let updatedDate = new Date(date?.seconds * 1000).toLocaleDateString(
      "en-Us",
      {
        month: "short",
        day: "numeric",
        year: "numeric",
      }
    );
    return updatedDate;
  };

  const handleDeleteState = async () => {
    setOpenDelete((currentState) => !currentState);
  };

  const confirmDeleteStudent = async () => {
    const res = await deleteBatchStudent(id, student?.docId);
    if (res.successful) {
      deleteToast("Student");
    }
  };

  return (
    <React.Fragment>
      <WarningModal
        open={openDelete}
        setOpen={setOpenDelete}
        message={"Student"}
        subMessage={"Students won't be able to access these batch courses"}
        deleteItem={confirmDeleteStudent}
      />
      <TableRow className={classes.root}>
        <TableCell>
          <PersonIcon
            style={{
              color: "#000000",
            }}
          />
        </TableCell>
        <TableCell>{student?.studentName}</TableCell>
        <TableCell>{student?.contactNumber}</TableCell>
        <TableCell>{student?.parentsContactNumber}</TableCell>
        <TableCell>{formatDate(student?.dob)}</TableCell>
        <TableCell>
          <IconButton
            aria-label="delete"
            id={student?.docId}
            style={{ color: "#00000052", padding: 0 }}
            onClick={handleDeleteState}
          >
            <DeleteTwoToneIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export const EnrollStudentTable = ({ filteredResult }) => {
  const classes = useRowStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, filteredResult?.length - page * rowsPerPage);

  return (
    <>
      <TableContainer className={classes.container} component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Student Name</TableCell>
              <TableCell>Contact No.</TableCell>
              <TableCell>Parents Contact No.</TableCell>
              <TableCell>D.O.B</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          {filteredResult?.length ? (
            <TableBody>
              {filteredResult
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((student, index) => {
                  return <Row key={index} student={student} />;
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell>
                  <p>Please Add a Order</p>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredResult?.length ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
