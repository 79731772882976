import React from "react";
import { theme } from "./theme";
import { ThemeProvider } from "@material-ui/core";
import { AppRouter } from "./Router/Router";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppRouter />
    </ThemeProvider>
  );
}

export default App;
