import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { CourseCard } from "./CourseCard";

export const CourseList = ({ courses }) => {
  return (
    <React.Fragment>
      <Grid container spacing={5}>
        {courses?.length ? (
          courses?.map((course, index) => {
            return (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                <CourseCard course={course} />
              </Grid>
            );
          })
        ) : (
          <Typography variant="h4">Not found</Typography>
        )}
      </Grid>
    </React.Fragment>
  );
};
