import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import StudentData from "../../components/StudentData";
import swal from "sweetalert";
import { getDownloadURL, uploadFileToStorage } from "../../backend/UploadMedia";
import { AddMultipleStudents } from "../AddMultipleStudents/AddMultipleStudents";
import { AddCourse } from "../../common/Button";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import { IconButton } from "@material-ui/core";
import { editStudent } from "../../backend/EditDataToFirebase";
import "date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { useLocation } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { BLOOD_GROUP, DEVICE } from "../../common/const";
import { createStudent } from "../../backend/Server/Create/createStudents";
import { INPUT_IMAGE_PATH } from "../../common/config/configImage";
import {
  getCourseOption,
  setCourseOptions,
} from "../../backend/getStudentCourse";
import { CORE_ADD_STUDENT } from "../../common/config/configURL";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "red",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  steps: {
    padding: "0px",
  },
  step: {
    flex: 0,
    flexBasis: "15%",
    padding: "0px",
    margin: "0px",
  },
  StepsName: {
    flexDirection: "row",
  },
  Btns: {
    width: "200px",
    fontWeight: "bold",
    textTransform: "capitalize",
    color: "#000",
  },
  datePicker: {
    width: "100%",
  },
  delete: {
    width: "15px",
  },
  deleteBtn: {
    color: "#F44336",
    marginTop: 10,
  },
}));

function getSteps() {
  return ["Student Information", "Course Information"];
}

const AddStudent = ({ handleDrawerClose, editStudentData, isRedirect }) => {
  const classes = useStyles();
  const initialData = {
    profilePhoto: "",
    studentName: "",
    contactNumber: "",
    parentsContactNumber: "",
    address: "",
    collegeName: "",
    boardName: "",
    bloodGroup: "",
    reference: "",
    emergencyContactName: "",
    emergencyContactNumber: "",
    email: "",
    feeReceipt: [],
  };

  const [formData, setFormData] = useState(initialData);
  const [activeStep, setActiveStep] = useState(0);
  const [multiForm, setMultiForm] = useState(1);
  const [reference, setReference] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [courses, setCourses] = useState([
    {
      courseName: "",
      courseFee: "",
      courseDuration: new Date(),
      totalPaidFee: 0,
    },
  ]);

  const [validInput, setValidInput] = useState({
    profilePhoto: false,
    studentName: false,
    contactNumber: false,
    dob: true,
    email: false,
  });

  const [errorMsg, setErrorMsg] = useState("");
  const [studentDOB, setStudentDOB] = useState(new Date(2000, 0, 1));
  const [courseOption, setCourseOption] = useState([]);
  const [optionObject, setOptionObject] = useState({});
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    handleCourseOptions();
  }, []);

  const handleCourseOptions = async () => {
    await getCourseOption(setCourseOption, setOptionObject);
  };

  useEffect(() => {
    if (editStudentData) {
      setFormData({
        ...editStudentData,
        contactNumber: editStudentData?.contactNumber?.slice(3),
      });
      //code for DOB date in student form
      setStudentDOB(new Date(editStudentData?.dob.seconds * 1000));
      const updateDate = editStudentData?.courseDetails?.map((item) => {
        return {
          ...item,
          courseDuration: new Date(item?.courseDuration.seconds * 1000),
        };
      });
      setCourses(updateDate);
      setReference(editStudentData.reference);
      setValidInput({
        profilePhoto: true,
        studentName: true,
        contactNumber: true,
        email: true,
        dob: true,
      });
    }
  }, [editStudentData]);

  const handleChange = (e) => {
    if (
      e.target.name === "contactNumber" ||
      e.target.name === "parentsContactNumber" ||
      e.target.name === "emergencyContactNumber"
    ) {
      if (e.target.value.length > 10) {
        return;
      }
    }
    setFormData({ ...formData, [`${e.target.name}`]: e.target.value });
    switch (e.target.name) {
      case "reference":
        setReference("");
        break;
      default:
        break;
    }

    if (e.target.name) {
      if (!e.target.value) {
        setValidInput({ ...validInput, [`${e.target.name}`]: false });
      } else {
        if (
          e.target.name === "contactNumber" ||
          e.target.name === "parentsContactNumber" ||
          e.target.name === "emergencyContactNumber"
        ) {
          const isValidPhone = /^[0-9]{10}$/.test(e.target.value);
          setValidInput({ ...validInput, [`${e.target.name}`]: isValidPhone });
        } else if (e.target.name === "email") {
          const isValidEmail =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              e.target.value
            );
          setValidInput({ ...validInput, [`${e.target.name}`]: isValidEmail });
        } else {
          setValidInput({ ...validInput, [`${e.target.name}`]: true });
        }
      }
    }
  };

  const changeReference = (e) => {
    setReference(e.target.value);
  };

  const handleChangeProfile = (e) => {
    setValidInput({ ...validInput, profilePhoto: true });
    setFormData({ ...formData, [`${e.target.name}`]: e.target.files[0] });
  };

  const handleCreateStudent = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      let res;
      const profilePhoto =
        typeof formData.profilePhoto === "object"
          ? (await handleUpload(formData.profilePhoto)).url
          : formData.profilePhoto;
      if (!editStudentData?.id) {
        console.log("getUrl");
        res = await createStudent({
          ...formData,
          dob: studentDOB,
          contactNumber: "+91" + formData.contactNumber,
          joinDate: new Date(),
          courseDetails: courses,
          reference:
            formData.reference === "Others" ? reference : formData.reference,
          profilePhoto,
        });
      } else {
        res = await editStudent({
          ...formData,
          dob: studentDOB,
          contactNumber: "+91" + formData.contactNumber,
          courseDetails: courses,
          reference:
            formData.reference === "Others" ? reference : formData.reference,
          profilePhoto,
        });
      }
      if (res?.successful) {
        swal("Success!!!", `${res?.message ?? "Successful"}`, "success").then(
          () => {
            handleDrawerClose();
            // setFormData(initialData);
            if (!editStudentData?.id) {
              if (isRedirect) window.location.href = "https://roguecode.in";
            }
          }
        );
      } else {
        swal("Failed!!!", res?.message ?? "Something went wrong", "error");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = async (file) => {
    console.log(typeof file);
    if (typeof file === "string") return { url: file };
    const id =
      Date.now().toString(36) + Math.random().toString(36).substring(2);
    console.log(id);
    const ref = `/student-documents/${id}`;
    const uploadTask = uploadFileToStorage(ref, file);
    const url = await getDownloadURL(uploadTask);
    return { url, ref };
  };

  const next = (e) => {
    e.preventDefault();
    for (let key in validInput) {
      if (!validInput[key]) {
        setErrorMsg(key);
        return;
      } else {
        setErrorMsg("");
      }
    }
    if (multiForm < 2) {
      setMultiForm(multiForm + 1);
    }
  };

  const prev = () => {
    if (multiForm > 1) {
      setMultiForm(multiForm - 1);
    }
    setActiveStep(0);
  };

  const handlerCourse = async (e, index) => {
    if (e.target.name === "courseName") {
      await setCourseOptions({ ...optionObject, [e.target.value]: true });
    }
    const { name, value, valueAsNumber } = e.target;
    const values = [...courses];
    if (name === "courseName") {
      values[index].courseName = value;
    } else if (name === "courseFee") {
      valueAsNumber >= 0
        ? (values[index].courseFee = valueAsNumber)
        : (values[index].courseFee = 0);
    } else if (name === "courseDuration") {
      courses[index].courseDuration = startDate;
    }
    setCourses(values);
  };

  const handlerMore = (e) => {
    e.preventDefault();
    setCourses([
      ...courses,
      {
        courseName: "",
        courseFee: "",
        courseDuration: new Date(),
        totalPaidFee: 0,
      },
    ]);
  };

  const handlerDelete = (id) => {
    setCourses((prev) => {
      return prev.filter((item, index) => index !== id);
    });
  };

  const handlerDates = (index, e) => {
    if (e instanceof Date && !isNaN(e)) {
      setStartDate(e);
      courses[index].courseDuration = e;
    }
  };

  let getAvatarImage;
  if (formData?.profilePhoto) {
    if (typeof formData?.profilePhoto === "object") {
      getAvatarImage = URL.createObjectURL(formData?.profilePhoto);
    } else {
      getAvatarImage = formData?.profilePhoto;
    }
  } else {
    getAvatarImage = "/assets/profile-icon.png";
  }

  const location = useLocation();

  const isAddStudentPage = location.pathname === CORE_ADD_STUDENT;

  return (
    <Container>
      <Main>
        <Header>
          <div>
            <h3>{editStudentData?.id ? "EDIT STUDENT" : "CREATE STUDENT"}</h3>
          </div>
          <div>
            {!editStudentData?.id && !isAddStudentPage ? (
              <AddMultipleStudents />
            ) : null}
          </div>
        </Header>
        <Navbar>
          <img alt="student_info" src={INPUT_IMAGE_PATH.STEPPER_STUDENT_INFO} />
          {activeStep === 0 && (
            <img alt="course_info" src={INPUT_IMAGE_PATH.STEPPER_COURSE_INFO} />
          )}
          {activeStep === 1 && (
            <img
              alt="course_info_1"
              src={INPUT_IMAGE_PATH.STEPPER_COURSE_INFO_1}
            />
          )}
        </Navbar>

        {multiForm === 2 && (
          <StudentData formData={formData} studentDOB={studentDOB} />
        )}

        <Form>
          {multiForm === 1 ? (
            <div>
              <p>Student’s Personal Information</p>
            </div>
          ) : (
            <div>
              <p>Courses Regarding Information</p>
            </div>
          )}

          <form onSubmit={handleCreateStudent}>
            {multiForm === 1 ? (
              <>
                <div>
                  <p>Profile Picture (optional)</p>
                  <Inputs>
                    <Avatar>
                      <img src={getAvatarImage} alt="" />
                    </Avatar>
                    <div>
                      <label htmlFor="file" style={{ color: "#1976D2" }}>
                        Choose a photo
                      </label>
                      <input
                        type="file"
                        accept="image/*"
                        id="file"
                        style={{ display: "none" }}
                        name="profilePhoto"
                        onChange={handleChangeProfile}
                      />
                      <p style={{ fontSize: "12px" }}>
                        Acceptable formats jpg, png only.
                      </p>
                      <p style={{ fontSize: "12px" }}>300*300px</p>
                    </div>
                  </Inputs>
                </div>
                <div style={{ color: "red" }}>
                  {errorMsg === "profilePhoto" && "Please add profile"}
                </div>

                <InputFields>
                  <div>
                    <label htmlFor="studentName">
                      Name <span>*</span>
                    </label>
                    <TextField
                      placeholder="Enter Student’s Name"
                      variant="outlined"
                      type="text"
                      id="studentName"
                      name="studentName"
                      onChange={handleChange}
                      value={formData.studentName}
                      error={errorMsg === "studentName" ? true : false}
                      helperText={
                        errorMsg === "studentName" ? "Fill Student name" : null
                      }
                      autoComplete="off"
                    />
                  </div>

                  <div>
                    <label htmlFor="contactNumber">
                      Contact No.<span>*</span>
                    </label>
                    <TextField
                      autoComplete="off"
                      placeholder="Enter Contact No."
                      variant="outlined"
                      name="contactNumber"
                      onChange={handleChange}
                      value={formData.contactNumber}
                      type="number"
                      id="contactNumber"
                      error={errorMsg === "contactNumber" ? true : false}
                      helperText={
                        errorMsg === "contactNumber" ? "Fill Contact No." : null
                      }
                    />
                  </div>

                  <div>
                    <label htmlFor="parentsContactNumber">
                      Parent’s Contact No.
                    </label>
                    <TextField
                      autoComplete="off"
                      id="parentsContactNumber"
                      placeholder="Enter Parent’s Contact No."
                      variant="outlined"
                      name="parentsContactNumber"
                      onChange={handleChange}
                      value={formData.parentsContactNumber}
                      type="number"
                    />
                  </div>
                </InputFields>

                <InputFields>
                  <div style={{ flexBasis: "80%" }}>
                    <label htmlFor="">Address</label>
                    <TextField
                      autoComplete="off"
                      id="address"
                      placeholder="Enter Student’s Address"
                      variant="outlined"
                      type="text"
                      onChange={handleChange}
                      value={formData.address}
                      name="address"
                    />
                  </div>

                  <div style={{ flexBasis: "40%" }}>
                    <label htmlFor="date">
                      D.O.B <span>*</span>
                    </label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        variant="inline"
                        format="dd-MM-yyyy"
                        id="date-picker-inline"
                        name="dob"
                        className={classes.datePicker}
                        value={studentDOB}
                        onChange={(e) => {
                          setStudentDOB(e);
                          if (e instanceof Date && !isNaN(e)) {
                            setValidInput({ ...validInput, dob: true });
                          } else {
                            setValidInput({ ...validInput, dob: false });
                          }
                        }}
                        inputVariant="outlined"
                        error={errorMsg === "dob" ? true : false}
                        helperText={errorMsg === "dob" ? "Fill DOB" : null}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </InputFields>

                <InputFields>
                  <div style={{ flexBasis: "33%" }}>
                    <label htmlFor="collegeName">School/College Name</label>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      id="collegeName"
                      placeholder="School/College Name"
                      variant="outlined"
                      name="collegeName"
                      onChange={handleChange}
                      value={formData.collegeName}
                      type="text"
                    />
                  </div>

                  <div style={{ flexBasis: "33%" }}>
                    <label htmlFor="board">Board</label>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <Select
                        id="board"
                        name="boardName"
                        onChange={handleChange}
                        value={formData.boardName}
                        autoComplete="off"
                        style={{ width: 200 }}
                      >
                        <MenuItem value="IGCSE">
                          International General Certificate of Secondary
                          Education (IGCSE)
                        </MenuItem>
                        <MenuItem value="ICSE">
                          Indian Certificate of Secondary Education (ICSE)
                        </MenuItem>
                        <MenuItem value="CBSE">
                          Central Board of Secondary Education (CBSE)
                        </MenuItem>
                        <MenuItem value="HSC">
                          Higher Secondary School Certificate (HSC)
                        </MenuItem>
                        <MenuItem value="SSC">
                          Secondary School Certificate (SSC)
                        </MenuItem>
                        <MenuItem value="IB">
                          International Baccalaureate (IB)
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div style={{ flexBasis: "33%" }}>
                    <label htmlFor="blood">Blood Group</label>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <Select
                        id="blood"
                        name="bloodGroup"
                        onChange={handleChange}
                        value={formData.bloodGroup}
                        autoComplete="off"
                      >
                        {BLOOD_GROUP.map((item, index) => (
                          <MenuItem value={item.group}>{item.group}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </InputFields>

                <InputFields>
                  <div>
                    <label htmlFor="reference">Reference</label>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <Select
                        autoComplete="off"
                        name="reference"
                        onChange={handleChange}
                        id="reference"
                        value={formData.reference}
                      >
                        <MenuItem value="Instagram">Instagram</MenuItem>
                        <MenuItem value="Facebook">Facebook</MenuItem>
                        <MenuItem value="Google">Google</MenuItem>
                        <MenuItem value="Others">Others</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <div>
                    <label htmlFor="emergencyContactName">
                      Emergency Contact Name
                    </label>
                    <TextField
                      autoComplete="off"
                      id="emergencyContactName"
                      placeholder="Emergency Contact Name"
                      variant="outlined"
                      type="text"
                      name="emergencyContactName"
                      onChange={handleChange}
                      value={formData.emergencyContactName}
                    />
                  </div>

                  <div>
                    <label htmlFor="emergencyContactNumber">
                      Emergency Contact Number
                    </label>
                    <TextField
                      autoComplete="off"
                      id="emergencyContactNumber"
                      placeholder="Enter Contact Number"
                      variant="outlined"
                      type="number"
                      name="emergencyContactNumber"
                      onChange={handleChange}
                      value={formData.emergencyContactNumber}
                    />
                  </div>
                </InputFields>
                <InputFields>
                  {formData.reference === "Others" ? (
                    <div>
                      <label htmlFor="studentEmail">Other Reference</label>
                      <TextField
                        autoComplete="off"
                        id=""
                        style={{ width: 240 }}
                        placeholder="Enter Other Reference"
                        variant="outlined"
                        type="text"
                        name="emergencyContactNumber"
                        onChange={changeReference}
                        value={reference}
                      />
                    </div>
                  ) : null}
                  <div>
                    <label htmlFor="studentEmail">
                      Email <span>*</span>
                    </label>
                    <TextField
                      style={{ maxWidth: 504 }}
                      placeholder="Enter Student’s Email"
                      variant="outlined"
                      type="text"
                      id="studentEmail"
                      name="email"
                      onChange={handleChange}
                      value={formData.email}
                      error={errorMsg === "email" ? true : false}
                      helperText={
                        errorMsg === "email" ? "Fill Student's Email" : null
                      }
                      autoComplete="off"
                    />
                  </div>
                </InputFields>
                <Btns>
                  <Button
                    variant="contained"
                    className={classes.Btns}
                    onClick={prev}
                    disabled={multiForm === 1 ? true : false}
                  >
                    Back
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={next}
                    style={{ backgroundColor: "#F6B501" }}
                    disabled={multiForm === 2 ? true : false}
                    className={classes.Btns}
                  >
                    Next
                  </Button>
                </Btns>
              </>
            ) : (
              <>
                {courses.map((item, index) => {
                  return (
                    <>
                      <InputFields>
                        <div>
                          <label htmlFor="">Course Name</label>
                          <Autocomplete
                            // style={{ width: 190 }}
                            freeSolo
                            fullWidth
                            options={courseOption}
                            autoSelect
                            disableClearable
                            value={item?.courseName}
                            onChange={(e) => handlerCourse(e, index)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                style={{ width: 190 }}
                                id="courseName"
                                placeholder="Enter Course Name"
                                variant="outlined"
                                type="text"
                                name="courseName"
                              />
                            )}
                          />
                        </div>
                        <div>
                          <label htmlFor="">Date of Joining</label>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              // style={{ width: 190 }}
                              fullWidth
                              variant="inline"
                              format="dd-MM-yyyy"
                              id="date-picker-inline"
                              name="courseDuration"
                              className={classes.datePicker}
                              value={courses[index].courseDuration}
                              onChange={(e) => handlerDates(index, e)}
                              inputVariant="outlined"
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </div>

                        <div>
                          <label htmlFor="courseFee">Total Fees</label>
                          <TextField
                            autoComplete="off"
                            id="courseFee"
                            placeholder="Enter Total Fees"
                            variant="outlined"
                            type="number"
                            name="courseFee"
                            onChange={(e) => handlerCourse(e, index)}
                            value={item?.courseFee}
                          />
                        </div>
                        {courses.length > 1 && (
                          <div className={classes.delete}>
                            <IconButton
                              className={classes.deleteBtn}
                              aria-label="delete"
                              style={{ color: "#F44336", marginTop: 10 }}
                              onClick={() => handlerDelete(index)}
                            >
                              <DeleteForeverOutlinedIcon />
                            </IconButton>
                          </div>
                        )}
                      </InputFields>
                    </>
                  );
                })}

                <AddCourse
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={handlerMore}
                >
                  Add More Course
                </AddCourse>

                <Btns>
                  <Button
                    variant="contained"
                    className={classes.Btns}
                    onClick={prev}
                    disabled={multiForm === 1 ? true : false}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#F6B501" }}
                    className={classes.Btns}
                    type="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <CircularProgress color="secondary" />
                    ) : editStudentData?.id ? (
                      "Update"
                    ) : (
                      "Create"
                    )}
                  </Button>
                </Btns>
              </>
            )}
          </form>
        </Form>
      </Main>
    </Container>
  );
};

const Container = styled.div`
  background-color: #fff;
  width: 100%;
`;

const Main = styled.div`
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
`;

const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid #808080;
  > div {
    h3 {
      font-size: 18px;
      margin-top: 5px;
    }
  }

  p {
    color: #666666;
    color: #666666;
    font-size: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  span {
    color: #1a1a1a;
    font-size: 15px;
  }
  svg {
    height: 15px;
  }
`;

export const Btn = styled.button`
  background-color: #fff;
  border: 1px solid #0a2340;
  color: #000;
  /* padding: 10px 20px; */
  /* width: 100%; */
  width: 200px;

  &:last-child {
    background: #0a2340;
    color: #fff;
    margin-left: 10px;
  }

  svg,
  img {
    margin-right: 5px;
    height: 15px;
  }
`;

const Navbar = styled.div`
  padding-top: 27px;
`;

const Form = styled.div`
  margin-top: 10px;

  > div > p {
    color: #000;
    padding-bottom: 10px;
    font-weight: 550;
    border-bottom: 1px solid #ccc;
  }

  form {
    > div {
      margin-top: 10px;
    }
  }
`;

const Inputs = styled.div`
  display: flex;
  /* align-items: center; */
  gap: 20px;
  /* row-gap: 50px; */
  margin-top: 10px;
`;

const Avatar = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 5px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.5);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
`;

const InputFields = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px 0;

  label {
    span {
      color: red;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    > input {
      padding: 15px 10px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      border-radius: 5px;
      margin-top: 5px;
    }
    select {
      padding: 10px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      border-radius: 5px;
      margin-top: 5px;
      width: 100%;
    }

    @media ${DEVICE.laptop} {
      width: 100%;
    }
  }

  @media ${DEVICE.laptop} {
    flex-wrap: wrap;
  }
`;

const Btns = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

export default AddStudent;
