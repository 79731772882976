import React, { useEffect } from "react";
import { Divider, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/Close";
import { PublishChanges } from "../../../common/Button";
import PublishIcon from "@material-ui/icons/Publish";
import { AddBatchForm } from "./AddBatchForm";
import {
  getDownloadURL,
  uploadFileToStorage,
} from "../../../backend/UploadMedia";
import {
  errorToast,
  successToast,
  successToastMessage,
} from "../../../common/Toastify";
import { addBatch as createBatch } from "../../../backend/sendDataToFirestore";
import { updateBatch } from "../../../backend/EditDataToFirebase";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "6px 5px 0px 32px",
  },
  header: {
    fontSize: 27,
    fontWeight: "600",
    color: "#5F5F5F",
  },
  closeIcon: { fontSize: "30px" },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: 350,
    minWidth: 450,
    padding: "32px 40px 54px 45px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
  },
}));

export const AddBatch = ({ handleClose, editBatchData }) => {
  const classes = useStyles();
  const initialData = {
    batchName: "",
    description: "",
    // batchProfile: "",
    batchCourses: [],
    batchStudents: [],
  };
  const [formData, setFormData] = React.useState(initialData);
  const [isLoading, setLoading] = React.useState(false);

  useEffect(() => {
    if (
      editBatchData &&
      Object?.keys(editBatchData)?.length &&
      editBatchData?.docId
    ) {
      setFormData(editBatchData);
    }
  }, [editBatchData]);

  const handleChange = (name, value) => {
    setFormData({ ...formData, [`${name}`]: value });
  };

  const handleUpload = async (file) => {
    console.log(typeof file);
    if (typeof file === "string") return { url: file };
    const id =
      Date.now().toString(36) + Math.random().toString(36).substring(2);
    console.log(id);
    const ref = `/batch-images/${id}`;
    const uploadTask = uploadFileToStorage(ref, file);
    const url = await getDownloadURL(uploadTask);
    return { url, ref };
  };

  const handleUploadForm = async () => {
    try {
      setLoading(true);
      let res;
      // const imageData = await handleUpload(formData.batchProfile);
      // console.log(imageData.url);
      if (formData?.docId) {
        res = await updateBatch(formData);
        if (res.successfull) {
          successToastMessage("Batch Updated Successfully");
          handleClose();
        } else {
          errorToast();
        }
      } else {
        res = await createBatch({
          ...formData,
          // batchProfile: imageData.url,
        });
        if (res.successfull) {
          successToastMessage("Batch Created Successfully");
          handleClose();
        } else {
          errorToast();
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <div className={classes.headerContainer}>
        <Typography className={classes.header}>Create Batch</Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </div>
      <Divider />

      <div className={classes.content}>
        <AddBatchForm formData={formData} handleChange={handleChange} />
        <div className={classes.buttonContainer}>
          <PublishChanges
            disabled={isLoading}
            endIcon={<PublishIcon />}
            onClick={handleUploadForm}
          >
            {isLoading ? <CircularProgress color="secondary" /> : "Create"}
          </PublishChanges>
        </div>
      </div>
    </React.Fragment>
  );
};
