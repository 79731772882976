import { auth, firestore } from "../../firebase";

export const createStudent = async (data) => {
  try {
    const collectionSize = (await firestore.collection("Students").get()).size;
    const lastTwoDigitsOfYear = new Date().getFullYear() % 100;
    const uniqueId = `RCS${lastTwoDigitsOfYear}${collectionSize + 1}`;
    const randomString = Math.random().toString(36).slice(-8);

    const isAdded = await handleStudentExist(data.email, data.contactNumber);
    if (isAdded) {
      return {
        successful: false,
        message:
          "This Email/Contact number is already present use another or else contact rogue code",
      };
    }

    const isUserAuthAvl = await handleUserAuthAvl(data.email);

    let collectionRef;

    if (!isUserAuthAvl) {
      const newUser = await auth.createUserWithEmailAndPassword(
        data.email,
        randomString
      );
      collectionRef = firestore.collection("Students").doc(newUser?.user?.uid);
    } else {
      collectionRef = firestore.collection("Students").doc();
    }

    await collectionRef.set({
      ...data,
      uniqueId,
      docId: collectionRef.id,
    });

    const res = await fetch(
      `https://us-central1-rogue-code-portal.cloudfunctions.net/sendMail?dest=${data?.email}&pass=${randomString}`
    );
    if (res.status === 200) {
      return {
        successful: true,
        message: "User has been created Successfully",
      };
    }
    return {
      successful: true,
      message: "User has been created but email not sent",
    };
  } catch (error) {
    return {
      successful: false,
      message: error,
    };
  }
};

const handleStudentExist = async (email, contact) => {
  const emailCollectionRef = await firestore
    .collection("Students")
    .where("email", "==", email)
    .get();

  const contactCollectionRef = await firestore
    .collection("Students")
    .where("contactNumber", "==", contact)
    .get();

  return emailCollectionRef.size > 0 || contactCollectionRef.size > 0;
};

const handleUserAuthAvl = async (email) => {
  const methods = await auth.fetchSignInMethodsForEmail(email);
  if (methods && methods?.length > 0) return true;

  return false;
};
