import React, { useState } from "react";
import XLSX from "xlsx";
import UploadTable from "./Table";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { PrimaryButton } from "../../../common/Button";
import CloseIcon from "@material-ui/icons/Close";
import { INPUT_IMAGE_PATH } from "../../../common/config/configImage";
import DOMAIN_SAMPLE from "../../../globals/assets/excel-sample/domain.xlsx";
import HOST_SAMPLE from "../../../globals/assets/excel-sample/host.xlsx";
import SSL_SAMPLE from "../../../globals/assets/excel-sample/ssl.xlsx";
import { REMINDER_COLLECTION_NAME } from "../../../common/const";
import { addNewReminder } from "../../../backend/sendDataToFirestore";
import swal from "sweetalert";

const useStyles = makeStyles({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "18px",
  },
  sampleButton: {
    height: "100%",
    width: 170,
    margin: "0px 15px",
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "40px 0px 0px",
  },
});

function AddMultipleReminder({ tabName, handleState }) {
  const classes = useStyles();
  let file = [];
  const [tableData, setTableData] = useState([]);
  const [showSpinner, setShowSpinner] = React.useState(false);

  const handlerDropOver = (e) => {
    e.preventDefault();
  };

  const handlerDrop = async (e) => {
    e.preventDefault();
    file = e.dataTransfer.files;
    await handleData();
  };

  const handleUpload = async (event) => {
    file = event.target.files;
    await handleData();
  };

  const handleData = async (e) => {
    if (file.length < 1) {
      alert("Choose Correct File...");
      return;
    }
    if (typeof FileReader !== "undefined") {
      const reader = new FileReader();
      if (reader.readAsBinaryString) {
        reader.onload = (e) => {
          const workbook = XLSX.read(reader.result, { type: "binary" });
          const firstSheet = workbook.SheetNames[0];
          let excelRows = XLSX.utils.sheet_to_row_object_array(
            workbook.Sheets[firstSheet]
          );
          for (let row in excelRows) {
            const newRow = {};

            tabName === "Domain" &&
              (newRow["domainName"] = excelRows[row]["Domain Name"]);
            tabName === "SSL" &&
              (newRow["sslName"] = excelRows[row]["SSL Name"]);
            tabName === "Host" &&
              (newRow["hostName"] = excelRows[row]["Host Name"]);
            newRow["vendorName"] = excelRows[row]["Vendor Name"];
            newRow["clientName"] = excelRows[row]["Client Name"];
            newRow["clientContact"] = excelRows[row]["Client Contact"];
            newRow["purchaseDate"] = excelSerialToJSDate(
              excelRows[row]["Date of Purchase"]
            );
            newRow["expireDate"] = excelSerialToJSDate(
              excelRows[row]["Date of Expire"]
            );
            newRow["price"] = excelRows[row]["Price"];

            excelRows[row] = newRow;
          }

          setTableData(excelRows);
        };
        reader.readAsBinaryString(file[0]);
      }
    } else {
      console.log("This browser does not support HTML5.");
    }
  };

  const handleSubmit = async () => {
    setShowSpinner(true);

    try {
      for (const row of tableData) {
        await addNewReminder(SAMPLE_DOWNLOAD_COLLECTION_MAPPING[tabName], row);
      }
      setShowSpinner(false);
      swal("Success!!!", `${tabName} added Successfully`, "success").then(
        () => {
          setTableData([]);
          handleState();
        }
      );
    } catch (error) {
      setShowSpinner(false);
      alert(error);
    }
  };

  return (
    <div>
      <div onDrop={handlerDrop} onDragOver={handlerDropOver}>
        <div className={classes.header}>
          <div>
            <Typography variant="h7">Upload Excel or CSV File</Typography>

            <a
              href={SAMPLE_DOWNLOAD_MAPPING[tabName]}
              download={tabName}
              target="_blank"
              rel="noreferrer"
            >
              <PrimaryButton
                className={classes.sampleButton}
                color="primary"
                variant="contained"
              >
                Download Sample
              </PrimaryButton>
            </a>
          </div>
          <Button onClick={handleState}>
            <CloseIcon />
          </Button>
        </div>
        <div>
          <label>
            <img
              style={{ width: "100%" }}
              alt="upload Excel"
              src={INPUT_IMAGE_PATH.UPLOAD_EXCEL}
            />
            <input
              type="file"
              accept=".xlsx"
              name="uploadExcel"
              style={{ display: "none" }}
              onChange={handleUpload}
            />
          </label>
        </div>
      </div>
      <UploadTable tableData={tableData} tabName={tabName} />
      <div className={classes.footer}>
        {showSpinner && (
          <CircularProgress
            style={{
              color: "#18D26E",
            }}
          />
        )}
        <Button
          disabled={tableData.length === 0}
          variant="contained"
          onClick={handleSubmit}
        >
          Bulk Upload
        </Button>
      </div>
    </div>
  );
}

export default AddMultipleReminder;

function excelSerialToJSDate(serial) {
  const utcDays = Math.floor(serial - 25569);
  const utcValue = utcDays * 86400;
  const date = new Date(utcValue * 1000);

  return date;
}

const SAMPLE_DOWNLOAD_MAPPING = {
  Domain: DOMAIN_SAMPLE,
  SSL: SSL_SAMPLE,
  Host: HOST_SAMPLE,
};

const SAMPLE_DOWNLOAD_COLLECTION_MAPPING = {
  Domain: REMINDER_COLLECTION_NAME.DOMAIN,
  SSL: REMINDER_COLLECTION_NAME.SSL,
  Host: REMINDER_COLLECTION_NAME.HOST,
};
