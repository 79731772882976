import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Box from "../components/Box";
import { getCourseAnalytics } from "../backend/analytics/getAnalytics";

const useStyles = makeStyles((theme) => ({
  analyticsHeading: {
    marginBottom: 50,
    color: "#fff",
    fontFamily: "Outfit",
    fontSize: "16px",
  },
  analytics: {
    paddingTop: "30px",
    paddingBottom: "30px",
  },
  analyticsBox: {
    marginBottom: "5px",
    gap: 31,
    display: "flex",
    justifyContent: "space-between",
  },
}));

const initialData = {
  students: 0,
  courses: 0,
  trainers: 0,
  batches: 0,
};

function Analytics() {
  const classes = useStyles();
  const [analytics, setAnalytics] = useState(initialData);

  useEffect(() => {
    (async () => {
      await getCourseAnalytics(setAnalytics);
    })();
  }, []);

  return (
    <div className={classes.analytics}>
      <div className={classes.analyticsBox}>
        <Typography variant="body1" className={classes.analyticsHeading}>
          ANALYTICS
        </Typography>
      </div>

      <div className={classes.analyticsBox}>
        {analyticsDetails.map((item, index) => {
          const { key, ...rest } = item;
          const data = {
            ...rest,
            numberOfData: analytics[key],
          };
          return <Box item={data} key={index} />;
        })}
      </div>
    </div>
  );
}

export default Analytics;

const analyticsDetails = [
  {
    id: 1,
    icon: "/assets/student.svg",
    rate: " +20%",
    para: "TOTAL STUDENTS",
    key: "students",
  },
  // {
  //   id: 2,
  //   icon: "/assets/revenue.svg",
  //   rate: " +20%",
  //   para: "TOTAL REVENUE",
  //   key: "courses",
  // },
  {
    id: 3,
    icon: "/assets/course.svg",
    rate: " +20%",
    para: "TOTAL COURSES",
    key: "courses",
  },
  // {
  //   id: 4,
  //   icon: "/assets/team.svg",
  //   rate: " +20%",
  //   para: "TOTAL TRAINERS",
  //   key: "trainers",
  // },
  {
    id: 5,
    icon: "/assets/team.svg",
    rate: " +20%",
    para: "TOTAL BATCH",
    header: "20",
    key: "batches",
  },
];
