import React from "react";
import styled from "styled-components";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Link } from "react-router-dom";
import DownloadPdf from "../../components/DownloadPdf";
import { ShowStudentsDetails } from "./ShowSudentsDetails";

export const StudentDetails = ({ handleDrawerClose, student }) => {
  const handlerBack = () => {
    handleDrawerClose();
  };
  return (
    <Container>
      <Main>
        <Header>
          <div>
            <h3>
              Student Details of <span>{student?.studentName}</span>
            </h3>
          </div>
        </Header>
        <ShowStudentsDetails studentsDetails={student} />
        <Header>
          <div>Payment Receipts</div>
        </Header>
        <Table>
          <table>
            <thead>
              <tr>
                <th>Mode of Payment</th>
                <th>Course</th>
                <th>Total Fees (Rs.)</th>
                <th>Fees Paid (Rs.)</th>
                <th>Pending Fees (Rs.)</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {student?.feeReceipt?.map((item) => (
                <tr>
                  <td>{item?.paymentMode}</td>
                  <td>{item?.courseName}</td>
                  <td>{item?.courseFee}</td>
                  <td style={{ color: "#219653" }}>{item?.paidFee}</td>
                  {Number(item?.courseFee) - Number(item?.paidFee) === 0 ? (
                    <td style={{ color: "#000" }}>
                      {Number(item?.courseFee) - Number(item?.paidFee)}
                    </td>
                  ) : (
                    <td style={{ color: "#F44336" }}>
                      {Number(item?.courseFee) - Number(item?.paidFee)}
                    </td>
                  )}
                  <td>
                    <DownloadPdf students={item} studentDetails={student} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <Pagination>
            <SelectPage>Rows per page: 10</SelectPage>

            <PageMove>
              <p>10-10 of 10</p>

              <button>
                <NavigateBeforeIcon />
              </button>
              <button>
                <NavigateNextIcon />
              </button>
            </PageMove>
          </Pagination>
        </Table>

        <Btns>
          <BackBtn onClick={handlerBack}>Back</BackBtn>
        </Btns>
      </Main>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
`;

const Main = styled.div`
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  margin-top: 10px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid #cccccc;

  > div {
    h3 {
      font-size: 20px;
      margin-top: 5px;

      span {
        font-weight: 400;
        color: rgba(0, 0, 0, 0.54);
        font-size: 20px;
      }
    }
  }
  p {
    color: #666666;
    font-size: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  span {
    color: #1a1a1a;
    font-size: 15px;
  }
  svg {
    font-size: 14px;
  }
`;

const Btns = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BackBtn = styled.button`
  color: #000;
  background: #e6e6e6;
  border: none;
  outline: none;
  padding: 10px 30px;
`;

const Btn = styled.button`
  border-radius: 5px;
  color: #fff;
  background: #0a2340;
`;

const Table = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  overflow: hidden;
  table {
    /* table-layout: fixed; */
    width: 100%;
    border-radius: 5px;
    /* border: 1px solid rgba(0,0,0,0.15); */
    border-collapse: collapse;
  }
  th,
  td {
    /* border-bottom: 1px solid rgba(0,0,0,0.15); */
    padding: 10px 0;
    text-align: center;
    font-weight: 500;
  }
  th,
  td {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
  margin: 5px 0;
  padding: 0 5px;
`;

const SelectPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .MuiSvgIcon-root {
    color: rgba(0, 0, 0, 0.5);
    font-size: 30px;
  }
`;

const PageMove = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  /* background-color: red; */
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    color: rgba(0, 0, 0, 0.5);
    font-size: 20px;
    /* background-color: red; */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
  .MuiSvgIcon-root {
    color: rgba(0, 0, 0, 0.5);
    font-size: 22px;
  }
`;
