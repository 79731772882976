import React, { useState } from "react";
import styled from "styled-components";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Check from "@material-ui/icons/Check";
import SettingsIcon from "@material-ui/icons/Settings";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import Button from "@material-ui/core/Button";
import swal from "sweetalert";
import { getDownloadURL, uploadFileToStorage } from "../../backend/UploadMedia";
import { createTrainer } from "../../backend/sendDataToFirestore";

import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import { useEffect } from "react";
import { editTrainer } from "../../backend/EditDataToFirebase";
import { OutlinedInput } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const names = ["HTML", "CSS", "JAVA", "JAVASCRIPT", "REACT", "NODEJS"];

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "red",
    display: "flex",
    height: 22,
    alignItems: "center",
    backgroundColor: "red",
  },
  active: {
    color: "#784af4",
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
  completed: {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "red",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    color: "red",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,

  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "red",
  },
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  header: {
    marginTop: 15,
    marginBotton: 15,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  steps: {
    padding: "0px",
  },
  step: {
    flex: 0,
    flexBasis: "15%",
    padding: "0px",
    margin: "0px",
  },
  StepsName: {
    flexDirection: "row",
  },
  Btns: {
    width: "200px",
    fontWeight: "bold",
    textTransform: "capitalize",
    color: "#000",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  label: {
    left: "5%",
    "&::after": {
      borderBottom: "none",
    },
  },
  selectMenu: {
    minHeight: 45,
    border: "1px solid #ced4da",
    borderRadius: 4,

    "& .MuiInput-underline": {
      "&:before": {
        "border-bottom": "none",

        "&:hover": {
          "border-bottom": "none !important",
        },
      },
    },

    "& ..MuiSelect-root:after": {
      "border-bottom": "none !important",
    },
  },
}));

export const CreateTrainer = ({ handleDrawerClose, editTrainerData }) => {
  const classes = useStyles();
  const initialData = {
    profilePhoto: "",
    trainerName: "",
    emailId: "",
    contactNumber: "",
    address: "",
    dob: new Date(),
    experience: "",
    preferredMode: "",
    availability: "",
  };

  const [formData, setFormData] = useState(initialData);
  const [subjectName, setSubjectName] = React.useState([]);
  const [trainerValidInput, setTrainerValidInput] = React.useState({
    trainerName: false,
    emailId: false,
    contactNumber: false,
    address: false,
    dob: false,
    experience: false,
    preferredMode: false,
    availability: false,
  });
  const [trainerErrorMsg, setTrainerErrorMsg] = useState("");

  useEffect(() => {
    if (editTrainerData) {
      setFormData({
        ...editTrainerData,
        dob: changeTimestampToDate(editTrainerData.dob),
      });
      setSubjectName(editTrainerData.subject);
      setTrainerValidInput({
        trainerName: true,
        emailId: true,
        contactNumber: true,
        address: true,
        dob: true,
        experience: true,
        preferredMode: true,
        availability: true,
      });
    }
  }, [editTrainerData]);

  const handleChangeMulti = (event) => {
    setSubjectName(event.target.value);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [`${e.target.name}`]: e.target.value });
    if (e.target.name) {
      if (!e.target.value) {
        setTrainerValidInput({
          ...trainerValidInput,
          [`${e.target.name}`]: false,
        });
      } else {
        setTrainerValidInput({
          ...trainerValidInput,
          [`${e.target.name}`]: true,
        });
      }
    }
  };

  const handleChangeProfile = (e) => {
    setFormData({ ...formData, [`${e.target.name}`]: e.target.files[0] });
  };

  const handleCreateTrainer = async () => {
    let res;
    for (let key in trainerValidInput) {
      if (!trainerValidInput[key]) {
        setTrainerErrorMsg(key);
        return;
      } else {
        setTrainerErrorMsg("");
      }
    }
    if (!editTrainerData?.id) {
      const imageData = await handleUpload(formData.profilePhoto);
      console.log("imageData", imageData);
      console.log("getUrl");
      res = await createTrainer({
        ...formData,
        subject: subjectName,
        profilePhoto: imageData.url,
        joinDate: new Date(),
      });
    } else {
      res = await editTrainer({
        ...formData,
        subject: subjectName,
      });
    }
    if (res.successful) {
      swal("Success!!!", "Trainer Created Successfully", "success").then(() => {
        handleDrawerClose();
      });
    }
  };

  const handleUpload = async (file) => {
    console.log(typeof file);
    if (typeof file === "string") return { url: file };
    const id =
      Date.now().toString(36) + Math.random().toString(36).substring(2);
    console.log(id);
    const ref = `/trainer-documents/${id}`;
    const uploadTask = await uploadFileToStorage(ref, file);
    const url = await getDownloadURL(uploadTask);
    return { url, ref };
  };

  return (
    <Container>
      <Main>
        <Header>
          <div>
            <h3> {editTrainerData?.id ? "EDIT TRAINER" : "CREATE TRAINER"}</h3>
          </div>
        </Header>

        <Form>
          <div className={classes.header}>
            <p>Trainer’s Personal Information</p>
          </div>

          <form>
            <>
              <div>
                <p>Profile Picture (optional)</p>
                <Inputs>
                  <Avatar>
                    <img
                      src={
                        formData?.profilePhoto
                          ? URL.createObjectURL(formData?.profilePhoto)
                          : "/assets/profile-icon.png"
                      }
                      alt=""
                    />
                  </Avatar>
                  <div>
                    <label htmlFor="file" style={{ color: "#1976D2" }}>
                      Choose a photo
                    </label>
                    <input
                      type="file"
                      accept="image/*"
                      id="file"
                      style={{ display: "none" }}
                      name="profilePhoto"
                      onChange={handleChangeProfile}
                    />
                    <p style={{ fontSize: "12px" }}>
                      Acceptable formats jpg, png only.
                    </p>
                    <p style={{ fontSize: "12px" }}>300*300px</p>
                  </div>
                </Inputs>
              </div>

              <InputFields>
                <div>
                  <label htmlFor="trainerName">
                    Trainer’s Name <span>*</span>
                  </label>
                  <TextField
                    placeholder="Enter Trainer’s Name"
                    variant="outlined"
                    type="text"
                    name="trainerName"
                    onChange={handleChange}
                    value={formData.trainerName}
                    id="trainerName"
                    error={trainerErrorMsg === "trainerName" ? true : false}
                    helperText={
                      trainerErrorMsg === "trainerName"
                        ? "Fill Trainer name"
                        : null
                    }
                    autoComplete="off"
                  />
                </div>

                <div>
                  <label htmlFor="emailId">
                    Email Id<span>*</span>
                  </label>
                  <TextField
                    placeholder="Enter Email Id"
                    variant="outlined"
                    type="text"
                    name="emailId"
                    onChange={handleChange}
                    value={formData.emailId}
                    id="emailId"
                    error={trainerErrorMsg === "emailId" ? true : false}
                    helperText={
                      trainerErrorMsg === "emailId" ? "Fill Email Id" : null
                    }
                    autoComplete="off"
                  />
                </div>

                <div>
                  <label htmlFor="contactNumber">
                    Contact No.<span>*</span>
                  </label>
                  <TextField
                    placeholder="Enter Contact No."
                    variant="outlined"
                    type="number"
                    name="contactNumber"
                    onChange={handleChange}
                    value={formData.contactNumber}
                    id="contactNumber"
                    error={trainerErrorMsg === "contactNumber" ? true : false}
                    helperText={
                      trainerErrorMsg === "contactNumber"
                        ? "Fill Contact No."
                        : null
                    }
                    autoComplete="off"
                  />
                </div>
              </InputFields>

              <InputFields>
                <div style={{ flexBasis: "60%" }}>
                  <label htmlFor="address">
                    Address<span>*</span>
                  </label>
                  <TextField
                    placeholder="Enter Trainer's Address"
                    variant="outlined"
                    type="text"
                    name="address"
                    onChange={handleChange}
                    value={formData.address}
                    id="address"
                    error={trainerErrorMsg === "address" ? true : false}
                    helperText={
                      trainerErrorMsg === "address"
                        ? "Fill Trainer address"
                        : null
                    }
                    autoComplete="off"
                  />
                </div>

                <div>
                  <label htmlFor="dob">
                    D.O.B<span>*</span>
                  </label>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      error={trainerErrorMsg === "dob" ? true : false}
                      helperText={
                        trainerErrorMsg === "dob" ? "Fill D.O.B" : null
                      }
                      variant="inline"
                      format="dd-MM-yyyy"
                      id="date-picker-inline"
                      name="dob"
                      className={classes.datePicker}
                      value={formData?.dob}
                      onChange={(value) => {
                        setFormData({ ...formData, dob: value });
                        setTrainerValidInput({
                          ...trainerValidInput,
                          dob: true,
                        });
                      }}
                      inputVariant="outlined"
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </InputFields>

              <InputFields>
                <div>
                  <label htmlFor="experience">
                    Experience<span>*</span>
                  </label>
                  <TextField
                    variant="outlined"
                    type="text"
                    name="experience"
                    onChange={handleChange}
                    value={formData.experience}
                    id="experience"
                    placeholder="Enter Experience in years or months"
                    error={trainerErrorMsg === "experience" ? true : false}
                    helperText={
                      trainerErrorMsg === "experience"
                        ? "Fill Experience"
                        : null
                    }
                    autoComplete="off"
                  />
                </div>

                <div style={{ flexBasis: "23%" }}>
                  <label htmlFor="">
                    Subject <span>*</span>
                  </label>
                  <FormControl
                    variant="outlined"
                    className={classes.selectMenu}
                  >
                    <Select
                      style={{ minHeight: "45px", width: "200px" }}
                      value={subjectName}
                      onChange={handleChangeMulti}
                      multiple
                      input={<Input id="select-multiple-chip" />}
                      renderValue={(selected) => (
                        <div className={classes.chips}>
                          {selected.map((value) => (
                            <Chip
                              key={value}
                              label={value}
                              className={classes.chip}
                            />
                          ))}
                        </div>
                      )}
                      MenuProps={MenuProps}
                    >
                      {names.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={subjectName.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div>
                  <label htmlFor="preferredMode">
                    Preferred Mode of Teaching<span>*</span>
                  </label>

                  <FormControl
                    variant="outlined"
                    error={trainerErrorMsg === "preferredMode" ? true : false}
                  >
                    <Select
                      id="preferredMode"
                      name="preferredMode"
                      onChange={handleChange}
                      value={formData.preferredMode}
                      autoComplete="off"
                    >
                      <MenuItem value="In-Person">In-Person</MenuItem>
                      <MenuItem value="Online">Online</MenuItem>
                      <MenuItem value="Both Online & In-Person">
                        Both Online & In-Person
                      </MenuItem>
                    </Select>
                    {trainerErrorMsg === "preferredMode" ? (
                      <FormHelperText>Fill Board</FormHelperText>
                    ) : null}
                  </FormControl>
                </div>
              </InputFields>

              <InputFields>
                <div>
                  <label htmlFor="availability">
                    Availability<span>*</span>
                  </label>
                  <TextField
                    variant="outlined"
                    type="text"
                    name="availability"
                    onChange={handleChange}
                    value={formData.availability}
                    id="availability"
                    placeholder="Enter Trainer’s Availability"
                    style={{ width: 280 }}
                    error={trainerErrorMsg === "availability" ? true : false}
                    helperText={
                      trainerErrorMsg === "availability"
                        ? "Fill Availability"
                        : null
                    }
                    autoComplete="off"
                  />
                </div>
              </InputFields>
              <Btns>
                <Button
                  variant="contained"
                  className={classes.Btns}
                  onClick={handleDrawerClose}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleCreateTrainer}
                  style={{ backgroundColor: "#F6B501" }}
                  className={classes.Btns}
                >
                  {editTrainerData?.id ? "Update" : "Create"}
                </Button>
              </Btns>
            </>
          </form>
        </Form>
      </Main>
    </Container>
  );
};

const Container = styled.div`
  background-color: #fff;
  width: 100%;
`;

const Main = styled.div`
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  margin-top: 10px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid #808080;
  > div {
    h3 {
      font-size: 18px;
      margin-top: 5px;
    }
  }

  p {
    color: #666666;
    color: #666666;
    font-size: 15px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  span {
    color: #1a1a1a;
    font-size: 15px;
  }
  svg {
    height: 15px;
  }
`;

const Form = styled.div`
  margin-top: 10px;

  > div > p {
    color: #000;
    padding-bottom: 10px;
    font-weight: 550;
    border-bottom: 1px solid #ccc;
  }

  form {
    > div {
      margin-top: 10px;
    }
  }
`;

const Inputs = styled.div`
  display: flex;
  /* align-items: center; */
  gap: 20px;
  /* row-gap: 50px; */
  margin-top: 10px;
`;

const Avatar = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 5px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.5);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
`;

const InputFields = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
  /* row-gap: 50px; */
  label {
    span {
      color: red;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
`;

const Btns = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const changeTimestampToDate = (timestamp) => {
  const milliseconds =
    timestamp.seconds * 1000 + Math.round(timestamp.nanoseconds / 1e6);
  const date = new Date(milliseconds);

  const options = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
  };

  return date.toLocaleString("en-US", options);
};
