import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "40px 90px",
  },
  searchInputs: {
    display: "flex",
    gap: 20,
  },
  search: {
    width: 300,
    height: 35,
  },
  select: {
    width: 180,
  },
  cards: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  btn: {
    display: "flex",
    alignItems: "center",
    padding: "10px 12px",
    border: "1px solid #002E63",
    color: "#002E63",
    borderRadius: 5,
    fontWeight: 500,
    fontSize: 15,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const ToolBar = ({ btnText, handleModal, searchVal, setSearch }) => {
  const classes = useStyles();
  return (
    <>
      <div>
        <div className={classes.toolbar}>
          <div className={classes.searchInputs}>
            <div>
              <TextField
                id="filled-required"
                label="Search"
                variant="outlined"
                placeholder="Name, courses, etc..."
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.search}
                value={searchVal}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>

            <div>
              <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">
                  Sort By
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  label="Sort By"
                  className={classes.select}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <div>
            <Button
              variant="outlined"
              className={classes.btn}
              onClick={handleModal}
            >
              <AddIcon />
              {btnText}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ToolBar;
