import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { ScheduleEventModal } from "../AddEvent/AddEventModal";
import { PrimaryButton } from "../../../common/Button";
import EventIcon from "@material-ui/icons/Event";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    height: 191,
    backgroundColor: "#000000",
    padding: "64px 90px 69px",
    borderBottom: "1px solid #333333",
  },
  subHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerTitle: {
    fontWeight: "600",
    color: theme.palette.common.white,
  },
  headerSubTitle: {
    fontWeight: "300",
    color: theme.palette.common.white,
  },
}));

export const EventHeader = () => {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleState = () => {
    setOpen((currentState) => !currentState);
  };

  return (
    <React.Fragment>
      <ScheduleEventModal open={open} handleState={handleState} />
      <div className={classes.headerContainer}>
        <div className={classes.subHeaderContainer}>
          <Typography variant="h4" className={classes.headerTitle}>
            Events
          </Typography>
          <PrimaryButton
            startIcon={<EventIcon />}
            variant="contained"
            color="primary"
            onClick={handleState}
          >
            Schedule an event
          </PrimaryButton>
        </div>
        <Typography variant="h5" className={classes.headerSubTitle}>
          Lets host some memorable Events
        </Typography>
      </div>
    </React.Fragment>
  );
};
