export const INPUT_IMAGE_PATH = {
  ROGUE_CODE_LOGO: "/assets/Rogue-code.svg",
  UPLOAD_EXCEL: "/assets/uploadExcel.svg",
  STEPPER_STUDENT_INFO: "/assets/student_info.svg",
  STEPPER_COURSE_INFO: "/assets/course_info.svg",
  STEPPER_COURSE_INFO_1: "/assets/course_info_1.svg",
  BLOG_TITLE: "/assets/blogs/blog-title.svg",
  BLOG_TOPIC: "/assets/blogs/blog-topic.svg",
  BLOG_PEOPLE: "/assets/blogs/blog-people.svg",
  BLOG_AUTHOR: "/assets/blogs/blog-author.svg",
  BLOG_EXPAND: "/assets/blogs/expand.svg",
  LOGOUT_LOGO: "/assets/sidebar/Logout.svg",
};

export const SIDEBAR_ROUTES_IMAGE_PATH = {
  DASHBOARD: "/assets/sidebar/sidebar-dashboard.svg",
  COURSE: "/assets/sidebar/sidebar-course.svg",
  BLOGS: "/assets/sidebar/sidebar-blogs.svg",
  EVENTS: "/assets/sidebar/sidebar-events.svg",
  SHOP: "/assets/sidebar/sidebar-shop.svg",
  BATCH: "/assets/sidebar/sidebar-batch.svg",
  REMINDER: "/assets/sidebar/sidebar-reminder.svg",
  SETTINGS: "/assets/sidebar/sidebar-settings.svg",
  MARKETING: "/assets/sidebar/sidebar-marketing.svg",
};

export const STUDENTS_TABLE_IMAGE_PATH = {
  COURSE_CERTIFICATE: "/assets/view-students/certificate.svg",
  GOOGLE_REVIEW: "/assets/view-students/google-review.svg",
  GOOGLE_REVIEW_COLORED: "/assets/view-students/google-review-filled.svg",
  GENERATE_CERTIFICATE: "/assets/view-students/generate-certificate.svg",
};

export const FEE_RECIEPT_IMAGE_PATH = {
  LINKEDIN_LOGO: "/assets/fee-receipt/linkedin-logo.svg",
  FACEBOOK_LOGO: "/assets/fee-receipt/facebook-logo.svg",
  INSTAGRAM_LOGO: "/assets/fee-receipt/insta-logo.svg",
  YOUTUBE_LOGO: "/assets/fee-receipt/youtube-logo.svg",
  TWITTER_LOGO: "/assets/fee-receipt/twitter-logo.svg",
  SIGN: "/assets/rogue-sign.jpeg",
};
