import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { AddProductButton } from "../../../common/Button";
import AddIcon from "@material-ui/icons/Add";
import { AddProductForm } from "./AddProductForm";

const useStyles = makeStyles({
  root: {
    width: "729px",
    height: "1024px",
    padding: "32px 38px 50px",
  },
});

export const AddProductModal = ({ editProduct = false, productData }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleState = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      {!editProduct ? (
        <AddProductButton
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={handleState}
        >
          Add New Product
        </AddProductButton>
      ) : (
        <AddProductButton
          variant="outlined"
          style={{ width: 149 }}
          onClick={handleState}
        >
          Edit Product
        </AddProductButton>
      )}
      <React.Fragment key={"right"}>
        <SwipeableDrawer anchor={"right"} open={open} onClose={handleState}>
          <div className={classes.root}>
            <AddProductForm
              handleClose={handleState}
              editProductData={productData}
              isEditProduct={editProduct}
            />
          </div>
        </SwipeableDrawer>
      </React.Fragment>
    </React.Fragment>
  );
};
