export const course = [
  {
    name: "HTML",
    description: "A powerful language for web Development",
    link: "html",
    quiz: [
      {
        id: 1,
        title: "HTML QUIZ",
        description:
          "1Lorem ipsum dolor sit amet consectetur. Amet morbi sed consequat eget convallis aenean ph.0",
        duration: "30 min",
        date: "13/04/2023",
      },
      {
        id: 2,
        title: "HTML QUIZ",
        description:
          "1Lorem ipsum dolor sit amet consectetur. Amet morbi sed consequat eget convallis aenean ph.0",
        duration: "30 min",
        date: "13/04/2023",
      },
    ],
    assignments: [
      {
        id: 1,
        title: "HTML Assignment",
        time: "05 March, 2023",
        description:
          "Lorem ipsum dolor sit amet consectetur. Amet morbi sed consequat eget convallis aenean ph",
      },
      {
        id: 2,
        title: "HTML Assignment",
        time: "05 March, 2023",
        description:
          "Lorem ipsum dolor sit amet consectetur. Amet morbi sed consequat eget convallis aenean ph",
      },
    ],

    ppt: [
      {
        id: 1,
        title: "HTML PPT",
        description:
          "1Lorem ipsum dolor sit amet consectetur. Amet morbi sed consequat eget convallis aenean ph.0",
        link: "PPT Link",
        date: "13/04/2023",
      },
      {
        id: 2,
        title: "HTML QUIZ",
        description:
          "1Lorem ipsum dolor sit amet consectetur. Amet morbi sed consequat eget convallis aenean ph.0",
        link: "PPT Link",
        date: "13/04/2023",
      },
    ],
    videos: [
      {
        id: 1,
        title: "HTML Video",
        date: "05 March, 2023",
      },
      {
        id: 2,
        title: "HTML Video",
        date: "05 March, 2023",
      },
    ],
  },
];
