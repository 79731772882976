import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import { AddStudentFee } from "./AddStudentFee";

const useStyles = makeStyles({
  fullList: {
    minWidth: "900px",
    height: "1016px",
    padding: "32px 38px 50px",
  },
});

export function OpenFeeModal({ state, setState, student }) {
  const classes = useStyles();

  const handleDrawerClose = () => setState(false);
  const classe = `container mt-5 ${classes.fullList}`;

  return (
    <div>
      <React.Fragment key={"right"}>
        <SwipeableDrawer
          anchor={"right"}
          open={state}
          onClose={handleDrawerClose}
        >
          <div className={classe}>
            <AddStudentFee
              handleDrawerClose={handleDrawerClose}
              student={student}
            />
          </div>
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
