import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("T-shirt", 159, 6, 24, 4),
  createData("T-shirt", 237, 9, 37, 4),
  createData("T-shirt", 262, 16, 24, 6),
  createData("T-shirt", 305, 3, 67, 4),
];

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    gap: "50px",
    marginTop: 20,
  },
  locationCard: {
    height: 413,
    width: 261,
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 10,
    background: "#ccc",
  },
  productCard: {
    width: 925,
    height: 413,
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: 10,
    padding: 30,
  },
  table: {
    boxShadow: "none",

    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },

  tableImg: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    padding: "10px 16px",
    lineHeight: 1,
  },
}));

const OverviewProducts = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.container}>
        <div className={classes.productCard}>
          <Typography variant="h6" style={{ color: "#0A2340" }}>
            Best Selling Product
          </Typography>
          <div>
            <TableContainer component={Paper} className={classes.table}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell}>
                      Product name
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      Price
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      Orders
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      Stock
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      Total Amount
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell component="th" scope="row">
                        <div className={classes.tableImg}>
                          <img
                            src="https://s3-alpha-sig.figma.com/img/8c15/c898/37fa603a81c95447293a1f6b385835e9?Expires=1681689600&Signature=M~6-Tt6a1CqXwajg9IWoangFqrjdR2YCSE5v9MTJ9MXKMryE3z-nkAXwHG-icWwQU7VYaFXCPkjQW8ngDwnejjlByCrO7lDhK8hoZSte3gJbzdwFp5qgHrhrVwN8IxGqYkv15tW2UFy3j0-oQdZQk9BSZOlmaKx1r3CPqCFjT-kBspaPSvvLXjR2hinoYG0Vai6qXiy5GH5WMVVRM7P05C0fRlVm19JJ9wSWk59B8CGZHIZCBI0WtItTlTCQcQEq-bkF-umlOwy-DcylHgzFEC009KeYUGGu4rAW1wIQmI6xdFZh8ybgC6JUDnfLaBxXhRN0Ndn6sqedju3cB~Hs0Q__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"
                            alt=""
                            width={34}
                          />
                          {row.name}
                        </div>
                      </TableCell>
                      <TableCell className={classes.tableCell} align="right">
                        {row.calories}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="right">
                        {row.fat}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="right">
                        {row.carbs}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="right">
                        {row.protein}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <div className={classes.locationCard}></div>
      </div>
    </>
  );
};

export default OverviewProducts;
