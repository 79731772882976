import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { CreateBlog } from "../../../common/Button";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { OpenBlogModal } from "../AddBlog/AddNewBlog";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    backgroundColor: "#0C0C0C",
    padding: "64px 90px 69px",
  },
  subHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  headerTitle: {
    fontWeight: "600",
    color: theme.palette.common.white,
  },
  headerSubTitle: {
    fontWeight: "300",
    color: theme.palette.common.white,
  },
}));

export const BlogHeader = () => {
  const classes = useStyles();

  const [openAddBlog, setOpenAddBlog] = useState(false);

  return (
    <React.Fragment>
      <OpenBlogModal open={openAddBlog} setOpen={setOpenAddBlog} />

      <div className={classes.headerContainer}>
        <div className={classes.subHeaderContainer}>
          <Typography variant="h4" className={classes.headerTitle}>
            Blogs
          </Typography>
          <CreateBlog
            startIcon={<PersonAddIcon />}
            onClick={() => setOpenAddBlog(true)}
          >
            Create Blog
          </CreateBlog>
        </div>
        <Typography variant="h5" className={classes.headerSubTitle}>
          Lets create some amazing content.
        </Typography>
      </div>
    </React.Fragment>
  );
};
