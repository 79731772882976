import { useEffect, useState } from "react";
import { AddCourses } from "./AddCourses";
import { getToAddBatchCourseDetails } from "../../../../backend/getDataFromFirestore";

export const AddCoursePage = ({ handleClose, courseIds }) => {
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    handleToSelectCourses(courseIds);
  }, [courseIds]);

  const handleToSelectCourses = async (courseIds) => {
    const res = await getToAddBatchCourseDetails(courseIds);
    setCourses(res);
  };

  return <AddCourses handleClose={handleClose} courses={courses} />;
};
