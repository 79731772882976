import firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC8PvBvetx2C_uyRP_xaSfZQ5gi1Zvs_aQ",
  authDomain: "rogue-code-portal.firebaseapp.com",
  projectId: "rogue-code-portal",
  storageBucket: "rogue-code-portal.appspot.com",
  messagingSenderId: "750751944799",
  appId: "1:750751944799:web:46b83bb5d402e187238bea",
  measurementId: "G-W0QLYJDGSZ",
};

firebase.initializeApp(firebaseConfig);
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const firebasee = firebase.firestore;
export const auth = firebase.auth();
