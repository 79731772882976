import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { ModalBtn } from "../../common/Button";
import AddIcon from "@material-ui/icons/Add";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import ImageIcon from "@material-ui/icons/Image";
import CircularProgress from "@material-ui/core/CircularProgress";

import { createCourse } from "../../backend/sendCourseData";
import { uploadFileToStorage, getDownloadURL } from "../../backend/UploadMedia";
import { successToastMessage } from "../../common/Toastify";
import { CoursePreview } from "./CoursePreview";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.5)",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 100,
    bottom: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  box: {
    background: "#fff",
    width: 450,
    color: "#5F5F5F",
    borderRadius: 15,
    height: "100vh",
    overflow: "auto",
  },
  header: {
    padding: "15px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  imageWrapper: {
    marginBottom: 30,
    marginTop: 20,
    width: "50%",
    overflow: "hidden",
  },

  content: {
    padding: "25px 30px",
  },
  label: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    marginBottom: 10,
    color: "#000",
  },
  link: {
    transform: "rotate(-45deg)",
  },
  Btn: {
    paddingTop: 35,
    textAlign: "center",
  },
  description: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      height: 100,
      display: "block",
      padding: 10,
    },

    "& .MuiOutlinedInput-input": {
      padding: 0,
    },
  },
}));

const initialData = {
  courseTitle: "",
  courseDescription: "",
  CourseLogo: "",
  courseImage: "",
};

const CourseModal = ({ modal, closeModal }) => {
  const classes = useStyles();
  const [formData, setFormData] = useState(initialData);
  const [isLoading, setLoading] = useState(false);

  const handlerSaveCourseData = async () => {
    try {
      setLoading(true);
      const { courseTitle, courseDescription, CourseLogo, courseImage } =
        formData;
      const courseLogo = await uploadImages(CourseLogo);
      const courseImage1 = await uploadImages(courseImage);
      const data = {
        courseTitle,
        courseDescription,
        CourseLogo: courseLogo.url,
        courseImage: courseImage1.url,
      };
      const res = await createCourse(data);
      if (res.successful) {
        setFormData(initialData);
        closeModal();
        successToastMessage("Course Created Successfully");
      }
    } finally {
      setLoading(false);
    }
  };

  const uploadImages = async (file) => {
    if (typeof file === "string") return { url: file };
    const id =
      Date.now().toString(36) + Math.random().toString(36).substring(2);
    const ref = `/course-documents/${id}`;
    const uploadTask = await uploadFileToStorage(ref, file);
    const url = await getDownloadURL(uploadTask);
    return { url, ref };
  };

  const handlerChange = (name, value) => {
    setFormData({ ...formData, [`${name}`]: value });
  };

  return (
    <>
      {modal && (
        <div className={classes.main}>
          <div className={classes.box}>
            <div className={classes.header}>
              <Typography variant="h6">Create Subject</Typography>
              <div
                onClick={() => {
                  closeModal();
                  setFormData(initialData);
                }}
                style={{ cursor: "pointer" }}
              >
                <ClearIcon style={{ color: "#000", fontSize: 22 }} />
              </div>
            </div>
            <Divider />
            <div className={classes.content}>
              <div style={{ marginBottom: 30 }}>
                <label htmlFor="" className={classes.label}>
                  <ListAltIcon />
                  Course Title:
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  style={{ width: "100%", height: 35 }}
                  placeholder="Course Title"
                  name="courseTitle"
                  value={formData.courseTitle}
                  onChange={(e) => handlerChange(e.target.name, e.target.value)}
                />
              </div>
              <div style={{ marginBottom: 30, marginTop: 20 }}>
                <label htmlFor="" className={classes.label}>
                  <MenuBookIcon />
                  Course Description:
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Add Description"
                  className={classes.description}
                  name="courseDescription"
                  value={formData.courseDescription}
                  onChange={(e) => handlerChange(e.target.name, e.target.value)}
                />
              </div>

              <div style={{ display: "flex" }}>
                <div className={classes.imageWrapper}>
                  <label htmlFor="" className={classes.label}>
                    <ImageIcon />
                    Logo:
                  </label>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    name="CourseLogo"
                    onChange={(e) =>
                      handlerChange("CourseLogo", e.target.files[0])
                    }
                  />
                  <p style={{ fontSize: 13, paddingTop: 3 }}>
                    (Image should be 100*100px)
                  </p>
                </div>

                <div className={classes.imageWrapper}>
                  <label htmlFor="" className={classes.label}>
                    <ImageIcon />
                    Side Image:
                  </label>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    name="courseImage"
                    onChange={(e) =>
                      handlerChange("courseImage", e.target.files[0])
                    }
                  />
                  <p style={{ fontSize: 14, paddingTop: 3 }}>
                    (Image should be 100*100px)
                  </p>
                </div>
              </div>
              <CoursePreview course={formData} />

              <div className={classes.Btn}>
                <ModalBtn
                  disabled={isLoading}
                  variant="contained"
                  onClick={handlerSaveCourseData}
                >
                  {!isLoading ? <AddIcon style={{ marginRight: 5 }} /> : null}
                  {isLoading ? <CircularProgress color="secondary" /> : "Add"}
                </ModalBtn>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CourseModal;
