import { firestore } from "./firebase";

export const perProductDetails = async (id, setProduct) => {
  firestore
    .collection("Products")
    .doc(id)
    .onSnapshot((querySnapshot) => {
      setProduct({ ...querySnapshot.data(), id: querySnapshot.id });
    });
};

export async function deleteProduct(id) {
  const collectionRef = firestore.collection("Products").doc(id);

  await collectionRef.delete().catch((error) => {
    console.error("Error removing document: ", error);
    return { successfull: false, message: "There is some Problem" };
  });
  return {
    successfull: true,
    message: "Blog has been successfully deleted.",
  };
}
