import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { EventHeader } from "./EventHeader";
import { DatePicker } from "react-horizontal-datepicker";
import { FormControl, InputLabel, Select, TextField } from "@material-ui/core";
import { getEvents } from "../../../backend/getDataFromFirestore";
import { ViewEventsContainer } from "./ViewEventsContainer";

const useStyles = makeStyles((theme) => ({
  dataContainer: {
    padding: "31px 90px",
  },
  searchContainer: {
    paddingBottom: 30,
    width: 500,
    display: "flex",
    justifyContent: "space-between",
  },
}));

export const ViewEventsComponent = () => {
  const classes = useStyles();
  const [state, setState] = useState(false);

  const [events, setEvents] = useState([]);
  const [filteredResult, setFilteredResult] = useState([]);
  //searching and sorting
  const [searchValue, setSearchValue] = useState("");
  const [sort, setSort] = useState("asc");

  const todayDate = new Date();
  const startDate = new Date(
    todayDate.getFullYear() - 1,
    todayDate.getMonth(),
    todayDate.getDate()
  );
  const [selectedDate, setSelectedDate] = useState(
    new Date(new Date().setHours(0, 0, 0, 0))
  );

  setTimeout(() => {
    setState(true);
  }, 0);

  function compareAB(a, b) {
    var nameA = a ? a.toString().toUpperCase() : ""; // ignore upper and lowercase
    var nameB = b ? b.toString().toUpperCase() : ""; // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  const searchLeadResult = (name, status) => {
    if (!events?.length) {
      return;
    }

    if (status === "asc" && !name) {
      return events?.sort((a, b) => compareAB(a["eventName"], b["eventName"]));
    } else if (status === "desc" && !name) {
      return events?.sort(
        (a, b) => -1 * compareAB(a["eventName"], b["eventName"])
      );
    }

    return events?.filter((pros) => {
      if (name) {
        return (
          pros?.eventName &&
          pros?.eventName.toLowerCase().includes(name.toLowerCase())
        );
      }
      return true;
    });
  };

  const selectedDay = (eventStartDate) => {
    const eventEndDate = new Date(eventStartDate.getTime());
    eventEndDate.setHours(23, 59, 59, 59);
    setSelectedDate(eventStartDate);
    getEvents(eventStartDate, eventEndDate, setEvents, setFilteredResult);
  };

  const onSearch = (e) => {
    setSearchValue(e.target.value);
    setFilteredResult(searchLeadResult(e.target.value, sort));
  };

  const onSort = (e) => {
    setSort(e.target.value);
    setFilteredResult(searchLeadResult(searchValue, e.target.value));
  };

  return (
    <React.Fragment>
      <EventHeader />
      {state ? (
        <div>
          <DatePicker
            startDate={startDate}
            days={366 * 2}
            selectDate={selectedDate}
            getSelectedDay={selectedDay}
            labelFormat={"MMMM yyyy"}
          />
        </div>
      ) : null}
      <div className={classes.dataContainer}>
        <div className={classes.searchContainer}>
          <TextField
            size="small"
            style={{ width: 300, height: 38 }}
            label="Search"
            placeholder="Search by Blog name..."
            variant="outlined"
            value={searchValue}
            onChange={onSearch}
          />
          <FormControl
            size="small"
            variant="outlined"
            style={{ width: 180, height: 38 }}
          >
            <InputLabel>Sort By</InputLabel>
            <Select native label="Sort By" value={sort} onChange={onSort}>
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </Select>
          </FormControl>
        </div>
        <ViewEventsContainer events={filteredResult} />
      </div>
    </React.Fragment>
  );
};
