import React from "react";
import { Button } from "@material-ui/core";
import * as XLSX from "xlsx";
import pick from "lodash.pick";
import { saveAs } from "file-saver";
import { EXPORT_STUDENTS_CSV_Config } from "../CoreReminderTab/Actions/constant";

const FILE = {
  TYPE: "text/plain;charset=UTF-8",
  EXTENSION: ".csv",
  ORIGIN: "A2",
};

const ExportStudent = ({ students = [] }) => {
  const exportToCsv = () => {
    const fileType = FILE.TYPE;
    const fileExtension = FILE.EXTENSION;

    const exportModel = [];
    const headers = [];
    EXPORT_STUDENTS_CSV_Config?.forEach((column) => {
      if (!column.hidden) {
        exportModel.push(column.key);
        headers.push(column.label);
      }
    });
    const filteredData = students.map((c) => {
      const formattedData = { ...c };
      exportModel.forEach((key) => {
        if (key === "dob" || key === "joinDate") {
          const timestampData = formattedData[key];

          const dateString = new Date(
            timestampData.seconds * 1000
          ).toLocaleDateString("en-US", {
            timeZone: "Asia/Kolkata",
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          });

          formattedData[key] = dateString;
        }
      });
      return pick(formattedData, exportModel);
    });
    const ws = XLSX.utils.book_new();
    XLSX.utils.sheet_add_aoa(ws, [headers]);
    XLSX.utils.sheet_add_json(ws, filteredData, {
      origin: FILE.ORIGIN,
      skipHeader: true,
    });
    const csv = XLSX.utils.sheet_to_csv(ws);
    const data = new Blob([csv], { type: fileType });
    saveAs(data, `students${fileExtension}`);
  };

  return (
    <Button
      color="primary"
      variant="contained"
      onClick={exportToCsv}
      disabled={!students?.length}
    >
      Export
    </Button>
  );
};

export default ExportStudent;
