import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@material-ui/core/TablePagination";
import DeleteTwoToneIcon from "@material-ui/icons/DeleteTwoTone";
import CreateIcon from "@material-ui/icons/Create";
import { formatDate } from "../../../common/const";
import ModalPpt from "./ModalPpt";
import { WarningModal } from "../../../common/WarningModal";
import { useParams } from "react-router-dom";
import { deleteToast, errorToast } from "../../../common/Toastify";
import { deletePpt } from "../../../backend/EditCourseDetails";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  status: {
    color: theme.palette.info.main,
  },
  invoice: {
    color: theme.palette.error.main,
  },
  container: {
    maxHeight: 600,
    "& .MuiTableCell-stickyHeader": {
      backgroundColor: "#ffff",
    },
  },

  arrowIcon: {
    width: "30px",
    height: "30px",
  },
}));

function Row({ order }) {
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const { id } = useParams();

  async function handleConfirmDelete() {
    const res = await deletePpt(order.id, id);
    if (res.successfull) {
      setOpenWarningModal(false);
      deleteToast("PPT");
    } else {
      errorToast();
    }
  }

  return (
    <React.Fragment>
      <WarningModal
        open={openWarningModal}
        setOpen={setOpenWarningModal}
        message={"PPT"}
        subMessage={"All Data will be deleted & wont be accessible anymore"}
        deleteItem={handleConfirmDelete}
      />
      <ModalPpt modal={modal} setModal={setModal} editPptDetails={order} />
      <TableRow className={classes.root}>
        <TableCell/>
        <TableCell>{order?.title}</TableCell>
        <TableCell style={{ width: 300 }}>{order?.description}</TableCell>
        <TableCell style={{ color: "#085FC5" }}>{order?.link}</TableCell>
        <TableCell>{formatDate(order?.updatedDate)}</TableCell>
        <TableCell>
          <IconButton
            aria-label="delete"
            id={order?.docId}
            style={{ color: "#00000052", padding: 0 }}
            onClick={() => setOpenWarningModal(true)}
          >
            <DeleteTwoToneIcon />
          </IconButton>

          <IconButton
            aria-label="delete"
            id={order?.docId}
            style={{ color: "#00000052", padding: 0, marginLeft: 20 }}
            onClick={() => setModal(true)}
          >
            <CreateIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const PptTable = ({ pptData }) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, pptData?.length - page * rowsPerPage);
  return (
    <>
      <TableContainer className={classes.container} component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Link</TableCell>
              <TableCell>Date of Publish</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          {pptData?.length ? (
            <TableBody>
              {pptData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((order, index) => {
                  return <Row key={index} order={order} />;
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          ) : (
            <TableBody>
              <TableCell>
                <p>Please Add a Order</p>
              </TableCell>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={pptData?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

export default PptTable;
