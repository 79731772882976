import { firestore } from "../firebase";

export const getCourseAnalytics = async (setAnalytics) => {
  firestore.collection("Students").onSnapshot((docs) => {
    setAnalytics((prevAnalytics) => ({
      ...prevAnalytics,
      students: docs.size,
    }));
  });
  firestore.collection("Course").onSnapshot((docs) => {
    setAnalytics((prevAnalytics) => ({ ...prevAnalytics, courses: docs.size }));
  });
  firestore.collection("Trainers").onSnapshot((docs) => {
    setAnalytics((prevAnalytics) => ({
      ...prevAnalytics,
      trainers: docs.size,
    }));
  });
  firestore.collection("Batch").onSnapshot((docs) => {
    setAnalytics((prevAnalytics) => ({ ...prevAnalytics, batches: docs.size }));
  });
};

export const getRevenueAnalytics = async () => {
  const data = [];
  let res = {};
  const collectionRef = await firestore.collection("Students").get();

  if (!collectionRef.docs.length) return data;

  collectionRef.docs.forEach((doc) => {
    data.push({ ...doc.data(), id: doc.id });
  });

  res = calculateRevenue(data);
  return res;
};

const calculateRevenue = (data) => {
  const yearlyMonthCourseFees = {}
  data.forEach((entry) => {
    entry?.courseDetails?.forEach((course) => {
      if (course?.courseDuration) {
        const courseCreatedDate = new Date(course?.courseDuration?.seconds * 1000);
        const month = courseCreatedDate.getMonth();
        const year = courseCreatedDate.getFullYear();

        if (typeof yearlyMonthCourseFees[year] !== "object") {
          yearlyMonthCourseFees[year] = [...Array(12).fill(0)]
        }

        yearlyMonthCourseFees[year][month] += parseFloat(course.courseFee) || 0;
      }
    });
  });
  return yearlyMonthCourseFees;
};
