import { IconButton } from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import CreateIcon from "@material-ui/icons/Create";
import VisibilityIcon from "@material-ui/icons/Visibility";

export function ActionCol({ data, onView, onEdit, onDelete }) {
  return (
    <>
      <IconButton
        aria-label="view"
        id={data?.docId}
        onClick={() => onView(data)}
      >
        <VisibilityIcon />
      </IconButton>
      <IconButton
        aria-label="edit"
        id={data?.docId}
        onClick={() => onEdit(data)}
      >
        <CreateIcon />
      </IconButton>

      <IconButton
        aria-label="delete"
        id={data?.docId}
        style={{ color: "#F44336" }}
        onClick={(e) => onDelete(data)}
      >
        <DeleteOutlineOutlinedIcon />
      </IconButton>
    </>
  );
}
