import React from "react";
import { TabMenu } from "../../../common/TabMenu/TabMenu";
import { TABS_CONFIG } from "../../../common/config/configURL";
import { SearchOrder } from "./SearchOrder";
import { makeStyles } from "@material-ui/core/styles";
import { ViewOrdersTable } from "./OrdersTable";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "25px 59px 58px 54px",
    minHeight: 1024,
    backgroundColor: "#CFCFCF",
  },
}));

export const ShopOrdersTab = () => {
  const classes = useStyles();

  return (
    <div>
      <TabMenu tabs={TABS_CONFIG} />
      <div className={classes.root}>
        <SearchOrder />
        <ViewOrdersTable />
      </div>
    </div>
  );
};
