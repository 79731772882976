import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import ListAltIcon from "@material-ui/icons/ListAlt";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import { ModalBtn } from "../../../common/Button";
import AddIcon from "@material-ui/icons/Add";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import { createPpt } from "../../../backend/sendCourseData";
import { useParams } from "react-router-dom";
import { successToastMessage } from "../../../common/Toastify";
import EditIcon from "@material-ui/icons/Edit";
import { editPpt } from "../../../backend/EditCourseDetails";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.5)",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 100,
    bottom: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  box: {
    background: "#fff",
    width: 450,
    // height: 500,
    color: "#5F5F5F",
    borderRadius: 15,
  },
  header: {
    padding: "15px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  content: {
    padding: "25px 30px",
  },
  label: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    marginBottom: 10,
    color: "#000",
  },
  link: {
    transform: "rotate(-45deg)",
  },
  Btn: {
    paddingTop: 50,
    textAlign: "center",
  },
  description: {
    height: 100,
    width: "100%",
    "& .MuiOutlinedInput-root": {
      height: 100,
    },
  },
}));

const initialData = {
  title: "",
  description: "",
  link: "",
};

const ModalPpt = ({ modal, setModal, editPptDetails }) => {
  const classes = useStyles();
  const [formData, setFormData] = useState(initialData);
  const { id } = useParams();

  useEffect(() => {
    if (editPptDetails) setFormData({ ...editPptDetails });
  }, [editPptDetails]);

  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    let res;
    if (!editPptDetails?.id) {
      res = await createPpt({ ...formData, updatedDate: new Date() }, id);
    } else {
      res = await editPpt(editPptDetails?.id, id, {
        ...formData,
        updatedDate: new Date(),
      });
    }

    if (res.successful) {
      setFormData(initialData);
      setModal((state) => !state);
      successToastMessage("PPT Created Successfully");
    }
  };

  return (
    <>
      {modal && (
        <div className={classes.main}>
          <div className={classes.box}>
            <div className={classes.header}>
              <Typography variant="h6">
                {editPptDetails ? "Edit PPT" : "Add PPT"}
              </Typography>
              <div
                onClick={() => setModal((state) => !state)}
                style={{ cursor: "pointer" }}
              >
                <ClearIcon style={{ color: "#000", fontSize: 22 }} />
              </div>
            </div>
            <Divider />
            <div className={classes.content}>
              <div style={{ marginBottom: 30 }}>
                <label htmlFor="" className={classes.label}>
                  <ListAltIcon />
                  PPT Title:
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  style={{ width: "100%", height: 35 }}
                  placeholder="PPT Title"
                  value={formData?.title}
                  onChange={(e) => handleChange("title", e.target.value)}
                />
              </div>
              <div style={{ marginBottom: 30, marginTop: 20 }}>
                <label htmlFor="" className={classes.label}>
                  <MenuBookIcon />
                  Add Description:
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Add Description"
                  className={classes.description}
                  value={formData?.description}
                  onChange={(e) => handleChange("description", e.target.value)}
                />
              </div>
              <div style={{ marginBottom: 30, marginTop: 0 }}>
                <label htmlFor="" className={classes.label}>
                  <InsertLinkIcon className={classes.link} />
                  PPT Link:
                </label>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  style={{ width: "100%", height: 35 }}
                  placeholder="Add Link"
                  value={formData?.link}
                  onChange={(e) => handleChange("link", e.target.value)}
                />
              </div>

              <div className={classes.Btn}>
                <ModalBtn variant="contained" onClick={handleSubmit}>
                  {editPptDetails ? (
                    <>
                      <EditIcon style={{ marginRight: 5 }} />
                      Edit
                    </>
                  ) : (
                    <>
                      <AddIcon style={{ marginRight: 5 }} />
                      Add
                    </>
                  )}
                </ModalBtn>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalPpt;
