import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import Divider from "@material-ui/core/Divider";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { ModalBtn } from "../../../common/Button";
import AddIcon from "@material-ui/icons/Add";
import { useParams } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import { successToastMessage } from "../../../common/Toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { createCourseSyllabus } from "../../../backend/sendCourseData";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.5)",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 100,
    bottom: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  box: {
    background: "#fff",
    width: 450,
    minHeight: 450,
    color: "#5F5F5F",
    borderRadius: 15,
  },
  header: {
    padding: "15px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  content: {
    padding: "25px 30px",
  },
  label: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    marginBottom: 10,
    color: "#000",
  },
  link: {
    transform: "rotate(-45deg)",
  },
  Btn: {
    textAlign: "center",
  },
  editorContainer: {
    "& .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused)": {
      minHeight: 200,
      overflowY: "auto",
      height: "100%",
      maxHeight: 200,
    },

    "& .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners":
      {
        minHeight: 200,
        overflowY: "auto",
        height: "100%",
        maxHeight: 200,
      },
  },
}));

const AddSyllabus = ({ modal, setModal, editSyllabusDetails }) => {
  const classes = useStyles();
  const [title, setTitle] = useState("");
  const [syllabus, setSyllabus] = useState("");
  const { id } = useParams();

  //   useEffect(() => {
  //  const {syllabus} = editSyllabusDetails
  //     if (editSyllabusDetails) setSyllabus(syllabus);
  //   }, [editSyllabusDetails]);

  const handleSubmit = async () => {
    const res = await createCourseSyllabus(
      id,
      {
        syllabus,
        title,
        updatedDate: new Date(),
      },
      editSyllabusDetails?.id
    );

    if (res.successful) {
      setSyllabus("");
      setModal((state) => !state);
      successToastMessage("Syllabus Created Successfully");
    }
  };

  return (
    <>
      {modal && (
        <div className={classes.main}>
          <div className={classes.box}>
            <div className={classes.header}>
              <Typography variant="h6">Add Syllabus</Typography>
              <div
                onClick={() => setModal(!modal)}
                style={{ cursor: "pointer" }}
              >
                <ClearIcon style={{ color: "#000", fontSize: 22 }} />
              </div>
            </div>
            <Divider />
            <div className={classes.content}>
              <div style={{ marginBottom: 30 }}>
                <label htmlFor="" className={classes.label}>
                  <ListAltIcon />
                  Heading Title
                </label>
                <TextField
                  variant="outlined"
                  style={{ width: "100%", height: 35 }}
                  placeholder="Syllabus Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div style={{ marginBottom: 30 }}>
                <label htmlFor="" className={classes.label}>
                  <ListAltIcon />
                  Syllabus
                </label>
                <div className={classes.editorContainer}>
                  <CKEditor
                    editor={ClassicEditor}
                    data={syllabus}
                    onBlur={(_, editor) => {
                      const data = editor.getData();
                      setSyllabus(data);
                    }}
                  />
                </div>
              </div>
              <div className={classes.Btn}>
                <ModalBtn variant="contained" onClick={handleSubmit}>
                  {editSyllabusDetails ? (
                    <>
                      <EditIcon style={{ marginRight: 5 }} />
                      Edit
                    </>
                  ) : (
                    <>
                      <AddIcon style={{ marginRight: 5 }} />
                      Add
                    </>
                  )}
                </ModalBtn>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddSyllabus;
