import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { getRevenueAnalytics } from "../../backend/analytics/getAnalytics";
import { MenuItem, TextField } from "@material-ui/core";

const options = {
  plugins: {
    title: {
      display: true,
      text: "Revenue Chart",
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

const labels = [
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const RevenueChart = () => {
  const [revenueYear, setRevenueYear] = useState("2024");
  const [revenueData, setRevenueData] = useState({});

  useEffect(() => {
    (async () => {
      const data = await getRevenueAnalytics();
      setRevenueData(data);
    })();
  }, []);

  const data = {
    labels,
    datasets: [
      {
        label: "Course Fees",
        data: revenueData?.[revenueYear],
        backgroundColor: "rgb(255, 99, 132)",
      },
      // {
      //   label: "Paid Fees",
      //   data: revenueData?.monthlyPaidFees,
      //   backgroundColor: "rgb(75, 192, 192)",
      // },
      // {
      //   label: "Pending Fees",
      //   data: revenueData?.monthlyPendingFees,
      //   backgroundColor: "rgb(53, 162, 235)",
      // },
    ],
  };

  return (
    <div>
      <div style={{ display: "flex", gap: 20, alignItems: "center" }}>
        <h2>Revenue Chart</h2>
        <div>
          <TextField
            value={revenueYear}
            onChange={(e) => setRevenueYear(e.target.value)}
            select
            variant="outlined"
            label="Year"
          >
            {Object.keys(revenueData)?.map((year, index) => (<MenuItem key={index} value={year}>{year}</MenuItem>)
            )}
          </TextField>
        </div>
      </div>
      <Bar options={options} data={data} />
    </div>
  );
};

export default RevenueChart;
