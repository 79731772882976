import React, { useState, useEffect } from "react";
import { TabMenu } from "../../../common/TabMenu/TabMenu";
import { TABS_CONFIG } from "../../../common/config/configURL";
import { makeStyles } from "@material-ui/core/styles";
import ProductDescription from "./ProductDescription";
import ProductHeader from "./ProductHeader";
import { perProductDetails } from "../../../backend/EditProductDetails";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "26px 50px 43px 36px",
    background: "#F1F1F1",
  },
}));

const ProductCard = () => {
  const { id } = useParams();
  const [product, setProduct] = useState({});
  useEffect(() => {
    perProductDetails(id, setProduct);
  }, [id]);

  const classes = useStyles();
  return (
    <>
      <div>
        <TabMenu tabs={TABS_CONFIG} />
        <div className={classes.root}>
          <ProductHeader product={product} />
          <ProductDescription product={product} />
        </div>
      </div>
    </>
  );
};

export default ProductCard;
