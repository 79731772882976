import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AddCourse } from "../common/Button";
import { useReactToPrint } from "react-to-print";
import ViewPdf from "./Viewpdf";
import { formatFileName } from "../globals/helpers/helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  Button: {
    backgroundColor: "#0A2340",
    color: "#fff",
    fontWeight: 400,
    padding: "10px 10px",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: "#0A2340",
      color: "#fff",
    },
  },
}));

const DownloadPdf = ({ students, studentDetails }) => {
  const classes = useStyles();
  const componentRef = React.useRef(null);
  const [loader, setLoader] = React.useState(false);

  const { studentName } = studentDetails;

  useEffect(() => {
    if (loader === true) {
      handlePrint();
    }
  }, [loader]);

  const handleAfterPrint = React.useCallback(() => {
    setLoader(false);
    console.log("`onAfterPrint` called");
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    console.log("`onBeforePrint` called");
  }, []);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [loader]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: formatFileName(
      `${studentName} ${students?.courseName} receipt`
    ),
    onBeforePrint: handleBeforePrint,
    onAfterPrint: handleAfterPrint,
    removeAfterPrint: true,
  });

  const handlerbtn = () => {
    if (students) {
      setLoader(true);
    }
  };

  return (
    <>
      <div className={classes.root}>
        <AddCourse
          variant="outlined"
          className={classes.Button}
          onClick={handlerbtn}
        >
          Generate Receipt
        </AddCourse>
      </div>
      <ViewPdf
        ref={componentRef}
        loader={loader}
        students={students}
        studentDetails={studentDetails}
      />
    </>
  );
};

export default DownloadPdf;
