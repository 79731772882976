import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import { CertificatePreview } from "./CertificatePreview";
import { PrimaryButton } from "../../../common/Button";
import ReactToPrint from "react-to-print";
import { formatFileName } from "../../../globals/helpers/helpers";

const useStyles = makeStyles((theme) => ({
  dialog: {
    "& .MuiDialog-paperWidthSm": {
      borderRadius: 0,
    },
  },
  container: {
    padding: "25px 40px 47px",
  },
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 20,
  },
}));

export const OpenCertificateModal = ({
  open,
  certificateDetails,
  handleClose,
}) => {
  const { studentName, course: courseName } = certificateDetails;
  const classes = useStyles();
  const componentRef = React.useRef(null);

  const handleAfterPrint = React.useCallback(() => {
    handleClose();
  }, [handleClose]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, []);

  const reactToPrintTrigger = React.useCallback(() => {
    return (
      <div className={classes.footer}>
        <PrimaryButton variant="contained" color="primary">
          Download
        </PrimaryButton>
      </div>
    );
  }, []);

  return (
    <React.Fragment>
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        className={classes.dialog}
      >
        <div className={classes.container}>
          <CertificatePreview
            ref={componentRef}
            data={certificateDetails}
            handleClose={handleClose}
          />
          <ReactToPrint
            content={reactToPrintContent}
            documentTitle={formatFileName(
              `${studentName} ${courseName} rogue_code`
            )}
            onAfterPrint={handleAfterPrint}
            removeAfterPrint
            trigger={reactToPrintTrigger}
          />
        </div>
      </Dialog>
    </React.Fragment>
  );
};
